import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  List,
  Loader,
  Panel,
  Stack,
  Button,
  ButtonGroup,
  Modal,
  InputPicker,
  InputNumber,
  Toggle,
  Input,
  Tag,
} from "rsuite";
import constants from "../../../constants";
import { MdDeleteForever } from "react-icons/md";
import { deleteSalesReturn } from "../../../Loaders/DeleteHelper";

const title = "Sales Return";

class SalesReturn extends Component {
  state = {
    products: [],
    salesReturns: null,
    loading: true,
    modal: false,
    dummy: {
      customer_id: this.props.customer.id,
      product_id: null,
      quantity: 1,
      replace_product: false,
      remarks: "",
    },
  };

  componentDidMount = () => {
    this.loadSalesReturns();
    this.loadProducts();
  };

  loadSalesReturns = () => {
    this.setState({ salesReturns: null });
    fetch(constants.url + `sales-returns/${this.props.customer.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ salesReturns: data, loading: false });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  loadProducts = () => {
    fetch(constants.url + "products", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ products: data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  setDummy = (where, value) => {
    var { dummy } = this.state;
    dummy[where] = value;
    this.setState({ dummy });
  };

  modal = (modal = true) => {
    this.setState({ modal });
  };

  save = () => {
    this.setState({ loading: true });
    var data = this.state.dummy;
    if (data.product_id == null) {
      alert("You must select a product");
      this.setState({ loading: false });
      return;
    }

    if (data.quantity == 0) {
      alert("Quantity is zero!");
      this.setState({ loading: false });
      return;
    }

    fetch(constants.url + `sales-returns`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        this.setState({ loading: false });
        this.modal(false);
        this.loadSalesReturns();
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  };

  updateReplace = (id) => {
    var conf = window.confirm("Are you sure to update sales replace?");
    if (!conf) {
      return;
    }
    this.setState({ loading: true });

    fetch(constants.url + `update-sales-replace/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((res) => {
        this.setState({ loading: false });
        this.loadSalesReturns();
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  };

  render() {
    var { salesReturns, products, dummy } = this.state;
    if (salesReturns == null) {
      return <Loader content="Loading sales returns.." />;
    }

    return (
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Sales returns ({salesReturns.length})</span>
            <ButtonGroup>
              <Button appearance="link" onClick={this.modal} color="green">
                Create +
              </Button>
              <Button
                appearance="link"
                onClick={() => {
                  this.loadProducts();
                  this.loadSalesReturns();
                }}
              >
                Refresh
              </Button>
            </ButtonGroup>
          </Stack>
        }
        bordered
      >
        <List size="lg">
          {salesReturns.map((item, index) => (
            <List.Item key={index} index={index}>
              <Stack
                justifyContent="space-between"
                style={{ textAlign: "left" }}
              >
                <span>
                  <strong>
                    {item.product.name} [{item.quantity} Nos]
                  </strong>{" "}
                  <br />
                  <i>{item.created_at}</i> <br /> <p>Remarks: {item.remarks}</p>
                  <br />
                  {!item.replace_product && (
                    <Button
                      appearance="link"
                      color="red"
                      size="sm"
                      onClick={() =>
                        deleteSalesReturn(item.id, this.loadSalesReturns)
                      }
                    >
                      <MdDeleteForever /> Delete
                    </Button>
                  )}
                </span>
                {item.replace_product == 1 ? (
                  <Tag color="green">Replaced</Tag>
                ) : (
                  <Tag color="orange">
                    Pending replace{" "}
                    <Button
                      appearance="link"
                      onClick={() => this.updateReplace(item.id)}
                    >
                      Issue replace
                    </Button>{" "}
                  </Tag>
                )}
              </Stack>
            </List.Item>
          ))}
        </List>

        <Modal open={this.state.modal} onClose={() => this.modal(false)}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {products ? (
              <InputPicker
                data={products?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                block
                value={dummy.product_id}
                onChange={(value) => this.setDummy("product_id", value)}
              />
            ) : (
              <Loader size="xs" content="Loading products" />
            )}
            <br />
            <InputNumber
              prefix="#"
              disabled={dummy.product_id == null}
              placeholder="Quantity"
              value={dummy.quantity}
              onChange={(value) => this.setDummy("quantity", value)}
              min={1}
            />
            <br />
            <Stack justifyContent="space-between">
              <span>Replaced?</span>
              <Toggle
                checked={dummy.replace_product}
                onChange={(value) => {
                  this.setDummy("replace_product", value);
                }}
              />
            </Stack>
            <br />
            <Input
              as="textarea"
              rows={3}
              placeholder="Remarks"
              value={dummy.remarks}
              onChange={(value) => this.setDummy("remarks", value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.save}
              appearance="primary"
              disabled={this.state.loading}
            >
              {this.state.loading ? (
                <Loader size="xs" content="Saving" />
              ) : (
                "Save"
              )}
            </Button>
            <Button
              onClick={() => this.modal(false)}
              appearance="subtle"
              disabled={this.state.loading}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Panel>
    );
  }
}

export default SalesReturn;
