import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  TagPicker,
  Table,
  Pagination,
  Panel,
  Stack,
  ButtonGroup,
  Button,
  List,
  Avatar,
  Tag,
} from "rsuite";
import constants, { getUserImage } from "../../../../constants";
import UserView from "./user-view";

const title = "User";
const titles = "Users";
const path = "users";

class UserIndex extends Component {
  state = {
    data: [],
    loading: true,
    page: 1,
    selectedData: null,
  };

  setColumnKeys = (columnKeys) => {
    this.setState({ columnKeys });
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  back = () => {
    this.setState({ selectedData: null });
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = (page = 1) => {
    this.setLoading();
    this.setState({ page });
    fetch(constants.url + path + "?page=" + page, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then(({ data }) => {
        this.setState({ data });
        this.setLoading(false);
      })
      .catch((error) => {
        this.setLoading(false);
        console.error(error);
      });
  };

  render() {
    var { data } = this.state;

    if (this.state.selectedData) {
      return (
        <UserView
          data={this.state.selectedData}
          back={this.back}
          currentLocation={this.props.currentLocation}
          sessionCheck={this.props.sessionCheck}
        />
      );
    }
    return (
      <div>
        <Panel
          bordered
          header={
            <Stack justifyContent="space-between">
              <strong>{titles}</strong>
              <ButtonGroup>
                {/* <Button
                  onClick={() => this.setState({ createMode: true })}
                  appearance="primary"
                >
                  Add
                </Button> */}
                <Button onClick={this.loadData}>Refresh</Button>
              </ButtonGroup>
            </Stack>
          }
        >
          <hr />
          {/* <div style={{ height: "auto" }}>
            <Table
              loading={this.state.loading}
              height={300}
              hover={true}
              fillHeight={true}
              showHeader={true}
              data={data?.data}
              bordered={true}
              cellBordered={false}
              headerHeight={40}
              rowHeight={46}
              onRowClick={(selectedData) => this.setState({ selectedData })}
            >
              {columns.map((column) => {
                const { key, label, ...rest } = column;
                return (
                  <Column {...rest} key={key}>
                    <HeaderCell>{label}</HeaderCell>
                    <Cell dataKey={key} />
                  </Column>
                );
              })}
            </Table>
          </div>
          <Pagination
            prev
            last
            next
            first
            size="md"
            total={data?.total}
            limit={data?.per_page}
            activePage={this.state.page}
            onChangePage={(page) => this.loadData(page)}
          /> */}

          <List hover>
            {data.map((user, index) => (
              <List.Item onClick={() => this.setState({ selectedData: user })}>
                <Avatar bordered circle src={getUserImage(user)} />
                <div>
                  <strong>
                    #{user.id} - {user.name}
                  </strong>
                  <br />
                  <i>{user.email}</i>
                  <br />
                  <Tag color="green">{user.role}</Tag>
                </div>
              </List.Item>
            ))}
          </List>
        </Panel>
      </div>
    );
  }
}

export default UserIndex;
