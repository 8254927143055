import Cookies from "js-cookie";
import React, { Component } from "react";
import { Panel } from "rsuite";

class RBPHome extends Component {
  state = {};
  render() {
    var { sessionCheck } = this.props;
    return (
      <Panel
        header={`Welcome ${Cookies.get("username")}`}
        bordered
        shaded
      ></Panel>
    );
  }
}

export default RBPHome;
