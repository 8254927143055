import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Input,
  InputGroup,
  InputNumber,
  List,
  Loader,
  Modal,
  Radio,
  RadioGroup,
  SelectPicker,
  Stack,
  Tag,
  TagPicker,
  Toggle,
} from "rsuite";
import { TbMapPinSearch } from "react-icons/tb";
import { GoogleMap, MarkerF, PolygonF } from "@react-google-maps/api";

import constants, {
  alertError,
  alertInfo,
  alertInfoSilent,
} from "../constants";
import { TbShoppingCartSearch } from "react-icons/tb";
import { VscGitPullRequestCreate } from "react-icons/vsc";

import {
  getNearestItems,
  getSimilarNearestItems,
} from "../Helpers/CoordinateHelper";
import { pointInPolygon } from "../Helpers/RouteHelper";

import { createLead } from "../Loaders/GeneralSaver";
import {
  extractEnglishWords,
  findSuitableHashTags,
} from "../Helpers/Utilities";

class GooglePlaces extends Component {
  state = {
    map: null,
    modal: false,
    searchType: "Text Search",
    searchTerm: "store",
    searchPlaceType: "store",
    radius: 1000,
    loading: false,
    results: null,
    googleService: null,
    selectedPlace: null,
    insideRouteOnly: true,
    pagination: null,
    autoCreate: null,
    autoCreatedList: [],
  };

  modal = (modal = true) => this.setState({ modal });
  loading = (loading = true) => this.setState({ loading });
  componentDidMount = () => {
    this.props.setPlaceMarkers(this.markers);
  };

  search = (pagination) => {
    var {
      map,
      searchType,
      searchTerm,
      searchPlaceType,
      radius,
      googleService,
    } = this.state;
    if (!map) {
      alertError("Map has to be fully loaded..");
      return;
    }
    if (pagination != null) {
      console.log("pagination wasn't null, load next page from here");
      pagination.nextPage();
      return;
    }
    var location = map.getCenter();

    this.loading();

    if (searchType == "Text Search") {
      // Define search request
      var request = {
        location,
        radius, // specify radius in meters
        query: searchTerm,
      };

      // Perform search
      googleService.textSearch(request, (results, status, pagination) => {
        this.loading(false);
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          // Process search results, get nearest lead or customers..
          const updatedResults = this.processResults(results);
          this.setState({
            results: updatedResults,
            pagination: pagination.hasNextPage ? pagination : null,
          });
          console.log(updatedResults);
          // console.log(pagination);
          // You can handle the search results here, e.g., display markers on the map
        } else {
          alertError("Failed to search for places. Please try again later.");
        }
      });
    } else {
      //NEARBY SEARCH..
      var request = {
        location,
        radius, // specify radius in meters
        type: searchPlaceType,
      };

      console.log(request);

      // Perform search
      googleService.nearbySearch(request, (results, status) => {
        this.loading(false);
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          // Process search results, get nearest lead or customers..
          const updatedResults = this.processResults(results);
          console.log(updatedResults);
          this.setState({
            results: updatedResults,
            pagination: null,
          });
          // You can handle the search results here, e.g., display markers on the map
        } else {
          // alertError("Failed to search for places. Please try again later.");
        }
      });
    }
  };

  processResults = (results) => {
    return results
      .map((p, index) => {
        const pLocation = {
          lat: p.geometry.location.lat(),
          lng: p.geometry.location.lng(),
        };
        var nearestCustomers = getSimilarNearestItems(
          pLocation,
          this.props.customers,
          "geo_location",
          5,
          p.name,
          "shop_name"
        );
        var nearestLeads = getSimilarNearestItems(
          pLocation,
          this.props.leads,
          "geolocation",
          5,
          p.name,
          "shop_name"
        );

        var insideTheRoute = pointInPolygon(
          pLocation,
          JSON.parse(this.props.selectedRoute.cord1)
        );

        return {
          ...p,
          nearestCustomers,
          nearestLeads,
          insideTheRoute,
        };
      })
      .filter((result) => {
        // Existing Customer or lead condition (take out nearest and most similar places)
        const isSimilarityValid =
          (!result.nearestCustomers ||
            result.nearestCustomers.length === 0 ||
            (result.nearestCustomers[0]?.similarity <= 0.7 &&
              result.nearestCustomers[0]?.distance >= 30)) &&
          (!result.nearestLeads ||
            result.nearestLeads.length === 0 ||
            (result.nearestLeads[0]?.similarity <= 0.7 &&
              result.nearestLeads[0]?.distance >= 30));

        // Additional condition based on insideRouteOnly
        const isInsideRoute =
          !this.state.insideRouteOnly || result.insideTheRoute;

        // Filter logic
        const isPlaceFiltered =
          this.state?.searchPlaceFilter?.length > 0
            ? result.name
                .toLowerCase()
                .includes(this.state.searchPlaceFilter.toLowerCase())
            : true;

        return isSimilarityValid && isInsideRoute && isPlaceFiltered;
      });
  };

  getPhoneNumber = (place) => {
    const { results, googleService } = this.state;
    this.loading();

    googleService.getDetails(
      {
        placeId: place.place_id,
      },
      (placeResult, status) => {
        this.loading(false);
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          // Access phone number from place details
          const phone = placeResult.formatted_phone_number;
          console.log("Phone Number:", phone);
          if (!phone) {
            alertError("This place has no public phone numbers.");
          }

          this.setPhoneNumberToPlace(place.place_id, phone, true);
        } else {
          console.error("Failed to retrieve place details:", status);
        }
      }
    );
  };

  markers = (map) => {
    const { results, loading } = this.state;
    if (!results) {
      return null; // Return null when results are not available
    }

    return results.map((item, index) => (
      <MarkerF
        key={`Place-${index}`}
        position={{
          lat: item.geometry.location.lat(),
          lng: item.geometry.location.lng(),
        }}
        icon={{
          url: item.noPhone
            ? constants.marker_red
            : item.formatted_phone_number
            ? constants.marker_green
            : constants.marker_yellow,
          scaledSize: new window.google.maps.Size(32, 32),
        }}
        label={{
          text: map && map.getZoom() >= 15 ? item.name : "P",
          color: "black",
          fontSize: "10",
        }}
        onClick={() => this.setState({ selectedPlace: item })}
        onDblClick={() => {
          console.log(`Finding phone number for ${item.name} - ${index}`);
          this.getPhoneNumber(item);
        }}
      />
    ));
  };

  autoCreate = (index = 0) => {
    console.log(`Auto create function called ${index}`);
    var { results, autoCreatedList, googleService } = this.state;
    var place = results[index];
    if (place) {
      console.log(
        `Auto create, valid place, now try to get the phone number details..`
      );
      this.setState({ autoCreate: index, loading: true });

      googleService.getDetails(
        {
          placeId: place.place_id,
        },
        (placeResult, status) => {
          //place types to find hash tags..
          const combinedTypes = [...place.types, ...place.name.split(" ")];
          //CREATE LEAD'S DATA TO FEETCH..
          var data = {
            geolocation: JSON.stringify({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            }),
            shop_name: extractEnglishWords(place?.name),
            phone: "",
            remarks: JSON.stringify(
              findSuitableHashTags(this.props.hashTags, combinedTypes)
            ),
            next_visit: "",
            rate: 2,
            route_id: this.props.selectedRoute.id,
            address: extractEnglishWords(
              place?.formatted_address ?? place?.vicinity ?? "no-address"
            ),
            g_photo: place.photos ? place.photos[0]?.getUrl() : null,
            g_rating: place.rating,
            g_types: JSON.stringify(place.types),
          };

          //CHECK IF THE PHONE SEARCH GOT ANY NUMBER
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            console.log("Google place-get details OK");
            const phone = placeResult.formatted_phone_number;
            if (phone) {
              console.log("Google place-get details: phone is okay: " + phone);
              data.phone = phone?.replace(/\s/g, "")?.slice(-10) ?? "";
            }
            this.setPhoneNumberToPlace(place.place_id, phone, false);
          }

          //INITIATE THE CREATE REQUEST..
          createLead(
            data,
            (lead) => {
              const updatedList = [
                ...this.state.autoCreatedList,
                place.place_id,
              ];
              // Update the state with the new array
              this.setState({ autoCreatedList: updatedList }, () =>
                this.autoCreate(index + 1)
              );
            },
            () => this.autoCreate(index + 1)
          );
        }
      );
    } else {
      console.log(`Auto create, invalid index or place, or this is the end`);
      if (autoCreatedList.length > 0) {
        console.log(`Autocreate end: clearing created list..`);
        alertInfo(`${autoCreatedList.length} new leads created!`);
        this.props.refreshLeads();
      }
      this.setState(
        {
          autoCreate: null,
          loading: false,
          results: results.filter((r) => !autoCreatedList.includes(r.place_id)),
        },
        () => this.setState({ autoCreatedList: [] })
      );
    }
  };

  setPhoneNumberToPlace = (place_id, phone, changeOnSelected = false) => {
    var { results, selectedPlace } = this.state;

    if (changeOnSelected && selectedPlace) {
      selectedPlace.formatted_phone_number =
        phone?.replace(/\s/g, "")?.slice(-10) ?? null;
      selectedPlace.noPhone = !phone;
      this.setState({ selectedPlace });
    }

    results.forEach((result) => {
      if (result.place_id === place_id) {
        result.formatted_phone_number =
          phone?.replace(/\s/g, "")?.slice(-10) ?? null;
        result.noPhone = !phone;
      }
    });

    this.setState({ results });
  };

  findDensityLevel = (leadCount, totalCount) => {
    // Determine density level based on lead count and total count
    const density = leadCount / totalCount;

    if (density < 0.3) {
      return "Low";
    } else if (density < 0.6) {
      return "Normal";
    } else {
      return "High";
    }
  };

  findLessDensityAreas = (leads, gridSize = 0.01, maxCoordinates = 20) => {
    console.log(`finding less density areas.. ${leads?.length}`);
    // Create an object to store the count of leads in each grid
    const gridCounts = {};
    const totalCount = leads.length;

    // Count leads in each grid
    leads.forEach((lead) => {
      const geoLocation = JSON.parse(lead.geolocation);
      // Calculate the grid key based on the rounded coordinates
      const gridKey = `${Math.floor(geoLocation.lat / gridSize)},${Math.floor(
        geoLocation.lng / gridSize
      )}`;

      // Increment the count for this grid
      gridCounts[gridKey] = (gridCounts[gridKey] || 0) + 1;
    });

    // Find the minimum lead count in any grid
    const minLeadCount = Math.min(...Object.values(gridCounts));

    // Find the grids with the minimum lead count
    const lessDensityAreas = Object.keys(gridCounts).filter(
      (key) => gridCounts[key] === minLeadCount
    );

    // Sort less density areas based on lead count
    lessDensityAreas.sort((a, b) => gridCounts[a] - gridCounts[b]);

    // Convert grid coordinates back to actual coordinates and add density labels
    const lessDensityCoordinates = lessDensityAreas
      .slice(0, maxCoordinates)
      .map((area) => {
        const [lat, lng] = area
          .split(",")
          .map((coord) => parseInt(coord) * gridSize);
        const densityLabel = this.findDensityLevel(
          gridCounts[area],
          totalCount
        );
        return { lat, lng, density: densityLabel };
      });

    this.setState({ lessDensityCoordinates });
  };

  render() {
    var { results, selectedPlace } = this.state;
    return (
      <>
        <Button
          onClick={this.modal}
          size="sm"
          appearance="primary"
          color="green"
        >
          <TbMapPinSearch />
        </Button>
        <Drawer
          size="full"
          placement="bottom"
          open={this.state.modal}
          onClose={() => this.modal(false)}
        >
          <Drawer.Header>
            <Drawer.Title>
              <TbMapPinSearch /> Find Google Places (Stores)
              <Button
                onClick={() => {
                  this.findLessDensityAreas(this.props.leads);
                  this.setState({ densityModal: true });
                }}
              >
                Find Low Density
              </Button>
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "60vh",
              }}
              zoom={12}
              onLoad={(map) => {
                this.setState({
                  map,
                  googleService: new window.google.maps.places.PlacesService(
                    map
                  ),
                });
                map.setCenter(this.props.location);
              }}
              mapTypeId="roadmap"
              options={{
                streetViewControl: true,
              }}
            >
              {this.markers(this.state.map)}

              {this.state.insideRouteOnly && this.props.selectedRoute && (
                <PolygonF
                  options={{
                    paths: JSON.parse(this.props.selectedRoute?.cord1).map(
                      (coord) => ({
                        lat: parseFloat(coord.lat),
                        lng: parseFloat(coord.lng),
                      })
                    ),
                    strokeColor: "#1abc9c",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillOpacity: 0,
                  }}
                />
              )}
            </GoogleMap>

            <Stack
              justifyContent="space-between"
              style={{ alignItems: "center" }}
            >
              <Checkbox
                checked={this.state.insideRouteOnly}
                onClick={() =>
                  this.setState({
                    insideRouteOnly: !this.state.insideRouteOnly,
                  })
                }
              >
                Route
              </Checkbox>

              <SelectPicker
                siz="sm"
                searchable={false}
                cleanable={false}
                data={["Text Search", "Nearby Search"].map((i) => ({
                  label: i,
                  value: i,
                }))}
                value={this.state.searchType}
                onChange={(searchType) => this.setState({ searchType })}
                placement="auto"
              />
              <Button
                onClick={() => this.search(null)}
                appearance="primary"
                disabled={this.state.loading}
                size="sm"
              >
                <TbShoppingCartSearch /> &nbsp;Search
              </Button>
              {this.state.pagination && (
                <Button
                  size="sm"
                  onClick={() => this.search(this.state.pagination)}
                >
                  More..
                </Button>
              )}
              <Button
                onClick={() => this.autoCreate(0)}
                disabled={this.state.autoCreate != null}
              >
                {this.state.autoCreate == null ? (
                  <>
                    <VscGitPullRequestCreate /> Auto{" "}
                    {this.state.results?.length}
                  </>
                ) : (
                  <Loader
                    size="sm"
                    content={`${this.state.autoCreate + 1}/${
                      this.state.results?.length
                    }`}
                  />
                )}
              </Button>
            </Stack>
            <InputGroup>
              {this.state.searchType == "Text Search" && (
                <Input
                  placeholder="Search term"
                  value={this.state.searchTerm}
                  onChange={(searchTerm) => this.setState({ searchTerm })}
                />
              )}

              {this.state.searchType == "Text Search" && (
                <SelectPicker
                  size="sm"
                  cleanable={false}
                  searchable={false}
                  style={{ width: "80px !important" }}
                  data={[500, 1000, 2000, 5000, 8000, 10000].map((i) => ({
                    label: `${i} Meters`,
                    value: i,
                  }))}
                  value={this.state.radius}
                  onChange={(radius) => this.setState({ radius })}
                  placement="auto"
                />
              )}

              {this.state.searchType == "Nearby Search" && (
                <SelectPicker
                  data={constants.place_search_types.map((i) => ({
                    label: i,
                    value: i,
                  }))}
                  value={this.state.searchPlaceType}
                  onChange={(searchPlaceType) =>
                    this.setState({ searchPlaceType })
                  }
                  placement="auto"
                />
              )}

              {this.state.searchType == "Nearby Search" && (
                <Input
                  placeholder="includes filter.."
                  value={this.state.searchPlaceFilter}
                  onChange={(searchPlaceFilter) =>
                    this.setState({ searchPlaceFilter })
                  }
                />
              )}
            </InputGroup>
          </Drawer.Body>
        </Drawer>

        {/* PLACE VIEW */}
        {selectedPlace && (
          <Modal
            open={selectedPlace}
            onClose={() => this.setState({ selectedPlace: null })}
          >
            <Modal.Header>
              <Modal.Title>{selectedPlace.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedPlace.photos && (
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "15px",
                  }}
                >
                  {selectedPlace.photos.map((photo) => (
                    <img
                      src={photo.getUrl()}
                      style={{
                        width: "auto",
                        height: "80px",
                        marginLeft: "5px",
                      }}
                      data-action="zoom"
                    />
                  ))}
                </div>
              )}
              <strong>Address: </strong> {selectedPlace.formatted_address}
              <br />
              <strong>Phone: </strong>{" "}
              {selectedPlace.formatted_phone_number ?? "No phone number"}
              <br />
              <strong>Types: </strong>{" "}
              {selectedPlace.types.map((t) => (
                <Tag style={{ margin: "5px" }}>{t}</Tag>
              ))}
              <br />
              <Divider>Nearest Customers</Divider>
              {selectedPlace.nearestCustomers.map((c, i) => (
                <Tag
                  key={i}
                  color={
                    c.distance < 30
                      ? "red"
                      : c.distance < 100
                      ? "orange"
                      : "green"
                  }
                  style={{ margin: "5px" }}
                >
                  <strong>{c.similarity.toFixed(2)}</strong> &nbsp;{c.shop_name}{" "}
                  ({(c.distance / 1000).toFixed(2)} KM)
                </Tag>
              ))}
              <Divider>Nearest Leads</Divider>
              {selectedPlace.nearestLeads.map((l, i) => (
                <Tag
                  key={i}
                  color={
                    l.distance < 30
                      ? "red"
                      : l.distance < 100
                      ? "orange"
                      : "green"
                  }
                  style={{ margin: "5px" }}
                >
                  <strong>{l.similarity.toFixed(2)}</strong> &nbsp;{l.shop_name}{" "}
                  ({(l.distance / 1000).toFixed(2)} KM)
                </Tag>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Stack justifyContent="space-between">
                <Button
                  onClick={() =>
                    this.setState({
                      results: this.state.results.filter(
                        (i) => i.place_id !== selectedPlace.place_id
                      ),
                      selectedPlace: null,
                    })
                  }
                  appearance="primary"
                  color="red"
                  disabled={this.state.loading}
                  size="sm"
                >
                  Remove Place
                </Button>

                <Stack>
                  <Button
                    onClick={() => this.props.leadFromPlace(selectedPlace)}
                    appearance="primary"
                    disabled={this.state.loading}
                    size="sm"
                  >
                    Create Lead
                  </Button>
                  {!selectedPlace.formatted_phone_number && (
                    <Button
                      color="yellow"
                      onClick={() => this.getPhoneNumber(selectedPlace)}
                      appearance="primary"
                      disabled={this.state.loading}
                      size="sm"
                    >
                      Find Phone Number
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Modal.Footer>
          </Modal>
        )}

        {/* LEAD LOW DENSITY AREAS  */}
        <Modal
          open={this.state.densityModal}
          onClose={() => this.setState({ densityModal: false })}
        >
          <Modal.Header>
            <Modal.Title>Lead - Less Density Indicator</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.lessDensityCoordinates && (
              <List hover>
                {this.state.lessDensityCoordinates.map((coordinate, index) => (
                  <List.Item
                    key={index}
                    onClick={() => this.state.map.setCenter(coordinate)}
                  >
                    <Stack justifyContent="space-between">
                      <strong>
                        {coordinate.lat}, {coordinate.lng}
                      </strong>
                      <Tag>{coordinate.density}</Tag>
                    </Stack>
                  </List.Item>
                ))}
              </List>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default GooglePlaces;
