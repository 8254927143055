import moment from "moment";
import React, { Component } from "react";
import {
  AutoComplete,
  Button,
  DatePicker,
  InputPicker,
  SelectPicker,
  Slider,
  Stack,
  Toggle,
} from "rsuite";
import constants from "../constants";
import { loadUsersFromRoute } from "../Loaders/GeneralLoder";

class LeadFollowupForm extends Component {
  state = {
    selected: this.props.selected ?? {},
    saveButtonClickShowLoading: false,
    routeUsers: [],
  };

  componentDidMount = () => {
    loadUsersFromRoute(this.props?.lead?.route_id, (routeUsers) =>
      this.setState({ routeUsers })
    );
  };

  changeValue = (where, what) => {
    console.log(what);
    if (this.props.changeValue) {
      this.props.changeValue(where, what);
    } else {
      var { selected } = this.state;
      selected[where] = what;
      this.setState({ selected });
    }
  };
  render() {
    var { selected } = this.state;
    return (
      <>
        <strong>Remark:</strong>
        {this.props.sessionCheck.role == "Admin" ? (
          <AutoComplete
            data={constants.followup_remarks}
            value={selected.remark ?? ""}
            onChange={(value) => this.changeValue("remark", value)}
            placeholder="Remark"
            block
            readOnly={this.props.sessionCheck.role != "Admin"}
          />
        ) : (
          selected.remark
        )}
        <br />
        <strong>Kind:</strong>
        <InputPicker
          value={selected.kind ?? "Phone call"}
          onChange={(value) => this.changeValue("kind", value)}
          data={constants.followup_types.map((i) => ({
            label: i,
            value: i,
          }))}
          placeholder="Kind"
          block
          plaintext={this.props.sessionCheck.role != "Admin"}
        />
        <br />
        {this.props.sessionCheck.role == "Admin" && (
          <>
            <strong>Assigned User:</strong>
            <InputPicker
              value={selected.assigned_user ?? "Assign user"}
              onChange={(value) => this.changeValue("assigned_user", value)}
              data={this.state.routeUsers.map((i) => ({
                label: i.name,
                value: i.id,
              }))}
              placeholder="assigned_user"
              block
            />
            <br />
          </>
        )}

        <strong>Response rate {selected.response ?? 0}%</strong>
        <Slider
          progress
          value={selected.response}
          onChange={(value) => {
            this.changeValue("response", value);
          }}
        />

        <br />
        <Stack justifyContent="space-between">
          <span>Is this an event?</span>

          <Toggle
            checked={selected.event}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(value) => this.changeValue("event", value)}
            disabled={this.props.sessionCheck.role != "Admin"}
          />
        </Stack>

        <br />

        {selected.event ? (
          <>
            <Stack justifyContent="space-between">
              <span>Afternoon-visit?</span>

              <Toggle
                checked={selected.an_visit}
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(value) => this.changeValue("an_visit", value)}
                disabled={this.props.sessionCheck.role != "Admin"}
              />
            </Stack>
            <br />
            <strong>Event Date</strong>
            <DatePicker
              placement="auto"
              block
              value={
                selected.event_date
                  ? moment(selected.event_date).toDate()
                  : null
              }
              onChange={(value) => this.changeValue("event_date", value)}
              format="yyyy-MM-dd HH:mm"
              editable={false}
              plaintext={this.props.sessionCheck.role != "Admin"}
              // oneTap
            />
            <br />
            <strong>Event Type</strong>
            <SelectPicker
              size="sm"
              style={{ width: 224 }}
              searchable={false}
              data={constants.event_types.map((type) => ({
                label: type,
                value: type,
              }))}
              value={selected.event_type}
              onChange={(value) => this.changeValue("event_type", value)}
              plaintext={this.props.sessionCheck.role != "Admin"}
              placement="auto"
            />

            <br />
            <Stack justifyContent="space-between">
              <span>Completed Event?</span>

              <Toggle
                checked={selected.event_done}
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(value) => {
                  this.changeValue("event_done", value);
                  this.changeValue("event_done_at", value ? new Date() : null);
                  this.changeValue("event_remark", "");
                }}
              />
            </Stack>
            <br />
            {selected.event_done ? (
              <>
                <strong>Event Done At</strong>
                <DatePicker
                  placement="auto"
                  block
                  value={
                    selected.event_done_at
                      ? moment(selected.event_done_at).toDate()
                      : null
                  }
                  onChange={(value) => this.changeValue("event_done_at", value)}
                  format="yyyy-MM-dd HH:mm"
                />
                <br />

                <strong>Event Remark:</strong>
                <AutoComplete
                  block
                  data={constants.followup_remarks}
                  placeholder="Remark"
                  value={selected.event_remark}
                  onChange={(value) => this.changeValue("event_remark", value)}
                  placement="auto"
                />
                <br />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {this.props.onClickSave && (
          <Button
            block
            appearance="primary"
            size="md"
            disabled={this.state.saveButtonClickShowLoading}
            onClick={() => {
              this.props.onClickSave(this.state.selected);
              this.setState({ saveButtonClickShowLoading: true });
            }}
          >
            Save
          </Button>
        )}
      </>
    );
  }
}

export default LeadFollowupForm;
