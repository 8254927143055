import React, { useState, useEffect } from "react";
import {
  InfoWindow,
  Marker,
  Circle,
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  Polygon,
  Polyline,
  DirectionsRenderer,
} from "@react-google-maps/api";
import constants, { getShopTypeLabel, shortenedText } from "../constants";
import { Button, Rate, Checkbox, Tag } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import PhoneFillIcon from "@rsuite/icons/PhoneFill";
import CloseIcon from "@rsuite/icons/Close";
import MemberIcon from "@rsuite/icons/Member";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import moment from "moment";

const colors = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#d35400",
  "#c0392b",
  "#7f8c8d",
  "#f39c12",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
];

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

function MapView(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: constants.map_api,
  });

  const [map, setMap] = React.useState(null);
  const [info, setInfo] = React.useState();
  const [mapTypeId, setMapTypeId] = React.useState("roadmap");

  const [directions, setDirections] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!

    // const bounds = new window.google.maps.LatLngBounds(props.center);
    // map.fitBounds(bounds);

    setMap(map);
    props.setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
    props.setMap(null);
  }, []);

  function handleMarkerClick(index) {
    setInfo(index);
  }

  function toggleMapTypeId() {
    setMapTypeId(mapTypeId == "roadmap" ? "satellite" : "roadmap");
  }

  const directionsOptions = {
    origin: origin,
    destination: destination,
    travelMode: "DRIVING",
  };

  const directionsCallback = (response) => {
    if (response !== null) {
      setDirections(response);
    }
  };

  const startDirection = (position) => {
    setOrigin(props.center);
    setDestination(position);
  };

  const openMap = ({ lat, lng }, title) => {
    const url = `https://www.google.com/maps?q=${lat},${lng}&t=${encodeURIComponent(
      title
    )}`;
    window.open(url);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props.initialLocation}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      mapTypeId={props.mapTypeId}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker
        position={props.center}
        label={{
          text: "You are here",
          fontSize: "16px",
          fontWeight: "bold",
          anchor: { x: 20, y: 40 },
        }}
      />
      {props.markers.map((marker, index) => {
        var index = index;
        const radius = parseFloat(marker.customer.radius); // Parse the radius as a float
        const shouldRenderCircle = radius > 0;
        return (
          <>
            <Marker
              key={index}
              position={marker.position}
              // label={{
              //   text: marker.label,
              //   fontSize: "10",
              // }}
              onClick={() => handleMarkerClick(index)}
            >
              {info == index && (
                <InfoWindow onCloseClick={setInfo}>
                  <span style={{ padding: "10px" }}>
                    {marker.customer.image_path && (
                      <img
                        src={constants.imagePath + marker.customer.image_path}
                        style={{
                          width: "90px",
                          height: "auto",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                    )}
                    <h4>
                      {marker.label} ({marker.customer.id})
                    </h4>
                    <span>
                      {getShopTypeLabel(marker.customer.shop_type_id)}
                    </span>
                    <br />
                    <i>{marker.customer.created_by_name}</i>
                    <br />
                    <Rate
                      readOnly
                      defaultValue={marker.customer.rating}
                      allowHalf
                      size="xs"
                    />
                    <br />
                    <br />
                    <Button
                      appearance="primary"
                      onClick={() => props.selectCustomer(marker.customer)}
                      size="xs"
                    >
                      View Customer
                    </Button>
                    <br />
                    <Checkbox
                      checked={props.favs.indexOf(marker.customer.id) !== -1}
                      onChange={(value) => props.setFavs(marker)}
                    >
                      {" "}
                      Favorite
                    </Checkbox>
                    <br />
                    {/* <Button
                      appearance="primary"
                      onClick={() =>
                        props.locateMe(() => startDirection(marker.position))
                      }
                      size="xs"
                    >
                      Show Direction
                    </Button> */}

                    {marker.customer.followups_latest && (
                      <Tag size="sm">
                        {marker.customer?.followups_latest?.mode == "Phone" ? (
                          <PhoneFillIcon />
                        ) : (
                          <MemberIcon />
                        )}
                        &nbsp;
                        {shortenedText(
                          marker.customer.followups_latest.remarks
                        )}
                        {" - "}
                        {moment(
                          marker.customer.followups_latest.created_at
                        ).fromNow()}
                      </Tag>
                    )}
                    <br />
                  </span>
                </InfoWindow>
              )}

              {origin && destination && (
                <DirectionsService
                  options={directionsOptions}
                  callback={directionsCallback}
                />
              )}
              {origin && destination && directions && (
                <DirectionsRenderer directions={directions} />
              )}

              {shouldRenderCircle && (
                <Circle
                  key={`circle-${index}`}
                  center={marker.position} // Center of the circle
                  radius={radius * 1000} // Convert radius from kilometers to meters
                  options={{
                    strokeColor: "red",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: "yellow",
                    fillOpacity: 0.15,
                  }}
                />
              )}
            </Marker>
          </>
        );
      })}
      {/* plygon for routes */}
      {props.sessionCheck.routes.map((route, index) => {
        const coordinates = JSON.parse(route.cord1);
        if (Array.isArray(coordinates) && coordinates.length > 0) {
          // Check that each coordinate has valid lat and lng properties
          const isValidCoordinates = coordinates.every(
            (coord) =>
              typeof coord.lat === "string" &&
              typeof coord.lng === "string" &&
              !isNaN(parseFloat(coord.lat)) &&
              !isNaN(parseFloat(coord.lng))
          );

          if (isValidCoordinates) {
            return (
              <Polygon
                key={index}
                options={{
                  paths: coordinates.map((coord) => ({
                    lat: parseFloat(coord.lat),
                    lng: parseFloat(coord.lng),
                  })),
                  strokeColor: colors[index % colors.length], // Use colors in a cyclic manner
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: colors[index % colors.length], // Use colors in a cyclic manner
                  fillOpacity: 0,
                }}
              />
            );
          }
        }
        return null; // Skip routes with invalid or empty coordinates
      })}
      {/* Draw polyline using user_locations_today */}
      <Polyline
        path={props.sessionCheck.user_locations_today.map((location) => ({
          lat: location.geolocation.lat,
          lng: location.geolocation.lng,
        }))}
        options={{
          strokeColor: "#00FF00",
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default MapView;
