import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  TagPicker,
  Table,
  Pagination,
  Panel,
  Stack,
  ButtonGroup,
  Button,
} from "rsuite";
import constants from "../constants";
import CandidateView from "./sub-pages/CandidateView";

const { Column, HeaderCell, Cell } = Table;

const defaultColumns = [
  {
    key: "id",
    label: "Id",
    fixed: true,
    width: 70,
  },
  {
    key: "name",
    label: "Name",
    fixed: true,
    width: 130,
  },
  {
    key: "phone",
    label: "Phone",
    width: 123,
  },

  {
    key: "gender",
    label: "Gender",
    width: 200,
  },
  {
    key: "location",
    label: "Location",
    flexGrow: 1,
  },
];

class Candidates extends Component {
  state = {
    columnKeys: ["id", "name", "phone", "location"],
    data: [],
    loading: true,
    page: 1,
    createMode: false,
    selectedData: null,
  };

  setColumnKeys = (columnKeys) => {
    this.setState({ columnKeys });
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  back = () => {
    this.setState({ selectedData: null, createMode: false });
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = (page = 1) => {
    this.setLoading();
    this.setState({ page });
    fetch(constants.url + "candidates?page=" + page, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ data });
        this.setLoading(false);
      })
      .catch((error) => {
        this.setLoading(false);
        console.error(error);
      });
  };
  render() {
    var { data } = this.state;
    var columns = defaultColumns.filter((column) =>
      this.state.columnKeys.some((key) => key === column.key)
    );

    if (this.state.createMode) {
      return <CandidateView back={this.back} />;
    }

    if (this.state.selectedData) {
      return <CandidateView data={this.state.selectedData} back={this.back} />;
    }
    return (
      <div>
        <Panel
          bordered
          header={
            <Stack justifyContent="space-between">
              <h4>Candidates</h4>
              <ButtonGroup>
                <Button
                  onClick={() => this.setState({ createMode: true })}
                  appearance="primary"
                >
                  Add
                </Button>
              </ButtonGroup>
            </Stack>
          }
        >
          <TagPicker
            data={defaultColumns}
            labelKey="label"
            valueKey="key"
            value={this.state.columnKeys}
            onChange={this.setColumnKeys}
            cleanable={false}
          />
          <hr />
          <div style={{ height: "auto" }}>
            <Table
              loading={this.state.loading}
              height={300}
              hover={true}
              fillHeight={true}
              showHeader={true}
              autoHeight={true}
              data={data?.data}
              bordered={true}
              cellBordered={false}
              headerHeight={40}
              rowHeight={46}
              onRowClick={(selectedData) => this.setState({ selectedData })}
            >
              {columns.map((column) => {
                const { key, label, ...rest } = column;
                return (
                  <Column {...rest} key={key}>
                    <HeaderCell>{label}</HeaderCell>
                    <Cell dataKey={key} />
                  </Column>
                );
              })}
            </Table>
          </div>
          <Pagination
            prev
            last
            next
            first
            size="md"
            total={data?.total}
            limit={data?.per_page}
            activePage={this.state.page}
            onChangePage={(page) => this.loadData(page)}
          />
        </Panel>
      </div>
    );
  }
}

export default Candidates;
