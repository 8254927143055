import Cookies from "js-cookie";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import constants, { alertError, alertInfo } from "../constants";
import { stringSimilarity } from "string-similarity-js";

export function getProgressColor(progress) {
  // Convert progress to a value between 0 and 1
  const normalizedProgress = progress / 100;

  // Define color ranges
  const startColor = [255, 0, 0]; // Red for lower progress
  const endColor = [0, 255, 0]; // Green for higher progress

  // Interpolate between startColor and endColor based on progress
  const color = startColor.map((channel, index) => {
    return Math.round(
      channel + (endColor[index] - channel) * normalizedProgress
    );
  });

  // Construct color string in RGB format
  const rgbColor = `rgb(${color.join(",")})`;

  return rgbColor;
}

export function confirm(message, yes, no = null) {
  var conf = window.confirm(message);
  if (conf) {
    yes();
  } else if (no) {
    no();
  }
  // confirmAlert({
  //   title: "Confirm to submit",
  //   message,
  //   buttons: [
  //     {
  //       label: "Yes",
  //       onClick: () => {
  //         yes();
  //       },
  //     },
  //     {
  //       label: "No",
  //       onClick: () => {
  //         if (no) {
  //           no();
  //         }
  //       },
  //     },
  //   ],
  // });
}

export function copyToClipboard(text) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied to clipboard:", text);
    })
    .catch((err) => {
      console.error("Failed to copy text to clipboard:", err);
    });
  alertInfo("Copied to clipboard");
}

export function pasteText(onPaste) {
  navigator.clipboard
    .readText()
    .then((text) => {
      console.log("Text read from clipboard:", text);
      // Use the clipboard text as needed, e.g., insert it into an input field
      onPaste(text);
    })
    .catch((err) => {
      console.error("Failed to read text from clipboard:", err);
    });
}

export function truncatedString(str, len = 5) {
  return str.length > len ? `${str.slice(0, len)}...` : str;
}

export function getFavCustomers(onDone) {
  const favsJSON = Cookies.get("favs");

  if (favsJSON) {
    const favs = JSON.parse(favsJSON);
    onDone(favs);
    return favs;
  } else {
    const favs = [];
    onDone(favs);
    return favs;
  }
}

export function setFavCustomer(customer, onDone) {
  const favsJSON = Cookies.get("favs") || "[]";
  const favs = JSON.parse(favsJSON);

  const favIndex = favs.indexOf(customer.id);

  if (favIndex !== -1) {
    favs.splice(favIndex, 1);
  } else {
    favs.push(customer.id);
  }

  // Convert the updated favs array back to JSON and store it in cookies
  Cookies.set("favs", JSON.stringify(favs), { expires: 365 });

  // Update the state with the new favs array
  onDone(favs);
}

export function findSuitableHashTags(
  hashTags,
  stringArray,
  similarityThreshold = 0.5
) {
  const suitableHashTags = [];
  const addedHashTags = new Set();

  // Iterate over each string in the stringArray
  stringArray.forEach((str) => {
    let maxSimilarity = -1;
    let mostSimilarHashTag = null;

    // Iterate over each hash tag to find the most similar one
    hashTags.forEach((hashTag) => {
      // Skip hash tags that have already been added
      if (addedHashTags.has(hashTag.name)) return;

      const similarity = stringSimilarity(str, hashTag.name);

      // Update the most similar hash tag if the similarity is higher
      if (similarity > maxSimilarity) {
        maxSimilarity = similarity;
        mostSimilarHashTag = hashTag.name;
      }
    });

    // Add the most similar hash tag to the suitableHashTags array
    if (maxSimilarity >= similarityThreshold && mostSimilarHashTag) {
      suitableHashTags.push(mostSimilarHashTag);
      addedHashTags.add(mostSimilarHashTag);
    }
  });

  return suitableHashTags;
}

export function extractEnglishWords(sentence) {
  // Regular expression to match English words and words with common special characters
  const englishWordRegex = /[a-zA-Z.'&,!?-]+/g;

  // Extract all English words from the sentence
  const englishWords = sentence.match(englishWordRegex);

  // Join the English words back into a sentence
  const result = englishWords ? englishWords.join(" ") : "";

  // If the result is empty, prompt the user to edit the sentence
  if (!result) {
    const editedSentence = prompt("Correct the word:", sentence);
    return editedSentence ? editedSentence : "";
  }

  return result;
}

export function priceFromList(product, qty) {
  var unit_price = product?.unit_price;
  if (product?.prices?.length > 0) {
    const matchingPrice = product.prices.find(
      (price) => qty >= price.min && qty <= price.max
    );
    if (matchingPrice) {
      unit_price = matchingPrice.unit_price;
    }
  }
  return unit_price;
}

export function deepCopy(object) {
  const deepCopy = JSON.parse(JSON.stringify(object));
  return deepCopy;
}

export function changePassword(user_id) {
  var newPass = window.prompt("Enter new password");

  // Check if the password is provided and at least 9 characters long
  if (!newPass || newPass.length < 9) {
    alertError("Password is too short! It must be at least 9 characters.");
    return;
  }

  // Check if the password contains at least one capital letter
  const hasUpperCase = /[A-Z]/.test(newPass);
  if (!hasUpperCase) {
    alertError("Password must contain at least one uppercase letter.");
    return;
  }

  // Check if the password contains at least one number
  const hasNumber = /[0-9]/.test(newPass);
  if (!hasNumber) {
    alertError("Password must contain at least one number.");
    return;
  }

  // Check if the password contains at least one special character
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPass);
  if (!hasSpecialChar) {
    alertError("Password must contain at least one special character.");
    return;
  }

  fetch(constants.url + "users/" + user_id + "/cpass", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
    body: JSON.stringify({ newPass }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success == false) {
        alertError("Password change failed");
      } else {
        alertInfo("Password Change Success!");
      }
    })
    .catch((error) => {
      alertError("Password change failed");
    });
}

export function greetingsNow() {
  const now = new Date();
  const hours = now.getHours();
  const dayOfWeek = now.toLocaleDateString("en-US", { weekday: "long" });
  const userMoods = [
    "feeling energetic",
    "having a cozy day",
    "on the go",
    "looking productive",
    "in a relaxed mood",
    "ready to conquer the world",
    "in a cheerful mood",
    "taking it easy",
  ];

  // Greeting based on time of day
  let greeting = "Hello there";
  if (hours < 5) {
    greeting = "Burning the midnight oil, are we?";
  } else if (hours < 12) {
    greeting = "Good morning! Rise and shine!";
  } else if (hours < 17) {
    greeting = "Good afternoon! Keep up the great work!";
  } else {
    greeting = "Good evening! Hope you had a fulfilling day!";
  }

  // Additional messages based on day of the week
  const dayGreetings = {
    Sunday: [
      "Happy Sunday! Time to recharge for the week ahead.",
      "Sunday Funday! Enjoy every moment!",
      "Lazy Sunday vibes are in the air.",
    ],
    Monday: [
      "Happy Monday! Let’s start strong!",
      "Monday motivation: You got this!",
      "A fresh week begins! Make it count!",
    ],
    Tuesday: [
      "Happy Tuesday! Keep the momentum going!",
      "Terrific Tuesday! A great day to be productive.",
      "It's Taco Tuesday! Don’t forget to celebrate.",
    ],
    Wednesday: [
      "Happy Wednesday! We're halfway through!",
      "Midweek magic is in the air!",
      "Wondrous Wednesday! Keep pushing forward!",
    ],
    Thursday: [
      "It's Thursday! Almost there!",
      "Thankful Thursday! Reflect on your week.",
      "Thriving on this Thursday, aren't we?",
    ],
    Friday: [
      "Happy Friday! The weekend is almost here!",
      "It's finally Friday! Time to unwind!",
      "Fantastic Friday! Celebrate your achievements!",
    ],
    Saturday: [
      "Happy Saturday! A perfect day for adventure!",
      "Super Saturday vibes! Enjoy your time!",
      "Saturday is here! Time to relax and have fun!",
    ],
  };

  // Randomly select a user mood
  const randomMood = userMoods[Math.floor(Math.random() * userMoods.length)];

  // Combine greeting with day-specific and mood messages
  const selectedDayGreeting =
    dayGreetings[dayOfWeek][
      Math.floor(Math.random() * dayGreetings[dayOfWeek].length)
    ];
  return `${greeting} ${selectedDayGreeting} It seems like you're ${randomMood}.`;
}
