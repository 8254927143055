import React, { Component } from "react";

import {
  Button,
  Col,
  Drawer,
  Grid,
  IconButton,
  Input,
  List,
  Loader,
  Modal,
  Nav,
  Navbar,
  Panel,
  Stack,
  Row,
  SelectPicker,
  Sidenav,
  Badge,
  InputPicker,
  Tag,
  RadioGroup,
  InputGroup,
  Radio,
  Checkbox,
  Toggle,
  Progress,
  ButtonGroup,
  Divider,
  DateRangePicker,
} from "rsuite";
import NewCustomerForm, {
  rateTexts,
  rateColors,
} from "../../../components/new-customer-form";
import MapView from "../../map";
import PlusIcon from "@rsuite/icons/Plus";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import PhoneFillIcon from "@rsuite/icons/PhoneFill";
import CloseIcon from "@rsuite/icons/Close";
import MemberIcon from "@rsuite/icons/Member";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import CustomerView from "./customer-view";
import Cookies from "js-cookie";
import constants, {
  alertError,
  alertInfo,
  isInCurrentMonth,
  isToday,
  shortenedText,
} from "../../../constants";
import moment from "moment";
import TravelDistance from "./TravelDistance";
import SalesQtyList from "../../../Modules/SalesQtyList";
import { copyToClipboard } from "../../../Helpers/Utilities";

class Customers extends Component {
  state = {
    enableMap: false,
    mapTypeId: "roadmap",
    followType: "nofollow",
    customerModal: false,
    customerListType: "all",
    markers: [],
    selectedCustomer: null,
    selectedCustomerIndex: null,
    customerListModal: false,
    loading: false,
    sales: null,
    search: "",
    customerListFilter: "A",
    travelToday: 0,
    summaryDrawer: false,
    saleProgressClosed: false,
    favs: null,
    sort: "nearest",
    showUseless: false,
    salesReport: null,
    dateRange: [new Date(), new Date()],
  };

  summaryDrawer = (summaryDrawer = true) => {
    this.setState({ summaryDrawer });
  };

  loading = (loading = true) => {
    this.setState({ loading });
  };

  getFavs = () => {
    const favsJSON = Cookies.get("favs");

    if (favsJSON) {
      // The "favs" cookie exists, so we can parse it and update the state
      const favs = JSON.parse(favsJSON);
      this.setState({ favs });
      console.log("favs");
      console.log(favs);
    } else {
      // The "favs" cookie doesn't exist, so initialize favs as an empty array
      const favs = [];
      this.setState({ favs });
    }
  };
  setFavs = (fav) => {
    const favsJSON = Cookies.get("favs") || "[]";
    const favs = JSON.parse(favsJSON);

    const favIndex = favs.indexOf(fav.customer.id);

    if (favIndex !== -1) {
      favs.splice(favIndex, 1);
    } else {
      favs.push(fav.customer.id);
    }

    // Convert the updated favs array back to JSON and store it in cookies
    Cookies.set("favs", JSON.stringify(favs), { expires: 365 });

    // Update the state with the new favs array
    this.setState({ favs });
  };

  customerModal = (customerModal = true) => {
    this.props.locateMe();
    this.setState({ customerModal });
  };

  customerListModal = (customerListModal = true, customerListType = "all") => {
    this.setState({ customerListModal, customerListType });
  };

  getCustomerList = () => {
    var { customerListType, markers, sort } = this.state;

    if (customerListType == "today") {
      markers = markers.filter((item) => isToday(item.customer.created_at));
    }
    if (customerListType == "month") {
      markers = markers.filter((item) =>
        isInCurrentMonth(item.customer.created_at)
      );
    }

    if (sort == "nearest") {
      if (this.props.currentLocation != null) {
        markers = this.findNearestCoordinates(
          this.props.currentLocation,
          markers
        );
      }
    } else if (sort == "id") {
      markers = markers.sort((a, b) => {
        const idA = a.customer.id;
        const idB = b.customer.id;

        // Compare the IDs
        if (idA < idB) return -1;
        if (idA > idB) return 1;
        return 0;
      });
    } else if (sort == "followup") {
      markers = markers.sort((a, b) => {
        const dateA = a.customer.followups_latest
          ? new Date(a.customer.followups_latest.created_at)
          : null;
        const dateB = b.customer.followups_latest
          ? new Date(b.customer.followups_latest.created_at)
          : null;

        return dateA && dateB ? dateA - dateB : dateA ? -1 : dateB ? 1 : 0;
      });
    } else if (sort == "sales") {
      markers = markers.sort((a, b) => {
        const dateA = a.customer.sales_latest
          ? new Date(a.customer.sales_latest.created_at)
          : null;
        const dateB = b.customer.sales_latest
          ? new Date(b.customer.sales_latest.created_at)
          : null;

        return dateA && dateB ? dateA - dateB : dateA ? -1 : dateB ? 1 : 0;
      });
    } else if (sort == "rating") {
      markers = markers.sort((a, b) => {
        const idA = parseFloat(a.customer.rating);
        const idB = parseFloat(b.customer.rating);

        // Compare the IDs
        if (idA > idB) return -1;
        if (idA < idB) return 1;
        return 0;
      });
    }

    if (!this.state.showUseless) {
      markers = markers.filter(
        (marker) => parseFloat(marker.customer.rating) > 1
      );
    }

    return markers;
  };

  getFilteredCustomerList = () => {
    const search = this.state.search.toLowerCase();
    const customerListFilter = this.state.customerListFilter;
    var { favs } = this.state;

    return this.getCustomerList().filter((item) => {
      // Initialize shouldReturn to true
      let shouldReturn = true;

      // Check search-related conditions
      shouldReturn =
        item.label.toLowerCase().includes(search) ||
        (item.customer?.phone + "").toLowerCase().includes(search) ||
        (item.customer?.address + "").toLowerCase().includes(search) ||
        (item.customer?.created_by_name + "").toLowerCase().includes(search) ||
        (item.customer?.followups_latest?.remarks + "")
          .toLowerCase()
          .includes(search) ||
        (item.customer.id + "").toLowerCase().includes(search);

      // Apply filter conditions
      if (customerListFilter === "D") {
        shouldReturn =
          shouldReturn &&
          item.customer.sales_latest &&
          item.customer.sales_latest.status === "delivery";
      } else if (customerListFilter === "R") {
        shouldReturn =
          shouldReturn && item.customer.latest_sales_returns.length > 0;
      } else if (customerListFilter === "F") {
        shouldReturn = shouldReturn && favs.indexOf(item.customer.id) !== -1;
      } else if (customerListFilter === "RBP") {
        shouldReturn = shouldReturn && item.customer.shop_type_id == 6;
      }

      return shouldReturn;
    });
  };

  selectNextCustomer = () => {
    console.log("next customer click called");
    var { selectedCustomerIndex, selectedCustomer } = this.state;
    if (selectedCustomerIndex != null) {
      if (nextIndex == selectedCustomerIndex) {
        window.AndroidInterface.showToast("List Ended");
        return;
      }
      var nextIndex =
        selectedCustomerIndex < this.getFilteredCustomerList().length - 1
          ? selectedCustomerIndex + 1
          : selectedCustomerIndex;
      this.selectCustomer(
        this.getFilteredCustomerList()[nextIndex].customer,
        nextIndex
      );
    }
  };

  selectPreviousCustomer = () => {
    var { selectedCustomerIndex, selectedCustomer } = this.state;
    if (selectedCustomerIndex != null) {
      var nextIndex =
        selectedCustomerIndex > 0
          ? selectedCustomerIndex - 1
          : selectedCustomerIndex;
      if (nextIndex == selectedCustomerIndex) {
        window.AndroidInterface.showToast("List Ended");
        return;
      }
      this.selectCustomer(
        this.getFilteredCustomerList()[nextIndex].customer,
        nextIndex
      );
    }
  };

  calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  // Function to find the nearest coordinates from the list of coordinates
  findNearestCoordinates = (currentLocation, coordinatesList) => {
    const distancesWithCoordinates = [];

    for (const coordinate of coordinatesList) {
      const distance = this.calculateDistance(
        currentLocation.lat,
        currentLocation.lng,
        coordinate.position.lat,
        coordinate.position.lng
      );

      distancesWithCoordinates.push({ coordinate, distance });
    }

    // Sort the distancesWithCoordinates array based on distance (nearest first)
    distancesWithCoordinates.sort((a, b) => a.distance - b.distance);

    // Return all the sorted coordinates without limiting the results
    return distancesWithCoordinates.map((item) => item.coordinate);
  };

  back = () => {
    this.selectCustomer(null, 0);
    this.loadCustomers();

    // this.customerModal(false);
  };

  selectCustomer = (selectedCustomer, selectedCustomerIndex = null) => {
    // console.log("select customer: " + selectedCustomer?.shop_name);
    // console.log("select customer index: " + selectedCustomerIndex);
    window.saveScroll();
    this.setState({ selectedCustomer, selectedCustomerIndex });
  };

  componentDidMount = () => {
    this.loadCustomers();
    this.getFavs();
    window.refreshFunction = this.loadCustomers;
  };

  loadSalesReport = (dateRange) => {
    this.setState({ dateRange });

    const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
    const endDate = moment(dateRange[1]).format("YYYY-MM-DD");

    const url =
      constants.url +
      "sales-report" +
      `?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
        endDate
      )}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ salesReport: data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  loadCustomers = () => {
    this.loading();
    fetch(constants.url + "customers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        var markers = [];
        data.data.filter((customer) => {
          markers.push({
            position: JSON.parse(customer.geo_location),
            label: customer.shop_name,
            customer: customer,
          });
        });
        console.log(markers);
        this.setState({ markers });
        this.loading(false);
        window.scrollToSaved();
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
      });
  };

  checkIsFinite = (value, defaultValue = 100) => {
    return isFinite(value) ? value : defaultValue;
  };

  renderColor = (percent) => {
    if (percent < 10) {
      return "red"; // Dark Red
    }

    if (percent < 30) {
      return "orange"; // Purple
    }

    if (percent < 40) {
      return "cyan"; // Deep Purple
    }

    if (percent < 60) {
      return "blue"; // Blue
    }

    if (percent < 70) {
      return "green";
    }

    return "green";
  };

  render() {
    var { salesReport, favs } = this.state;
    var month = new Date().toLocaleString("en-US", { month: "long" });
    var customerToday = this.state.markers.filter((item) =>
      isToday(item.customer.created_at)
    ).length;
    var customerThisMonth = this.state.markers.filter((item) =>
      isInCurrentMonth(item.customer.created_at)
    ).length;

    var empType = this.props.sessionCheck?.active_employment?.employment_type;
    if (this.state.selectedCustomer != null) {
      return (
        <CustomerView
          data={this.state.selectedCustomer}
          back={this.back}
          currentLocation={this.props.currentLocation}
          sessionCheck={this.props.sessionCheck}
          selectedCustomerIndex={this.state.selectedCustomerIndex}
          selectNextCustomer={this.selectNextCustomer}
          selectPreviousCustomer={this.selectPreviousCustomer}
        />
      );
    }
    return (
      <div>
        {/*         
        <strong>
          <TravelDistance
            canClose={true}
            sessionCheck={this.props.sessionCheck}
            locations={this.props.sessionCheck.user_locations_today}
            customerPercent={this.checkIsFinite(
              (customerToday / empType?.min_customers) * 100
            )}
            salesValuePercent={this.checkIsFinite(
              (salesThisMonth / empType?.min_sales_value) * 100
            )}
            salesVolumePercent={this.checkIsFinite(
              (salesThisMonthVolume / empType?.min_sales_volume) * 100
            )}
          />
        </strong> */}

        {empType != null && (
          <div>
            {/* CUSTOMERS PROGRESS BEGIN */}
            <Divider>
              <strong>Customers</strong>
            </Divider>
            <Stack justifyContent="space-between">
              <Button
                appearance="link"
                size="sm"
                color={this.renderColor(
                  (customerToday / empType?.min_customers) * 100
                )}
                onClick={() => this.customerListModal(true, "today")}
              >
                Today {customerToday}
              </Button>
              <Button
                appearance="link"
                size="sm"
                onClick={() => this.customerListModal(true, "month")}
              >
                {month} {customerThisMonth}
              </Button>
              <Button
                appearance="link"
                size="sm"
                onClick={this.customerListModal}
              >
                Total {this.state.markers.length}
              </Button>
              <Button
                appearance="primary"
                size="md"
                onClick={this.customerModal}
              >
                <PlusIcon />
              </Button>
            </Stack>
            {empType?.min_customers != 0 && (
              <Progress.Line
                strokeWidth={3}
                percent={this.checkIsFinite(
                  (customerToday / empType?.min_customers) * 100
                ).toFixed(2)}
                status="active"
                strokeColor={this.renderColor(
                  (customerToday / empType?.min_customers) * 100
                )}
              />
            )}
            {/* CUSTOMERS PROGRESS ENDS */}

            {/* SALES PROGRESS BEGIN */}
            {/*
            <Divider>
              <strong>{`Sales ${month}`}</strong>
            </Divider>
            {!this.state.saleProgressClosed && (
              <Stack justifyContent="space-between">
                <Tag
                  color={this.renderColor(
                    (salesThisMonth / empType?.min_sales_value) * 100
                  )}
                >
                  {`₹${salesThisMonth.toFixed(2)} of`}{" "}
                  <span>{`₹${empType?.min_sales_value}`}</span>
                </Tag>

                <Tag
                  color={this.renderColor(
                    (salesThisMonthVolume / empType?.min_sales_volume) * 100
                  )}
                >{`${salesThisMonthVolume} of ${
                  empType?.min_sales_volume ?? 0
                } pcs`}</Tag>

                <Button
                  size="sm"
                  appearance="primary"
                  onClick={this.summaryDrawer}
                >
                  Summary
                </Button>
                <Button
                  onClick={() => this.setState({ saleProgressClosed: true })}
                  size="xs"
                >
                  <CloseOutlineIcon />
                </Button>
              </Stack>
            )}

            {empType?.min_sales_value != 0 && (
              <Progress.Line
                strokeWidth={3}
                percent={this.checkIsFinite(
                  (salesThisMonth / empType?.min_sales_value) * 100
                ).toFixed(2)}
                status="active"
                strokeColor={this.renderColor(
                  (salesThisMonth / empType?.min_sales_value) * 100
                )}
              />
            )}

            {empType?.min_sales_volume != 0 && (
              <Progress.Line
                strokeWidth={3}
                percent={this.checkIsFinite(
                  (salesThisMonthVolume / empType?.min_sales_volume) * 100
                ).toFixed(2)}
                status="active"
                strokeColor={this.renderColor(
                  (salesThisMonthVolume / empType?.min_sales_volume) * 100
                )}
              />
            )}

                */}

            {/* SALES PROGRESS ENDS */}
          </div>
        )}

        <br />

        {this.state.enableMap ? (
          <div>
            <span>
              You are at:{" "}
              {this.props.currentAddress == null ? (
                <Loader size="xs" content="" />
              ) : (
                this.props.currentAddress
              )}
            </span>
            <Button appearance="link" onClick={this.props.locateMe}>
              Update
            </Button>
            <SelectPicker
              data={this.getFilteredCustomerList()?.map((item, index) => ({
                label: item.label,
                value: index,
              }))}
              block
              onChange={(value) =>
                this.props.focusTo(this.getFilteredCustomerList()[value])
              }
              placeholder="Search customer"
            />
            <br />
            {this.props.currentLocation != null && (
              <MapView
                mapTypeId={this.state.mapTypeId}
                setMap={this.props.setMap}
                markers={this.getFilteredCustomerList()}
                center={this.props.currentLocation}
                selectCustomer={this.selectCustomer}
                locateMe={this.props.locateMe}
                initialLocation={
                  this.state.followType == "nofollow"
                    ? this.props.initialLocation
                    : this.props.currentLocation
                }
                sessionCheck={this.props.sessionCheck}
                favs={favs}
                setFavs={this.setFavs}
              />
            )}
            <br />
            <Stack justifyContent="space-around">
              <Toggle
                size="md"
                checkedChildren="Satellite"
                unCheckedChildren="Roadmap"
                onChange={(change) => {
                  this.setState({
                    mapTypeId:
                      this.state.mapTypeId == "satellite"
                        ? "roadmap"
                        : "satellite",
                  });
                }}
              />
              <Toggle
                size="md"
                checkedChildren="Follow"
                unCheckedChildren="Nofollow"
                onChange={(change) => {
                  this.setState({
                    followType:
                      this.state.followType == "nofollow"
                        ? "follow"
                        : "nofollow",
                  });
                }}
              />
            </Stack>
          </div>
        ) : (
          <Button onClick={() => this.setState({ enableMap: true })}>
            ENABLE MAP
          </Button>
        )}

        <br />
        <Button onClick={this.summaryDrawer} block>
          Show Summary
        </Button>
        <br />

        {/* SUMMARY DRAWER BEIGNS */}
        <Drawer
          placement="bottom"
          open={this.state.summaryDrawer}
          onClose={() => this.summaryDrawer(false)}
        >
          <Drawer.Header>
            <Drawer.Title>Sales Summary</Drawer.Title>
            <Drawer.Actions>
              <InputGroup>
                <DateRangePicker
                  value={this.state.dateRange}
                  onChange={this.loadSalesReport}
                  cleanable={false}
                  loading={this.state.loading}
                  placement="auto"
                  showOneCalendar
                />
                <InputGroup.Button
                  onClick={() => this.loadSalesReport(this.state.dateRange)}
                >
                  Load
                </InputGroup.Button>
              </InputGroup>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <Divider>Payments Summary</Divider>
            {this.state?.salesReport?.payments && (
              <>
                <p>
                  <strong>Cash: </strong>
                  {this.state.salesReport.payments.reduce(
                    (total, payment) =>
                      payment.type === "Cash" ? total + payment.amount : total,
                    0
                  )}
                </p>
                <p>
                  <strong>UPI / Bank: </strong>
                  {this.state.salesReport.payments.reduce(
                    (total, payment) =>
                      payment.type === "UPI" ? total + payment.amount : total,
                    0
                  )}
                </p>
                <p>
                  <strong>Online: </strong>
                  {this.state.salesReport.payments.reduce(
                    (total, payment) =>
                      payment.type === "Online"
                        ? total + payment.amount
                        : total,
                    0
                  )}
                </p>
                <p>
                  <strong>Total: </strong>
                  {this.state.salesReport.payments.reduce(
                    (total, payment) => total + payment.amount,
                    0
                  )}
                </p>
                <SalesQtyList
                  label="Calculate Sales Quantity from Payments"
                  payments={this.state.salesReport.payments}
                />
                <SalesQtyList
                  label="Calculate Returns Quantity"
                  returns={this.state.salesReport.returns}
                />
              </>
            )}
          </Drawer.Body>
        </Drawer>
        {/* SUMMARY DRAWER ENDS */}

        {/* CREATE CUSTOMER MODAL */}
        <Modal
          open={this.state.customerModal}
          onClose={() => this.customerModal(false)}
        >
          <Modal.Header>
            <Modal.Title>Create Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewCustomerForm
              currentLocation={this.props.currentLocation}
              currentAddress={this.props.currentAddress}
              locateMe={this.props.locateMe}
              selectCustomer={this.selectCustomer}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.customerModal(false)}
              appearance="subtle"
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Customer list modal */}
        <Modal
          overflow
          open={this.state.customerListModal}
          onClose={() => this.customerListModal(false)}
        >
          <Modal.Header>
            <Modal.Title>
              Customers ({this.getFilteredCustomerList()?.length ?? 0})
              <Checkbox
                checked={this.state.showUseless}
                onChange={(value) =>
                  this.setState({ showUseless: !this.state.showUseless })
                }
              >
                {" "}
                {this.state.showUseless ? "Hide Useless" : "Show Useless"}
              </Checkbox>
              <SalesQtyList customers={this.getFilteredCustomerList()} />
              {this.state.customerListFilter == "F" && (
                <ButtonGroup size="sm">
                  <Button onClick={() => copyToClipboard(Cookies.get("favs"))}>
                    Copy Favs
                  </Button>
                  <Button
                    onClick={() => {
                      const input = window.prompt("Paste your favs here");
                      if (!input) return;

                      try {
                        const parsedInput = JSON.parse(input);
                        if (!Array.isArray(parsedInput))
                          throw new Error("Invalid favs format.");

                        Cookies.set("favs", input);
                        this.getFavs();
                        alertInfo("Favs set success!");
                      } catch (error) {
                        alertError("Invalid input: " + error.message);
                      }
                    }}
                  >
                    Set Favs
                  </Button>
                </ButtonGroup>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body id="scrollable">
            {!this.state.loading && (
              <div>
                <InputGroup>
                  <Input
                    block
                    value={this.state.search}
                    onChange={(value) => this.setState({ search: value })}
                    placeholder="Search customer"
                    size="xs"
                    cleanable
                  />
                  <InputGroup.Button
                    onClick={() => this.setState({ search: "" })}
                  >
                    <CloseIcon />
                  </InputGroup.Button>
                  <InputPicker
                    block
                    size="sm"
                    placeholder="Sort by"
                    data={[
                      { label: "ID", value: "id" },
                      { label: "Nearest", value: "nearest" },
                      { label: "Followup date", value: "followup" },
                      { label: "Sales date", value: "sales" },
                      { label: "Rating", value: "rating" },
                    ]}
                    value={this.state.sort}
                    onChange={(sort) => this.setState({ sort })}
                  />
                </InputGroup>
              </div>
            )}
            {this.state.loading ? (
              <Loader content="Loading customers.." />
            ) : (
              <List hover id="customer-list">
                {this.getFilteredCustomerList().map((marker, index) => {
                  return (
                    <List.Item key={index} className="flex-row">
                      {marker.customer.image_path && (
                        <img
                          src={constants.imagePath + marker.customer.image_path}
                          style={{
                            width: "100px",
                            height: "50px",
                            marginRight: "12px",
                          }}
                          data-action="zoom"
                        />
                      )}

                      <div>
                        <strong>
                          {marker.label} (#{marker.customer.id}){" "}
                          {marker.customer.latest_sales_returns.length > 0 && (
                            <Badge color="orange" content="Returns!" />
                          )}
                        </strong>
                        <br />
                        <i>{marker.customer.address}</i>
                        <div>
                          {marker.customer.sales_latest && (
                            <span>
                              {marker.customer.sales_latest.status ==
                              "delivery" ? (
                                <Tag size="sm" color="yellow">
                                  Delivery -{" "}
                                  {moment(
                                    marker.customer.sales_latest.delivery_date
                                  ).fromNow()}
                                </Tag>
                              ) : (
                                <Tag size="sm" color="blue">
                                  Sales -{" "}
                                  {moment(
                                    marker.customer.sales_latest.created_at
                                  ).fromNow()}{" "}
                                  - ({marker.customer.sales_latest.status})
                                </Tag>
                              )}
                            </span>
                          )}
                          {marker.customer.followups_latest && (
                            <Tag size="sm">
                              {marker.customer?.followups_latest?.mode ==
                              "Phone" ? (
                                <PhoneFillIcon />
                              ) : (
                                <MemberIcon />
                              )}
                              &nbsp;
                              {shortenedText(
                                marker.customer.followups_latest.remarks
                              )}
                              {" - "}
                              {moment(
                                marker.customer.followups_latest.created_at
                              ).fromNow()}
                            </Tag>
                          )}
                        </div>
                        <Button
                          appearance="link"
                          onClick={() =>
                            this.selectCustomer(marker.customer, index)
                          }
                        >
                          View
                        </Button>
                        <Checkbox
                          checked={favs.indexOf(marker.customer.id) !== -1}
                          onChange={(value) => this.setFavs(marker)}
                        >
                          {" "}
                          <CheckRoundIcon /> Favorite
                        </Checkbox>
                        <Tag
                          size="sm"
                          onClick={() => {
                            this.setState({
                              search: marker.customer.created_by_name,
                            });
                          }}
                        >
                          {marker.customer.created_by_name}
                        </Tag>
                      </div>
                      <span
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "3px",
                          fontSize: "3px !important !important",
                          backgroundColor: "#ededed",
                          borderRadius: "3.5px",
                          padding: "3px",
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            width: "5px",
                            height: "5px",
                            borderRadius: "2.5px",
                            backgroundColor: rateColors[marker.customer.rating],
                          }}
                        ></span>{" "}
                        &nbsp;
                        {rateTexts[marker.customer.rating]}
                      </span>
                    </List.Item>
                  );
                })}
              </List>
            )}
          </Modal.Body>
          <Modal.Footer>
            <br />
            <Stack justifyContent="space-between">
              <RadioGroup
                name="radioList"
                inline
                appearance="picker"
                value={this.state.customerListFilter}
                defaultValue="A"
                onChange={(value) => {
                  this.setState({ customerListFilter: value });
                }}
              >
                <Radio value="A">All</Radio>
                <Radio value="D">Delivery</Radio>
                <Radio value="R">Returns</Radio>
                <Radio value="F">Favorite</Radio>
                <Radio value="RBP">RBP</Radio>
              </RadioGroup>
              <Button
                onClick={() => this.customerListModal(false)}
                appearance="subtle"
              >
                Close
              </Button>
            </Stack>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Customers;
