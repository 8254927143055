import React, { Component } from "react";
import { List, Tag, Badge, Drawer } from "rsuite";
import moment from "moment";
import CustomerListItem from "../../../../components/CustomerListItem";
import Cookies from "js-cookie";
import CustomerView from "../customer-view";
import { getFavCustomers } from "../../../../Helpers/Utilities";

class UserCustomers extends Component {
  state = {
    selectedCustomer: null,
  };
  render() {
    var { data } = this.props;
    return (
      <div>
        <List size="lg" hover>
          {data.customers.map((customer, index) => {
            return (
              <List.Item
                key={index}
                index={index}
                onClick={() => this.props.selectCustomer(customer)}
              >
                <CustomerListItem
                  customer={customer}
                  favs={getFavCustomers(() => {})}
                  onFavUpdate={(favs) =>
                    Cookies.set("favs", JSON.stringify(favs), {
                      expires: 365,
                    })
                  }
                  selectCustomer={() => {
                    this.setState({ selectedCustomer: customer });
                  }}
                />
              </List.Item>
            );
          })}
        </List>

        <Drawer
          placement="bottom"
          size="full"
          open={this.state.selectedCustomer != null}
          onClose={() =>
            this.setState({
              selectedCustomer: null,
            })
          }
        >
          <Drawer.Header>
            <Drawer.Title>View Customer</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <CustomerView
              data={this.state.selectedCustomer}
              back={() => this.setState({ selectedCustomer: null })}
              currentLocation={this.props.currentLocation}
              sessionCheck={this.props.sessionCheck}
            />
          </Drawer.Body>
        </Drawer>
      </div>
    );
  }
}

export default UserCustomers;
