import React, { useState, useEffect } from "react";
import {
  InfoWindow,
  Marker,
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";

import { Button, Rate } from "rsuite";
import constants from "../constants";

const containerStyle = {
  width: "100%",
  height: "400px",
};

function SingleMap(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: constants.map_api,
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props.geo_location}
      zoom={18}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker
        position={props.geo_location}
        draggable={props.draggable}
        onDragEnd={(event) => {
          const newLocation = event.latLng;
          props.changeData("geo_location", JSON.stringify(newLocation));
        }}
      />
      {props.currentLocation && (
        <Marker position={props.currentLocation} label="You" />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default SingleMap;
