export function checkRoute(coord, routes) {
  if (!coord || !routes) {
    // console.log("either coord or roues is not provided");
    return null;
  }

  // Iterate through each route
  for (const route of routes) {
    var cords = JSON.parse(route.cord1);
    if (pointInPolygon(coord, cords)) {
      return route;
    }
  }

  return null; // Return null if the coordinate doesn't belong to any route
}

export function pointInPolygon(point, polygon) {
  var x = point.lat,
    y = point.lng;
  var inside = false;
  for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    var xi = polygon[i].lat,
      yi = polygon[i].lng;
    var xj = polygon[j].lat,
      yj = polygon[j].lng;

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}

export function getRouteName(id, routes) {
  return (
    (id && routes && routes.find((route) => route.id === id)?.route_name) ||
    null
  );
}
