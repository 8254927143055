import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputNumber,
  InputPicker,
  List,
  Loader,
  Modal,
  Panel,
  PanelGroup,
  Rate,
  Stack,
  Tag,
  Toggle,
  Uploader,
} from "rsuite";
import constants, { getProductImage } from "../constants";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";
import Compressor from "compressorjs";
import ProductCategories from "./sub-pages/ProductCategories";
import ProductPrices from "../Modules/ProdctPrices";

class Products extends Component {
  state = {
    data: [],
    loading: false,
    selected: null,
    selectedCategory: null,
    selectedSubCategory: null,
    createModal: false,
    dummy: {},
    categories: [],
    nonCategories: [],
    subCategories: [],
  };

  componentDidMount = () => {
    this.loadCategories();
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  createModal = (createModal = true) => {
    this.setState({ createModal });
    this.setDummy();
  };

  setDummy = () => {
    this.setState({
      dummy: {
        name: "",
        unit_price: "0",
        current_stock: "0",
        status: "0",
        store_item: "0",
      },
    });
  };

  changeDummy = (where, what) => {
    var { dummy } = this.state;
    dummy[where] = what;
    this.setState({ dummy });
  };

  loadCategories = () => {
    this.setLoading();
    fetch(constants.url + "categories", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          categories: data.categories,
          subCategories: data.subCategories,
          nonCategories: data.no_category_products,
        });
        this.setLoading(false);
      })
      .catch((error) => {
        this.setLoading(false);
        console.error(error);
      });
  };

  create = () => {
    var data = this.state.dummy;
    this.setLoading();
    fetch(constants.url + `prods`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ selected: data.product });
        this.setLoading(false);
        this.loadCategory();
        this.createModal(false);
      })
      .catch((error) => {
        console.error(error);
        // alert("Please check the entered data!");
        this.setLoading(false);
        this.loadCategory();
      });
  };

  change = (where, what) => {
    var { selected } = this.state;
    selected[where] = what;
    this.setState({ selected });
  };

  update = () => {
    var data = this.state.selected;
    this.setLoading();
    fetch(constants.url + `prods/${data.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ selected: data.product });
        this.setLoading(false);
        this.loadCategory();
      })
      .catch((error) => {
        console.error(error);
        // alert("Please check the entered data!");
        this.setLoading(false);
        this.loadCategory();
      });
  };

  handleUpload = async (event) => {
    this.setLoading();
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return alert("Please select a file to upload");
    }

    const formData = new FormData();

    const compressedImage = await new Promise((resolve) => {
      new Compressor(selectedFile, {
        quality: 0.1, // Adjust the quality to reduce file size
        success(result) {
          resolve(result);
        },
        error(error) {
          console.error(error.message);
          resolve(selectedFile); // Use the original file if compression fails
        },
      });
    });

    formData.append("image", compressedImage);

    try {
      const response = await fetch(
        constants.url + `prods/${this.state.selected.id}/update-image`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Bearer " + Cookies.get("u-token"),
          },
        }
      );

      if (response.ok) {
        // alert("Image uploaded successfully");
        const responseData = await response.json();
        this.setState({
          selected: responseData.product,
        });
        this.setLoading(false);
        this.loadCategory();
      } else {
        alert("Failed to upload image");
        this.setLoading(false);
      }
    } catch (error) {
      console.error(error);
      alert("Error uploading image");
      this.setLoading(false);
    }
  };

  render() {
    var {
      data,
      selected,
      loading,
      dummy,
      categories,
      nonCategories,
      subCategories,
    } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (selected) {
      return (
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>
                {selected.name} ({selected.id})
              </span>
              <ButtonGroup>
                <Button onClick={() => this.setState({ selected: null })}>
                  Back
                </Button>
                <Button onClick={this.update}>Save</Button>
              </ButtonGroup>
            </Stack>
          }
          shaded
        >
          <img
            src={getProductImage(selected)}
            style={{ width: "auto", height: "100px" }}
          />
          <br />
          <input type="file" name="image" onChange={this.handleUpload} />
          <br />
          <Input
            placeholder="Name"
            value={selected.name}
            onChange={(value) => this.change("name", value)}
          />
          <br />
          <>
            <span>
              <Rate
                value={selected.rating}
                allowHalf={true}
                onChange={(value) => this.change("rating", value)}
                size="lg"
              />
              <span>({selected.rating})</span>
            </span>
          </>
          <br />
          <br />
          <Input
            placeholder="Description"
            as="textarea"
            rows={5}
            value={selected.description}
            onChange={(value) => this.change("description", value)}
          />
          <br />
          <span>Unit price</span>
          <InputNumber
            placeholder="Unit Price"
            value={selected.unit_price}
            onChange={(value) => this.change("unit_price", value)}
          />
          <br />
          <span>OLD Price</span>
          <InputNumber
            placeholder="Old Price"
            value={selected.old_price}
            onChange={(value) => this.change("old_price", value)}
          />
          <br />

          <span>Unit Weight</span>
          <InputNumber
            placeholder="Unit Weight"
            value={selected.unit_weight}
            onChange={(value) => this.change("unit_weight", value)}
          />
          <br />
          <span>Stock</span>
          <InputNumber
            placeholder="Current Stock"
            value={selected.current_stock}
            onChange={(value) => this.change("current_stock", value)}
          />
          <br />

          <span>Minimum Quantity</span>
          <InputNumber
            placeholder="Minimum Quantity"
            value={selected.min_qty}
            onChange={(value) => this.change("min_qty", value)}
          />
          <br />
          <span>Maximum Quantity</span>
          <InputNumber
            placeholder="Maximum Quantity"
            value={selected.max_qty}
            onChange={(value) => this.change("max_qty", value)}
          />
          <br />
          <span>Category</span>
          <InputPicker
            cleanable={false}
            data={categories.map((c) => ({ label: c.name, value: c.id }))}
            placeholder="Category"
            value={selected.category_id}
            onChange={(value) => this.change("category_id", value)}
          />
          <br />
          <span>
            Volume:{" "}
            {new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
            }).format(
              (selected.width || 0) *
                (selected.height || 0) *
                (selected.length || 0)
            )}
            cm³
          </span>
          <InputGroup>
            <InputGroup.Addon>W</InputGroup.Addon>
            <InputNumber
              value={selected.width}
              onChange={(value) => this.change("width", value)}
            />
            <InputGroup.Addon>H</InputGroup.Addon>
            <InputNumber
              value={selected.height}
              onChange={(value) => this.change("height", value)}
            />
            <InputGroup.Addon>L</InputGroup.Addon>
            <InputNumber
              value={selected.length}
              onChange={(value) => this.change("length", value)}
            />
          </InputGroup>
          <br />
          <Stack justifyContent="space-between">
            Status
            <Toggle
              checked={selected.status}
              checkedChildren={<CheckIcon />}
              unCheckedChildren={<CloseIcon />}
              onChange={(value) => this.change("status", value)}
            />
          </Stack>
          <br />
          <Stack justifyContent="space-between">
            Store Item
            <Toggle
              checked={selected.store_item}
              checkedChildren={<CheckIcon />}
              unCheckedChildren={<CloseIcon />}
              onChange={(value) => this.change("store_item", value)}
            />
          </Stack>
          <br />

          <Stack justifyContent="space-between">
            Warranty
            <Toggle
              checked={selected.warranty}
              checkedChildren={<CheckIcon />}
              unCheckedChildren={<CloseIcon />}
              onChange={(value) => this.change("warranty", value)}
            />
          </Stack>
          <br />
          <Stack justifyContent="space-between">
            Free Shipping
            <Toggle
              checked={selected.free_ship}
              checkedChildren={<CheckIcon />}
              unCheckedChildren={<CloseIcon />}
              onChange={(value) => this.change("free_ship", value)}
            />
          </Stack>
          <ProductPrices product={selected} />
        </Panel>
      );
    }
    return (
      <div>
        <h1
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          Products
          <span>
            <ProductCategories
              categories={categories}
              loadCategories={this.loadCategories}
            />
            <Button onClick={this.loadCategories} appearance="primary">
              Refresh
            </Button>
            <Button onClick={this.createModal}>Create</Button>
          </span>
        </h1>
        <PanelGroup accordion defaultActiveKey={0} bordered>
          {categories.map((cat, index) => {
            return (
              <Panel
                header={`#${cat.id}: ${cat.name} (${cat.products.length})`}
                eventKey={index}
                id={`panel${index}`}
              >
                <List hover>
                  {cat.products.map((product) => {
                    return (
                      <List.Item
                        onClick={() => this.setState({ selected: product })}
                      >
                        <Stack>
                          <img
                            src={getProductImage(product)}
                            style={{
                              width: "100px",
                              height: "auto",
                            }}
                          />
                          <strong
                            style={{
                              textAlign: "left",
                              marginLeft: "10px",
                              flex: "1",
                            }}
                          >
                            {product.name} ({product.total_quantity} sold)
                            <br />
                            <Stack justifyContent="space-between">
                              {product.category_id ? (
                                <Tag color="yellow">
                                  {
                                    categories.find(
                                      (category) =>
                                        category.id === product.category_id
                                    )?.name
                                  }
                                </Tag>
                              ) : (
                                <Tag>No Category</Tag>
                              )}

                              <Toggle
                                readonly
                                checked={product.status}
                                checkedChildren="Active"
                                unCheckedChildren="Not Active"
                              />
                            </Stack>
                          </strong>
                        </Stack>
                      </List.Item>
                    );
                  })}
                </List>
              </Panel>
            );
          })}
          <Panel
            header={`No Category Products (${nonCategories.length})`}
            eventKey={888}
            id={`panel888`}
          >
            <List hover>
              {nonCategories.map((product) => {
                return (
                  <List.Item
                    onClick={() => this.setState({ selected: product })}
                  >
                    <Stack>
                      <img
                        src={getProductImage(product)}
                        style={{
                          width: "100px",
                          height: "auto",
                        }}
                      />
                      <strong
                        style={{
                          textAlign: "left",
                          marginLeft: "10px",
                          flex: "1",
                        }}
                      >
                        {product.name} ({product.total_quantity} sold)
                        <br />
                        <Stack justifyContent="space-between">
                          {product.category_id ? (
                            <Tag color="yellow">
                              {
                                categories.find(
                                  (category) =>
                                    category.id === product.category_id
                                )?.name
                              }
                            </Tag>
                          ) : (
                            <Tag>No Category</Tag>
                          )}

                          <Toggle
                            readonly
                            checked={product.status}
                            checkedChildren="Active"
                            unCheckedChildren="Not Active"
                          />
                        </Stack>
                      </strong>
                    </Stack>
                  </List.Item>
                );
              })}
            </List>
          </Panel>
        </PanelGroup>

        <Modal
          open={this.state.createModal}
          onClose={() => this.createModal(false)}
        >
          <Modal.Header>
            <Modal.Title>Create Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Input
              placeholder="Name"
              value={dummy.name}
              onChange={(value) => this.changeDummy("name", value)}
            />
            <br />

            <span>Unit price</span>
            <InputNumber
              placeholder="Unit Price"
              value={dummy.unit_price}
              onChange={(value) => this.changeDummy("unit_price", value)}
            />
            <br />
            <span>Stock</span>
            <InputNumber
              placeholder="Current Stock"
              value={dummy.current_stock}
              onChange={(value) => this.changeDummy("current_stock", value)}
            />
            <br />
            <Stack justifyContent="space-between">
              Status
              <Toggle
                checked={dummy.status}
                checkedChildren={<CheckIcon />}
                unCheckedChildren={<CloseIcon />}
                onChange={(value) => this.changeDummy("status", value)}
              />
            </Stack>
            <br />
            <Stack justifyContent="space-between">
              Store Item
              <Toggle
                checked={dummy.store_item}
                checkedChildren={<CheckIcon />}
                unCheckedChildren={<CloseIcon />}
                onChange={(value) => this.changeDummy("store_item", value)}
              />
            </Stack>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.create} appearance="primary">
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Products;
