import React, { Component } from "react";
import { getProgressColor } from "../Helpers/Utilities";

const container = {
  display: "block",
  width: "calc(100% - 15px)",
  height: "4px",
  background: "#a8a8a889",
  position: "relative",
  overflow: "hidden",
  marginLeft: "15px",
  marginTop: "3px",
};

class CustomProgress extends Component {
  state = {};
  render() {
    var { progress } = this.props;
    return (
      <div style={container}>
        <div
          style={{
            display: "block",
            height: "100%",
            width: parseInt(progress) + "%",
            background: getProgressColor(parseInt(progress)),
          }}
        ></div>
      </div>
    );
  }
}

export default CustomProgress;
