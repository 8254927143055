import moment from "moment";
import React, { Component } from "react";
import { Tag, Timeline } from "rsuite";
import { isToday } from "../../../constants";

class SalesList extends Component {
  state = {};
  render() {
    var { data } = this.props;
    return (
      <div>
        <Timeline align="left">
          {data.map((item) => {
            return (
              <Timeline.Item
                key={item.id}
                onClick={() => {
                  this.props.setSelectedSales(item);
                }}
              >
                <p>
                  Invoice #: {item.id} - {item.items.length} items
                  <Tag
                    color={
                      item.status == "delivery"
                        ? "red"
                        : item.status == "complete"
                        ? "green"
                        : "blue"
                    }
                  >
                    {item.status}
                  </Tag>
                </p>
                <p>Date of sale - {moment(item.date).fromNow()}</p>
                <p>
                  Total QTY:{" "}
                  {item?.items?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue?.quantity),
                    0
                  )}{" "}
                </p>
                <p>
                  Amount:{" "}
                  {item?.items?.reduce(
                    (accumulator, currentValue) =>
                      accumulator +
                      parseFloat(currentValue?.unit_price) *
                        parseFloat(currentValue?.quantity),
                    0
                  )}{" "}
                </p>
              </Timeline.Item>
            );
          })}
        </Timeline>
        <br />
        {data.length == 0 && <span>No sales from this customer</span>}
      </div>
    );
  }
}

export default SalesList;
