import Cookies from "js-cookie";
import constants, { alertError, alertInfo } from "../constants";

export function deleteSalesReturn(id, onComplete = null) {
  var conf = window.confirm("Are you sure to delete this sales return?");
  if (!conf) {
    return;
  }
  fetch(constants.url + `sales-returns/${id}/delete`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (onComplete != null) {
        onComplete();
      }

      alertInfo("Sales return deleted!");
    })
    .catch((error) => {
      alertError("Unable to delete this sales return..");
      console.error(error);
    });
}
