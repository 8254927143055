import React, { Component } from "react";

import {
  Stack,
  Panel,
  ButtonGroup,
  Button,
  Loader,
  DateRangePicker,
  Divider,
  Badge,
  InputNumber,
  InputGroup,
  List,
  Input,
  DatePicker,
  Modal,
  InputPicker,
} from "rsuite";
import ResponsiveNav from "@rsuite/responsive-nav";
import constants from "../../../../constants";
import moment from "moment";
import Cookies from "js-cookie";

const reference_table_list = [{ label: "Vendor", value: "vendors" }];

class FinanceIndex extends Component {
  state = {
    data: null,
    date: [new Date(), new Date()],
    active: "dashboard",
    selected: null,
    hubs: [],
    vendors: [],
    accounts: [],
    loading: true,
    modal: false,
    mode: "create",
  };

  dummy = () => {
    return {
      debit_account_id: null,
      debit_amount: 0,
      credit_account_id: null,
      credit_amount: 0,
      remarks: "",
      reference_table: null,
      reference_id: null,
      reference_user: null,
      hub_id: null,
      date: this.state.date[0],
    };
  };

  setActive = (active) => {
    if (!active) {
      return;
    }
    this.setState({ active });
  };

  componentDidMount = () => {
    this.setState({ selected: this.dummy() });
    this.load(this.state.date);
    this.loadExtras();
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  modal = (modal = true) => {
    this.setState({
      modal,
    });
    if (!modal) {
      this.setState({ selected: this.dummy(), mode: "create" });
    }
  };

  load = (dateRange) => {
    this.setLoading(true);

    this.setState({ data: null, date: dateRange });

    const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
    const endDate = moment(dateRange[1]).format("YYYY-MM-DD");

    const url =
      constants.url +
      `transactions?startDate=${encodeURIComponent(
        startDate
      )}&endDate=${encodeURIComponent(endDate)}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ data: data.transactions });
        this.setLoading(false);
        this.changeValue("date", dateRange[0]);
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  loadExtras = () => {
    //load hubs
    fetch(constants.url + "hubs", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ hubs: data });
      })
      .catch((error) => {
        console.error(error);
      });

    //load accounts
    fetch(constants.url + "accounts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ accounts: data });
      })
      .catch((error) => {
        console.error(error);
      });

    //load vendors
    fetch(constants.url + "vendors", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ vendors: data.vendors });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  changeValue = (key, value) => {
    var selected = this.state.selected;
    selected[key] = value;
    this.setState({ selected });
  };

  handleSaveButton = () => {
    var { selected } = this.state;
    console.log(selected);
    if (this.state.mode == "view") {
      this.setState({ mode: "edit" });
    } else if (this.state.mode == "create") {
      //create new transaction..
      this.setLoading();
      selected.date = new Date(selected.date)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      fetch(constants.url + `transactions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(selected),
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ selected: data.transaction, mode: "view" });
          this.setLoading(false);
          // this.modal(false);
          // window.toast("Transaction created");
          this.load(this.state.date);
        })
        .catch((error) => {
          console.log("transaction create error..");
          console.error(error);
          alert("Please check the entered data!");
          this.setLoading(false);
        });
    } else if (selected.id) {
      //update selected transaction..
      this.setLoading();
      selected.date = new Date(selected.date)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      fetch(constants.url + `transactions/${selected.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(selected),
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ selected: data.transaction, mode: "view" });
          this.setLoading(false);
          // this.modal(false);
          // window.toast("Transaction updated");
          this.load(this.state.date);
        })
        .catch((error) => {
          console.error(error);
          alert("Please check the entered data!");
          this.setLoading(false);
        });
    }
  };

  reverseAmountDirection = (column) => {
    var { selected } = this.state;
    var number = selected[column];
    if (number && number > 0) {
      number = -number;
    } else if (number && number < 0) {
      number = Math.abs(number);
    }
    selected[column] = number;
    this.setState({ selected });
  };

  render() {
    var { active, data, selected, accounts, hubs, vendors, mode } = this.state;
    return (
      <div>
        <Panel
          bordered
          header={
            <Stack justifyContent="space-between">
              <DateRangePicker
                placeholder="Select Dates"
                onChange={this.load}
                placement="auto"
                value={this.state.date}
                size="md"
                showOneCalendar
              />
              <ButtonGroup>
                {/* <Button
              onClick={() => this.setState({ createMode: true })}
              appearance="primary"
            >
              Add
            </Button> */}

                <Button
                  size="sm"
                  onClick={() => {
                    this.load(this.state.date);
                  }}
                >
                  Refresh
                </Button>
                <Button
                  size="sm"
                  appearance="primary"
                  color="green"
                  onClick={this.modal}
                >
                  Add
                </Button>
              </ButtonGroup>
            </Stack>
          }
        >
          <ResponsiveNav
            appearance="subtle"
            activeKey={active}
            onSelect={this.setActive}
            style={{ marginBottom: 5 }}
          >
            <ResponsiveNav.Item eventKey="dashboard">
              Dashboard
            </ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="transactions">
              <Badge content={data?.length ?? 0}>Transactions</Badge>
            </ResponsiveNav.Item>
          </ResponsiveNav>
        </Panel>

        {selected && (
          <Modal open={this.state.modal} onClose={() => this.modal(false)}>
            <Modal.Header>
              <Modal.Title>Transaction {mode}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selected.id && <h4> Transaction id #{selected.id}</h4>}
              <DatePicker
                placeholder="Date of transaction"
                format="yyyy-MM-dd"
                value={
                  typeof selected?.date === "string" &&
                  !isNaN(Date.parse(selected?.date))
                    ? new Date(selected?.date)
                    : selected?.date
                }
                onChange={(value) => this.changeValue("date", value)}
                block
                plaintext={mode == "view"}
              />
              <Stack justifyContent="space-between">
                <InputPicker
                  data={accounts?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  placeholder="Debit Account"
                  value={parseInt(selected?.debit_account_id)}
                  onChange={(value) =>
                    this.changeValue("debit_account_id", value)
                  }
                  plaintext={mode == "view"}
                />

                {selected?.debit_account_id && (
                  <InputGroup>
                    {mode != "view" && (
                      <Button
                        onClick={() =>
                          this.reverseAmountDirection("debit_amount")
                        }
                        appearance="primary"
                        color={selected?.debit_amount > 0 ? "green" : "red"}
                      >
                        {selected?.debit_amount > 0 ? "+" : "-"}
                      </Button>
                    )}
                    <InputNumber
                      placeholder="Debit Amount"
                      value={selected?.debit_amount}
                      onChange={(value) => {
                        this.changeValue("debit_amount", value);
                        this.changeValue("credit_amount", value);
                      }}
                      plaintext={mode == "view"}
                    />
                  </InputGroup>
                )}
              </Stack>
              <Stack justifyContent="space-between">
                <InputPicker
                  data={accounts?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  placeholder="Credit Account"
                  value={parseInt(selected?.credit_account_id)}
                  onChange={(value) =>
                    this.changeValue("credit_account_id", value)
                  }
                  plaintext={mode == "view"}
                />

                {selected?.credit_account_id && (
                  <InputGroup>
                    {mode != "view" && (
                      <Button
                        onClick={() =>
                          this.reverseAmountDirection("credit_amount")
                        }
                        appearance="primary"
                        color={selected?.credit_amount > 0 ? "green" : "red"}
                      >
                        {selected?.credit_amount > 0 ? "+" : "-"}
                      </Button>
                    )}
                    <InputNumber
                      placeholder="Credit Amount"
                      value={selected?.credit_amount}
                      onChange={(value) => {
                        this.changeValue("debit_amount", value);
                        this.changeValue("credit_amount", value);
                      }}
                      plaintext={mode == "view"}
                    />
                  </InputGroup>
                )}
              </Stack>
              <Input
                block
                placeholder="Remarks"
                value={selected?.remarks}
                onChange={(value) => this.changeValue("remarks", value)}
                plaintext={mode == "view"}
              />

              <InputPicker
                plaintext={mode == "view"}
                data={hubs[0]?.users?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                placeholder="Reference User"
                value={
                  mode === "create"
                    ? selected?.reference_user
                    : selected?.reference_user?.id
                }
                onChange={(value) => this.changeValue("reference_user", value)}
                block
              />

              <InputPicker
                block
                plaintext={mode == "view"}
                data={reference_table_list}
                placeholder="Reference Table"
                value={selected?.reference_table}
                onChange={(value) => this.changeValue("reference_table", value)}
              />

              {selected?.reference_table != null && (
                <InputPicker
                  block
                  plaintext={mode == "view"}
                  data={
                    selected?.reference_table == "vendors"
                      ? vendors?.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))
                      : null
                  }
                  placeholder="Reference ID"
                  value={parseInt(selected?.reference_id)}
                  onChange={(value) => this.changeValue("reference_id", value)}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={this.handleSaveButton}
                appearance="primary"
                disabled={this.state.loading}
              >
                {this.state.mode == "view" ? "Edit" : "Save"}
              </Button>
              <Button onClick={() => this.modal(false)} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {data && this.state.active == "transactions" && (
          <List hover>
            {data.map((item, index) => {
              return (
                <List.Item
                  onClick={() => {
                    this.setState({ mode: "view", selected: item });
                    console.log("transaction", item);
                    this.modal(true);
                  }}
                  style={{ textAlign: "left" }}
                >
                  <Stack justifyContent="space-between">
                    <div>
                      <strong>Debit:</strong>
                      <br />
                      <i>{item.debit_account["name"]}</i>
                      <br />
                      <i>{item.debit_amount}</i>
                    </div>
                    <div>
                      <strong>Credit:</strong>
                      <br /> <i>{item.credit_account["name"]}</i>
                      <br />
                      <i>{item.credit_amount}</i>
                    </div>
                    <div>
                      <strong>{item.reference_user?.name}</strong>
                      <br />
                      <i>{item.remarks}</i>
                    </div>
                  </Stack>
                </List.Item>
              );
            })}
          </List>
        )}
      </div>
    );
  }
}

export default FinanceIndex;
