import React, { Component } from "react";

import {
  Stack,
  Panel,
  ButtonGroup,
  Button,
  Loader,
  DateRangePicker,
  Divider,
  Badge,
  InputNumber,
  InputGroup,
  List,
  Checkbox,
  Input,
  DatePicker,
  Modal,
  InputPicker,
} from "rsuite";
import ResponsiveNav from "@rsuite/responsive-nav";
import constants from "../../../../constants";
import moment from "moment";
import Cookies from "js-cookie";

class Materials extends Component {
  state = {
    data: null,
    selected: null,
    loading: true,
    modal: false,
    mode: "create",
  };

  dummy = () => {
    return {
      name: "",
      description: "",
    };
  };

  componentDidMount = () => {
    this.setState({ selected: this.dummy() });
    this.load();
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  modal = (modal = true) => {
    this.setState({
      modal,
    });
    if (!modal) {
      this.setState({ selected: this.dummy(), mode: "create" });
    }
  };

  load = () => {
    this.setLoading(true);

    this.setState({ data: null });

    const url = constants.url + `materials`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ data: data.materials });
        this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  changeValue = (key, value) => {
    var selected = this.state.selected;
    selected[key] = value;
    this.setState({ selected });
  };

  handleSaveButton = () => {
    var { selected } = this.state;

    if (this.state.mode == "view") {
      this.setState({ mode: "edit" });
    } else if (this.state.mode == "create") {
      //create new transaction..
      this.setLoading();

      fetch(constants.url + `materials`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(selected),
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ selected: data.material, mode: "view" });
          this.setLoading(false);
          this.load();
        })
        .catch((error) => {
          alert("Please check the entered data!");
          this.setLoading(false);
        });
    } else if (selected.id) {
      //update selected transaction..
      this.setLoading();

      fetch(constants.url + `materials/${selected.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(selected),
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ selected: data.material, mode: "view" });
          this.setLoading(false);
          this.load();
        })
        .catch((error) => {
          console.error(error);
          alert("Please check the entered data!");
          this.setLoading(false);
        });
    }
  };

  render() {
    var { data, selected, mode } = this.state;
    return (
      <div>
        <Stack justifyContent="space-between">
          {/* <Button
              onClick={() => this.setState({ createMode: true })}
              appearance="primary"
            >
              Add
            </Button> */}

          <Button
            size="sm"
            onClick={() => {
              this.load();
            }}
          >
            Refresh
          </Button>
          <Button
            size="sm"
            appearance="primary"
            color="green"
            onClick={this.modal}
          >
            Add
          </Button>
        </Stack>

        {selected && (
          <Modal open={this.state.modal} onClose={() => this.modal(false)}>
            <Modal.Header>
              <Modal.Title>Material {mode}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selected.id && <h4> Material id #{selected.id}</h4>}

              <Input
                block
                value={selected?.name}
                onChange={(value) => {
                  this.changeValue("name", value);
                }}
                plaintext={mode == "view"}
                placeholder="Material name"
              />

              <Input
                as="textarea"
                rows={3}
                block
                value={selected?.description}
                onChange={(value) => {
                  this.changeValue("description", value);
                }}
                plaintext={mode == "view"}
                placeholder="Description"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={this.handleSaveButton}
                appearance="primary"
                disabled={this.state.loading}
              >
                {this.state.mode == "view" ? "Edit" : "Save"}
              </Button>
              <Button onClick={() => this.modal(false)} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {data && (
          <List hover>
            {data.map((item, index) => {
              return (
                <List.Item
                  onClick={() => {
                    this.setState({ mode: "view", selected: item });
                    this.modal(true);
                  }}
                  style={{ textAlign: "left" }}
                >
                  <strong>{item.name}</strong>
                  <br />
                  <i>{item.description}</i>
                </List.Item>
              );
            })}
          </List>
        )}
      </div>
    );
  }
}

export default Materials;
