import Cookies from "js-cookie";
import React, { Component } from "react";
import { Button, Loader, Modal } from "rsuite";
import constants, { alertError, alertInfo } from "../constants";

class CalibrateLeadSpot extends Component {
  state = {
    modal: false,
    data: false,
    loading: false,
  };

  setLoading = (loading = true) => this.setState({ loading });
  modal = (modal = true) => this.setState({ modal });

  calibrate = () => {
    this.setLoading();
    fetch(constants.url + "calibrate-leadSpots", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setLoading(false);
        this.setState({ data: data.statistics });
        alertInfo("Calibration Complete");
      })
      .catch((error) => {
        alertError("Calibration Failed!");
        this.setLoading(false);
        console.error(error);
      });
  };
  render() {
    return (
      <div>
        <Button onClick={this.modal}>Calibrate</Button>
        <Modal
          open={this.state.modal}
          onClose={() => this.modal(false)}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>Modal Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading ? (
              <Loader content="Calibrating.." />
            ) : this.state.data ? (
              <div>
                <p>
                  <strong>Total LeadSpots: </strong>
                  {this.state.data.total_lead_spots}
                </p>
                <p>
                  <strong>Updated: </strong>
                  {this.state.data.total_lead_spots_updated}
                </p>
                <p>
                  <strong>Skipped: </strong>
                  {this.state.data.total_lead_spots_skipped}
                </p>
              </div>
            ) : (
              "Data required.."
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.calibrate}
              appearance="primary"
              disabled={this.state.loading}
            >
              Start
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CalibrateLeadSpot;
