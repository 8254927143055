import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  DateRangePicker,
  Divider,
  Input,
  InputNumber,
  InputPicker,
  List,
  Modal,
  Nav,
} from "rsuite";
import constants, { alertError, alertInfo, getUserImage } from "../constants";
import {
  loadInventoryPlaces,
  loadInventoryUsers,
  loadProducts,
  loadUsers,
} from "../Loaders/GeneralLoder";
import CustomLoader from "../Widgets/CustomLoader";
import PageEndIcon from "@rsuite/icons/PageEnd";
import { FaLocationDot } from "react-icons/fa6";

class InventoryControl extends Component {
  state = {
    places: [],
    users: [],
    products: [],
    moves: [],
    selectedMovement: {},
    date: [new Date(), new Date()],
    loading: false,
    loadingText: null,
    modal: false,
    activeNav: "moves",
    productDisplayTitle: null,
    productDisplayList: null,
  };

  setLoading = (loading = true, loadingText = null) =>
    this.setState({ loading, loadingText });
  modal = (modal = true) => this.setState({ modal });

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    this.setLoading(true, "Loading Places");
    loadInventoryPlaces((places) =>
      this.setState({ places, loadingText: "Loading Users" }, () =>
        loadInventoryUsers((users) =>
          this.setState({ users, loadingText: "Loading Products" }, () =>
            loadProducts((products) =>
              this.setState({ products }, this.loadMoves)
            )
          )
        )
      )
    );
  };

  loadMoves = () => {
    this.setLoading(true, "Loading Moves");
    var { date } = this.state;
    const startDate = moment(date[0]).format("YYYY-MM-DD");
    const endDate = moment(date[1]).format("YYYY-MM-DD");

    // Construct the request body with the parameters
    const requestBody = {
      startDate: startDate,
      endDate: endDate,
    };

    fetch(constants.url + "inventory-moves-load", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(requestBody), // Send the parameters in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ moves: data.moves });
        this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  change = (where, what) => {
    var { selectedMovement } = this.state;
    selectedMovement[where] = what;
    this.setState({ selectedMovement });
  };

  saveMove = () => {
    var { selectedMovement } = this.state;
    if (!selectedMovement.product_id) {
      alertError("Product not specified..");
      return;
    }
    if (!selectedMovement.qty) {
      alertError("Quantity not specified..");
      return;
    }
    if (!selectedMovement.from_place) {
      alertError("From place not specified..");
      return;
    }
    if (!selectedMovement.to_place) {
      alertError("To place not specified..");
      return;
    }
    this.setLoading(true, "Saving movement..");
    fetch(constants.url + "inventory-moves", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(selectedMovement),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alertInfo("Created Movement");
        this.setLoading(false);
        this.modal(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <div>
        <CustomLoader
          full
          text={this.state.loadingText}
          loading={this.state.loading}
        />
        <Divider>
          Inventory Control{" | "}
          <Button appearance="link" onClick={this.modal}>
            Create Move
          </Button>
          {" | "}
          <Button appearance="link" onClick={this.fetchData}>
            Refresh
          </Button>
        </Divider>

        <Nav
          appearance="tabs"
          activeKey={this.state.activeNav}
          onSelect={(activeNav) => this.setState({ activeNav })}
        >
          <Nav.Item eventKey="moves">Moves</Nav.Item>
          <Nav.Item eventKey="places">Places</Nav.Item>
          <Nav.Item eventKey="users">Users</Nav.Item>
        </Nav>

        {/* Moves */}

        {this.state.activeNav == "moves" && (
          <DateRangePicker
            placeholder="Select Dates"
            onChange={(date) => this.setState({ date }, this.loadMoves)}
            placement="auto"
            value={this.state.date}
            size="sm"
            showOneCalendar
            block
          />
        )}
        {this.state.activeNav == "moves" &&
          this.state.moves.map((move, index) => (
            <div
              key={index}
              style={{
                width: "90vw",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px",
                marginBottom: "16px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
              }}
            >
              <strong style={{ fontSize: "18px", color: "#333" }}>
                {move.product?.name} (ID: {move?.product_id})
              </strong>
              <br />
              <div
                style={{ fontSize: "14px", color: "#777", marginTop: "4px" }}
              >
                <strong>Quantity:</strong> {move.qty}
              </div>
              <div
                style={{
                  width: "100%",
                  height: "50px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "8px",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <strong style={{ fontSize: "16px", color: "#555" }}>
                    {move.from_place?.name}
                  </strong>
                  <br />
                  <small style={{ fontSize: "14px", color: "#777" }}>
                    <img
                      src={getUserImage(move.from_user)}
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "20px",
                        margin: "8px",
                      }}
                    />
                    {move.from_user?.name ?? "No USER"}
                  </small>
                </div>
                <PageEndIcon
                  style={{ fontSize: "24px", color: "#888", margin: "0 12px" }}
                />
                <div style={{ textAlign: "right" }}>
                  <strong style={{ fontSize: "16px", color: "#555" }}>
                    {move.to_place?.name}
                  </strong>
                  <br />
                  <small style={{ fontSize: "14px", color: "#777" }}>
                    <img
                      src={getUserImage(move.to_user)}
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "20px",
                        margin: "8px",
                      }}
                    />
                    {move.to_user?.name ?? "No USER"}
                  </small>
                </div>
              </div>
              <div
                style={{
                  textAlign: "right",
                  marginTop: "12px",
                  fontSize: "14px",
                  color: "#777",
                }}
              >
                <strong>{move.created_by.name}</strong> created{" "}
                {moment(move.created_at).fromNow()} (MOV#{move.id})
              </div>
            </div>
          ))}

        {/* Places */}
        {this.state.activeNav === "places" &&
          this.state.places.map((place) => (
            <div
              key={place.id}
              style={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px",
                marginBottom: "16px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                maxWidth: "90vw",
              }}
              onClick={() =>
                this.setState({
                  productDisplayList: place.products,
                  productDisplayTitle: `Place: ${place.name}`,
                })
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <FaLocationDot
                  style={{
                    fontSize: "20px",
                    color: "#333",
                    marginRight: "8px",
                  }}
                />
                <strong style={{ fontSize: "18px", color: "#333" }}>
                  {place.name}
                </strong>
              </div>

              <div
                style={{ fontSize: "16px", color: "#555", marginBottom: "4px" }}
              >
                <strong>Total IN:</strong> {place.to_quantity}
              </div>
              <div
                style={{ fontSize: "16px", color: "#555", marginBottom: "4px" }}
              >
                <strong>Total OUT:</strong> {place.from_quantity}
              </div>
              <div style={{ fontSize: "16px", color: "#555" }}>
                <strong>Remaining:</strong>{" "}
                {parseInt(place.to_quantity) - parseInt(place.from_quantity)}
              </div>
            </div>
          ))}

        {/* Users */}
        {this.state.activeNav === "users" &&
          this.state.users.map((user) => (
            <div
              key={user.id}
              style={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px",
                marginBottom: "16px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                maxWidth: "90vw",
              }}
              onClick={() =>
                this.setState({
                  productDisplayList: user.products,
                  productDisplayTitle: `User: ${user.name}`,
                })
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <img
                  src={getUserImage(user)}
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "20px",
                    margin: "8px",
                  }}
                  alt="from user"
                />
                <strong style={{ fontSize: "18px", color: "#333" }}>
                  #{user.id} - {user.name}
                </strong>
              </div>

              <div
                style={{ fontSize: "16px", color: "#555", marginBottom: "4px" }}
              >
                <strong>Total IN:</strong> {user.to_quantity}
              </div>
              <div
                style={{ fontSize: "16px", color: "#555", marginBottom: "4px" }}
              >
                <strong>Total OUT:</strong> {user.from_quantity}
              </div>
              <div style={{ fontSize: "16px", color: "#555" }}>
                <strong>Remaining:</strong>{" "}
                {parseInt(user.to_quantity) - parseInt(user.from_quantity)}
              </div>
            </div>
          ))}

        <Modal
          open={this.state.modal}
          onClose={() => this.modal(false)}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>Inventory Move</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Divider>From Details</Divider>
            <i>From Place</i>
            <InputPicker
              block
              value={this.state.selectedMovement.from_place}
              onChange={(value) => this.change("from_place", value)}
              data={this.state.places.map((place) => ({
                label: place.name,
                value: place.id,
              }))}
            />

            <i>From User</i>
            <InputPicker
              block
              value={this.state.selectedMovement.from_user}
              onChange={(value) => this.change("from_user", value)}
              data={this.state.users.map((user) => ({
                label: user.name,
                value: user.id,
              }))}
            />
            <Divider>To Details</Divider>
            <i>To Place</i>
            <InputPicker
              block
              value={this.state.selectedMovement.to_place}
              onChange={(value) => this.change("to_place", value)}
              data={this.state.places.map((place) => ({
                label: place.name,
                value: place.id,
              }))}
            />

            <i>To User</i>
            <InputPicker
              block
              value={this.state.selectedMovement.to_user}
              onChange={(value) => this.change("to_user", value)}
              data={this.state.users.map((user) => ({
                label: user.name,
                value: user.id,
              }))}
            />
            <Divider>Product Details</Divider>
            <i>Product</i>
            <InputPicker
              block
              value={this.state.selectedMovement.product_id}
              onChange={(value) => this.change("product_id", value)}
              data={this.state.products.map((product) => ({
                label: product.name,
                value: product.id,
              }))}
            />
            <i>Quantity</i>
            <InputNumber
              block
              value={this.state.selectedMovement.qty}
              onChange={(value) => this.change("qty", value)}
            />
            <i>Remarks</i>
            <Input
              as="textarea"
              block
              value={this.state.selectedMovement.remarks}
              onChange={(value) => this.change("remarks", value)}
            />
            <br />
            <br />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.saveMove}
              appearance="primary"
              disabled={this.state.loading}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* productDisplayList */}

        <Modal
          open={this.state.productDisplayList != null}
          onClose={() => this.setState({ productDisplayList: null })}
        >
          <Modal.Header>
            <Modal.Title>{this.state.productDisplayTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <List>
              {this.state.productDisplayList?.map((product, index) => (
                <List.Item>
                  <strong>{product.name}</strong>
                  <br />
                  <small>Total In</small>: {product.total_in}
                  <br />
                  <small>Total Out</small>: {product.total_out}
                  <br />
                  <small>Remaining</small>:{" "}
                  {parseFloat(product.total_in) - parseFloat(product.total_out)}
                  <br />
                </List.Item>
              ))}
            </List>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default InventoryControl;
