import { stringSimilarity } from "string-similarity-js";
import { haversineDistance } from "../constants";

export function findNearestItem(targetCoordinate, list, element) {
  if (targetCoordinate == null || list == null) {
    return null;
  }
  const R = 6371e3; // Radius of the Earth in meters
  let nearestItem = null;
  let shortestDistance = Infinity;

  // Convert target coordinate to radians
  const lat1 = (targetCoordinate.lat * Math.PI) / 180;
  const lng1 = (targetCoordinate.lng * Math.PI) / 180;

  for (const item of list) {
    // Convert item coordinate to radians
    const lat2 = (JSON.parse(item[element])?.lat * Math.PI) / 180;
    const lng2 = (JSON.parse(item[element])?.lng * Math.PI) / 180;

    // Calculate differences between coordinates
    const dLat = lat2 - lat1;
    const dLng = lng2 - lng1;

    // Calculate distance using Haversine formula
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    // Check if the distance is shorter than the current shortest distance
    if (distance < shortestDistance) {
      shortestDistance = distance;
      nearestItem = item;
    }
  }

  // Return nearest item and its distance
  return { nearestItem, shortestDistance: Math.floor(shortestDistance) };
}

export function getNearestItems(targetCoordinate, list, element, count = 1) {
  if (!targetCoordinate || !list) {
    return null;
  }

  const R = 6371e3; // Radius of the Earth in meters

  const itemsWithDistances = list.map((item) => {
    const itemCoordinate = JSON.parse(item[element]);
    if (!itemCoordinate) return item;

    const lat1 = (targetCoordinate.lat * Math.PI) / 180;
    const lat2 = (itemCoordinate.lat * Math.PI) / 180;
    const dLat = lat2 - lat1;

    const lng1 = (targetCoordinate.lng * Math.PI) / 180;
    const lng2 = (itemCoordinate.lng * Math.PI) / 180;
    const dLng = lng2 - lng1;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return {
      ...item,
      distance: distance,
    };
  });

  const sortedItems = itemsWithDistances.sort(
    (a, b) => a.distance - b.distance
  );
  return sortedItems.slice(0, count);
}

export function getSimilarNearestItems(
  targetCoordinate,
  list,
  element,
  count = 1,
  searchWord,
  searchField
) {
  var nearest = getNearestItems(targetCoordinate, list, element, count);
  const itemsWithSimilarity = nearest.map((item) => {
    const similarity = stringSimilarity(searchWord, item[searchField] ?? "");
    return {
      ...item,
      similarity: similarity,
    };
  });

  const sortedItems = itemsWithSimilarity.sort(
    (a, b) => b.similarity - a.similarity
  );

  return sortedItems;
}

export function getRouteZones(route) {
  // Step 1: Parse the coordinates
  const coordinates = JSON.parse(route.cord1);

  // Step 2: Calculate the bounding box of the polygon
  let minLat = Infinity,
    maxLat = -Infinity,
    minLng = Infinity,
    maxLng = -Infinity;
  coordinates.forEach((coord) => {
    if (coord.lat < minLat) minLat = coord.lat;
    if (coord.lat > maxLat) maxLat = coord.lat;
    if (coord.lng < minLng) minLng = coord.lng;
    if (coord.lng > maxLng) maxLng = coord.lng;
  });

  // Step 3: Find the midpoint (halfway point) for both latitude and longitude
  const midLat = (minLat + maxLat) / 2;
  const midLng = (minLng + maxLng) / 2;

  // Step 4: Helper function to find intersection points with polygon edges
  function findIntersectionPoints(lineCoord, isVertical) {
    let intersections = [];
    for (let i = 0; i < coordinates.length; i++) {
      let start = coordinates[i];
      let end = coordinates[(i + 1) % coordinates.length];

      // Check for vertical intersection
      if (isVertical) {
        if (
          (start.lng <= lineCoord.lng && end.lng >= lineCoord.lng) ||
          (start.lng >= lineCoord.lng && end.lng <= lineCoord.lng)
        ) {
          let intersectLat =
            start.lat +
            ((end.lat - start.lat) * (lineCoord.lng - start.lng)) /
              (end.lng - start.lng);
          intersections.push({ lat: intersectLat, lng: lineCoord.lng });
        }
      }
      // Check for horizontal intersection
      else {
        if (
          (start.lat <= lineCoord.lat && end.lat >= lineCoord.lat) ||
          (start.lat >= lineCoord.lat && end.lat <= lineCoord.lat)
        ) {
          let intersectLng =
            start.lng +
            ((end.lng - start.lng) * (lineCoord.lat - start.lat)) /
              (end.lat - start.lat);
          intersections.push({ lat: lineCoord.lat, lng: intersectLng });
        }
      }
    }

    // Sort and select the closest intersection points
    if (isVertical) {
      intersections.sort((a, b) => a.lat - b.lat);
    } else {
      intersections.sort((a, b) => a.lng - b.lng);
    }

    return [intersections[0], intersections[intersections.length - 1]];
  }

  // Step 5: Get the vertical and horizontal line intersections using the midpoints
  const verticalLine = findIntersectionPoints(
    { lat: midLat, lng: midLng },
    true
  );
  const horizontalLine = findIntersectionPoints(
    { lat: midLat, lng: midLng },
    false
  );

  // Step 6: Return the results
  return {
    verticalLine: verticalLine,
    horizontalLine: horizontalLine,
  };
}

export async function getDuplicateLeads(leads) {
  const duplicates = [];
  const invalidLocations = [];
  const nullShopNames = [];
  const delayMs = 10; // Delay in milliseconds

  function nameSimilarity(name1, name2) {
    if (!name1 || !name2) return 0;
    const getBigrams = (str) => {
      const s = str.toLowerCase().replace(/\s+/g, "");
      const bigrams = new Set();
      for (let i = 0; i < s.length - 1; i++) {
        bigrams.add(s.slice(i, i + 2));
      }
      return bigrams;
    };

    const bigrams1 = getBigrams(name1);
    const bigrams2 = getBigrams(name2);
    const intersection = new Set(
      [...bigrams1].filter((bigram) => bigrams2.has(bigram))
    );
    return (2 * intersection.size) / (bigrams1.size + bigrams2.size);
  }

  // Main async processing loop
  for (let i = 0; i < leads.length; i++) {
    const leadA = leads[i];
    if (!leadA.geolocation) {
      invalidLocations.push({ lead: leadA, error: "Invalid JSON location" });
      continue;
    }
    if (!leadA.shop_name) {
      nullShopNames.push(leadA);
      continue;
    }

    const locA = JSON.parse(leadA.geolocation);

    for (let j = i + 1; j < leads.length; j++) {
      const leadB = leads[j];
      if (!leadB.geolocation) {
        invalidLocations.push({ lead: leadB, error: "Invalid JSON location" });
        continue;
      }
      if (!leadB.shop_name) {
        nullShopNames.push(leadB);
        continue;
      }

      const locB = JSON.parse(leadB.geolocation);
      const distance = haversineDistance(locA, locB);
      const nameMatch = nameSimilarity(leadA.shop_name, leadB.shop_name);
      const phoneMatch = leadA.phone === leadB.phone;

      if ((distance < 80 && nameMatch >= 0.6) || phoneMatch) {
        duplicates.push({
          lead: leadA,
          duplicateOf: leadB,
          probability: Math.round(nameMatch * 100) + "%",
          reason: `Distance: ${distance.toFixed(1)}m, Name Match: ${Math.round(
            nameMatch * 100
          )}%, Phone Match: ${phoneMatch}`,
        });
      }
    }

    // Add a small delay every 100 iterations to allow UI updates
    if (i % 100 === 0) {
      await new Promise((resolve) => setTimeout(resolve, delayMs));
    }
  }

  return { duplicates, invalidLocations, nullShopNames };
}
