import Compressor from "compressorjs";
import Cookies from "js-cookie";
import React, { Component } from "react";
import { Button, Divider } from "rsuite";
import constants, { getUserImage } from "../../../../constants";
import { changePassword } from "../../../../Helpers/Utilities";

class UserInfo extends Component {
  state = {
    loading: false,
  };

  setLoading = (loading = true) => this.setState({ loading });

  handleUpload = async (event) => {
    this.setLoading();
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return alert("Please select a file to upload");
    }

    const formData = new FormData();

    const compressedImage = await new Promise((resolve) => {
      new Compressor(selectedFile, {
        quality: 0.1, // Adjust the quality to reduce file size
        success(result) {
          resolve(result);
        },
        error(error) {
          console.error(error.message);
          resolve(selectedFile); // Use the original file if compression fails
        },
      });
    });

    formData.append("image", compressedImage);

    try {
      const response = await fetch(
        constants.url + `users/${this.props.data.id}/update-image`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Bearer " + Cookies.get("u-token"),
          },
        }
      );

      if (response.ok) {
        // alert("Image uploaded successfully");
        const responseData = await response.json();
        this.props.data.image = responseData?.user?.image;
        this.setLoading(false);
      } else {
        alert("Failed to upload image");
        this.setLoading(false);
      }
    } catch (error) {
      console.error(error);
      alert("Error uploading image");
      this.setLoading(false);
    }
  };

  render() {
    var { data } = this.props;
    var employee = data.active_employment?.employee ?? null;
    // var employment = data.active_employment.employment;
    var employment = data.active_employment?.employment_type ?? null;
    return (
      <div style={{ textAlign: "left" }}>
        <Divider>Employee Details</Divider>
        {employee && (
          <div>
            <img
              src={getUserImage(data)}
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "60px",
                objectFit: "cover",
              }}
            />
            <br />
            <input type="file" name="image" onChange={this.handleUpload} />
            <hr />
            <strong>Name</strong>:&nbsp;<i>{employee.name}</i>
            <br />
            <strong>Education</strong>:&nbsp;<i>{employee.education}</i>
            <br />
            <strong>Contact</strong>:&nbsp;
            <i>
              <a href={`tel:${employee.contact}`}>{employee.contact}</a>
            </i>
            <br />
            <strong>Emergency contact</strong>:&nbsp;
            <i>
              <a href={`tel:${employee.emergency_contact}`}>
                {employee.emergency_contact}
              </a>
            </i>
            <br />
            <strong>Email</strong>:&nbsp;<i>{employee.email}</i>
            <br />
            <strong>Address</strong>:&nbsp;<i>{employee.address}</i>
            <br />
            <strong>Date of birth</strong>:&nbsp;<i>{employee.dob}</i>
            <br />
            <strong>Previous company</strong>:&nbsp;
            <i>{employee.previous_company}</i>
            <br />
            <strong>Joining date</strong>:&nbsp;<i>{employee.joining_date}</i>
            <br />
            <strong>Status</strong>:&nbsp;<i>{employee.status}</i>
            <br />
            <strong>Id</strong>:&nbsp;<i>{data.id}</i>
            <br />
          </div>
        )}
        <Divider>Employment</Divider>
        {employment && (
          <div>
            <strong>Type</strong>:&nbsp;<i>{employment.name}</i>
            <br />
            <strong>Period</strong>:&nbsp;<i>{employment.period} days</i>
            <br />
            <strong>Min sales value</strong>:&nbsp;
            <i>{employment.min_sales_value}</i>
            <br />
            <strong>Min sales volume</strong>:&nbsp;
            <i>{employment.min_sales_volume}</i>
            <br />
            <strong>Min customers</strong>:&nbsp;
            <i>{employment.min_customers} a day</i>
            <br />
            <strong>Min Leads</strong>:&nbsp;<i>{employment.min_leads} a day</i>
            <br />
            <strong>Fuel</strong>:&nbsp;
            <i>{employment.fuel_allowance} per KM</i>
            <br />
            <strong>Basic salary</strong>:&nbsp;
            <i>{employment.basic_salary} per month</i>
            <br />
            <strong>Actual salary</strong>:&nbsp;
            <i>{employment.actual_salary} per month</i>
            <br />
            <strong>Regular Commission</strong>:&nbsp;
            <i>{employment.regular_commission}</i>
            <br />
            <strong>After Target Commission</strong>:&nbsp;
            <i>{employment.after_target_commission}</i>
            <br />
            <strong>Transport allowance</strong>:&nbsp;
            <i>{employment.transport_allowance}</i>
            <br />
            <strong>Mobile recharge</strong>:&nbsp;
            <i>{employment.mobile_recharge}</i>
            <br />
            <strong>Accommodation allowance</strong>:&nbsp;
            <i>{employment.accommodation_allowance}</i>
            <br />
            <strong>Weekly off</strong>:&nbsp;
            <i>{employment.weekly_off} days</i>
            <br />
          </div>
        )}

        <Divider>Actions</Divider>
        {this.props.sessionCheck.roles.includes("PASSWORD_CHANGE") && (
          <Button onClick={() => changePassword(data.id)}>
            Change Password
          </Button>
        )}
      </div>
    );
  }
}

export default UserInfo;
