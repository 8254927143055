import React, { Component } from "react";
import { Button, Drawer, List, Tag } from "rsuite";
import { MdOutlineChangeCircle } from "react-icons/md";
import constants, { alertError } from "../constants";
import Cookies from "js-cookie";
import moment from "moment";

export function whatChanged(oldObject, newObject) {
  // Initialize an array to store the changes
  let changes = [];

  // Iterate through each key in the new object
  for (let key in newObject) {
    // Check if the key is 'updated_at' and skip it
    if (key === "updated_at") {
      continue;
    }

    // Check if the key exists in the old object
    if (oldObject.hasOwnProperty(key)) {
      // Compare the values of the corresponding keys in both objects
      if (oldObject[key] !== newObject[key]) {
        // If the values are different, push the change to the changes array
        changes.push(
          `Changed ${key} from ${oldObject[key]} to ${newObject[key]}`
        );
      }
    } else {
      // If the key doesn't exist in the old object, it's a new addition
      changes.push(`Added ${key}: ${newObject[key]}`);
    }
  }

  // Check for any keys in the old object that are not in the new object (deleted keys)
  for (let key in oldObject) {
    if (key === "updated_at") {
      continue;
    }

    if (!newObject.hasOwnProperty(key)) {
      // Push the deletion to the changes array
      changes.push(`Deleted ${key}: ${oldObject[key]}`);
    }
  }

  // Return the changes array
  return changes;
}

class Revisions extends Component {
  state = {
    loading: false,
    drawer: false,
    data: [],
  };

  loading = (loading = true) => this.setState({ loading });
  drawer = (drawer = true) => this.setState({ drawer });

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate(prevProps) {
    const {
      customerId: prevCustomerId,
      leadId: prevLeadId,
      leadFollowupId: prevLeadFollowupId,
    } = prevProps;
    const { customerId, leadId, leadFollowupId } = this.props;

    if (
      customerId !== prevCustomerId ||
      leadId !== prevLeadId ||
      leadFollowupId !== prevLeadFollowupId
    ) {
      // Call loadData function here since customerId, leadId, or leadFollowupId has changed
      this.loadData();
    }
  }

  loadData = () => {
    this.loading();

    var { customerId, leadId, leadFollowupId } = this.props;

    var url = constants.url;
    if (customerId) {
      url += `revisions/customer/${customerId}`;
    } else if (leadId) {
      url += `revisions/lead/${leadId}`;
    } else if (leadFollowupId) {
      url += `revisions/lead_followup/${leadFollowupId}`;
    } else {
      return;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data: data.revisions });
        this.loading(false);
      })
      .catch((error) => {
        alertError("Unable to load revisions.");
        console.error(error);
      });
  };

  render() {
    var { data, loading, drawer } = this.state;
    return (
      <>
        <Button
          disabled={loading}
          size="sm"
          onClick={this.drawer}
          style={{ margin: "6px" }}
        >
          <MdOutlineChangeCircle /> {data.length > 0 ? data.length : "No"}{" "}
          Revisions
        </Button>

        <Drawer
          open={drawer}
          onClose={() => this.drawer(false)}
          placement="bottom"
          size="full"
        >
          <Drawer.Header>
            <Drawer.Title>Revisions {data.length}</Drawer.Title>
            <Drawer.Actions>
              <Button onClick={this.loadData} disabled={loading}>
                Refresh
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <List hover>
              {data.map((item, index) => {
                let comparisonObject;
                if (index === data.length - 1) {
                  comparisonObject = this.props.original;
                } else {
                  comparisonObject = JSON.parse(data[index + 1].previous);
                }
                return (
                  <List.Item key={index}>
                    <ul>
                      {whatChanged(
                        JSON.parse(item.previous),
                        comparisonObject
                      ).map((c, i) => (
                        <li key={i}>{c}</li>
                      ))}
                    </ul>
                    <Tag>
                      {item.created_by.name} changed it{" "}
                      {moment(item.created_at).fromNow()}
                    </Tag>
                  </List.Item>
                );
              })}
            </List>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default Revisions;
