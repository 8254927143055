import React, { Component } from "react";
import { Button, InputNumber, Modal } from "rsuite";
import { ImPriceTags } from "react-icons/im";
import { priceFromList } from "../../../Helpers/Utilities";
import { FaArrowsAltH } from "react-icons/fa";

class SalesEditPrice extends Component {
  state = {
    modal: false,
    value: 0,
  };

  componentDidMount = () => {
    this.setState({ value: this.props.value });
  };

  modal = (modal = true) => {
    this.setState({ modal });
  };

  apply = (commission) => {
    var { value } = this.state;
    this.props.onChange(value, commission);
    this.modal(false);
  };
  render() {
    if (!this.props.product?.unit_price) {
      return "";
    }
    var minRate = priceFromList(this.props.product, this.props.qty);
    var commission = (this.state.value - minRate) * this.props.qty;
    return (
      <>
        <Button appearance="link" onClick={this.modal}>
          <ImPriceTags /> Adjust
        </Button>

        <Modal open={this.state.modal} onClose={() => this.modal(false)}>
          <Modal.Header>
            <Modal.Title>Adjust Item Rate</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Min Rate: <strong>₹{minRate}</strong> <FaArrowsAltH /> Max Rate:{" "}
            <strong>₹{this.props.product.unit_price}</strong>
            <br />
            Earn: <strong>₹{commission}</strong>
            <br />
            <InputNumber
              value={this.state.value}
              onChange={(value) => this.setState({ value })}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.apply(commission)}
              appearance="primary"
              disabled={
                this.state.value < minRate ||
                this.state.value > this.props.product.unit_price
              }
            >
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default SalesEditPrice;
