import React, { Component } from "react";
import { Stack } from "rsuite";
import CalibrateLeadSpot from "../Modules/CalibrateLeadSpot";

class Settings extends Component {
  state = {};
  render() {
    return (
      <div>
        <h3>App Settings</h3>
        <hr />
        <Stack justifyContent="space-between">
          <span>Calibrate LeadSpots</span> <CalibrateLeadSpot />
        </Stack>
      </div>
    );
  }
}

export default Settings;
