import React, { Component } from "react";
import constants from "../../../constants";

class SalesExport extends Component {
  state = {};
  render() {
    const { customer, sale, totalSaleAmount } = this.props;
    const styles = {
      body: {
        fontFamily: "Arial, sans-serif",
        margin: 0,
        padding: "20px",
        backgroundColor: "#f4f4f4",
      },
      invoice: {
        backgroundColor: "#fff",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        padding: "20px",
        borderRadius: "10px",
      },
      header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
      },
      logo: {
        maxWidth: "100px",
        height: "auto",
      },
      infoSection: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
      },
      info: {
        flex: 1,
        padding: "0 10px",
        borderRight: "1px solid #ddd",
      },
      table: {
        width: "100%",
        borderCollapse: "collapse",
        marginTop: "20px",
      },
      tableCell: {
        border: "1px solid #ddd",
        padding: "10px",
        textAlign: "left",
      },
      tableHeader: {
        backgroundColor: "#f2f2f2",
      },
      footer: {
        marginTop: "20px",
        textAlign: "center",
      },
    };

    return (
      <div style={styles.body}>
        <div style={styles.invoice}>
          <div style={styles.header}>
            <div>
              <img src={constants.full_logo} style={styles.logo} />
            </div>
            <div>
              <h1>Stack Enterprises</h1>
              <p>
                12/444 Varkala Road Paravur
                <br />
                Kollam - 691301
                <br />
                Kerala
                <br />
                Email: sales@salesmart.in
                <br />
                Phone: 9037063936 or 8139043936
                <br />
                Website: www.salesmart.in
                {/* <br />
                Bank Account: 1234567890 */}
              </p>
            </div>
          </div>

          <div style={styles.infoSection}>
            <div style={styles.info}>
              <h2>Invoice</h2>
              <p>
                Invoice Number: INV-{sale.id}
                <br />
                Date: {sale.date}
                <br />
                Status: {sale.status}
              </p>
            </div>
            <div style={styles.info}>
              <h2>Customer Information</h2>
              <p>
                Customer Name: {customer.shop_name}
                <br />
                Address: {customer.address}
                <br />
                Phone: {customer.phone}
                <br />
                Email: {customer.email}
              </p>
            </div>
          </div>

          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableCell}>Description</th>
                <th style={styles.tableCell}>Quantity</th>
                <th style={styles.tableCell}>Unit Price</th>
                <th style={styles.tableCell}>Total</th>
              </tr>
            </thead>
            <tbody>
              {sale?.items?.map((item, index) => {
                return (
                  <tr>
                    <td style={styles.tableCell}>{item.product.name}</td>
                    <td style={styles.tableCell}>{item.quantity}</td>
                    <td style={styles.tableCell}>Rs {item.unit_price}</td>
                    <td style={styles.tableCell}>
                      {parseFloat(item.quantity) * parseFloat(item.unit_price)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td
                  colSpan="3"
                  style={{ ...styles.tableCell, textAlign: "right" }}
                >
                  Subtotal
                </td>
                <td style={styles.tableCell}>Rs {totalSaleAmount}</td>
              </tr>
              <tr>
                <td
                  colSpan="3"
                  style={{ ...styles.tableCell, textAlign: "right" }}
                >
                  Tax (0%)
                </td>
                <td style={styles.tableCell}>Rs 0.00</td>
              </tr>
              <tr>
                <td
                  colSpan="3"
                  style={{
                    ...styles.tableCell,
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  Total
                </td>
                <td style={styles.tableCell}>Rs {totalSaleAmount}</td>
              </tr>
            </tfoot>
          </table>

          <div style={styles.footer}>
            <p>Thank you for your business!</p>
          </div>
        </div>
      </div>
    );
  }
}

export default SalesExport;
