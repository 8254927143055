import React, { Component } from "react";
import { GoogleMap, DistanceMatrixService } from "@react-google-maps/api";

import ExploreIcon from "@rsuite/icons/Explore";
import FunnelIcon from "@rsuite/icons/Funnel";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import { Button, Loader, Progress, Stack } from "rsuite";
import constants, { haversineDistance } from "../../../constants";
import Cookies from "js-cookie";

const style = {
  width: 30,
  display: "inline-block",
  marginRight: 10,
};

class TravelDistance extends Component {
  state = { distance: 0, loading: false, progress: 0, closed: false };

  componentDidMount = () => {
    // this.loadCalculateDistance();

    this.getSavedDistance();
  };

  getSavedDistance = () => {
    setTimeout(() => {
      var distances = this.props?.locations?.filter((l) => l.distance != null);
      if (distances?.length > 0) {
        this.setState({ distance: distances[distances.length - 1].distance });
      } else {
        if (this.props.sessionCheck.role != "Admin") {
          setTimeout(this.calculateOffline, 3500);
        }
      }
    }, 100);
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  loadCalculateDistance = async () => {
    this.setLoading();
    // console.log("about to calculate the travel distance");

    if (!this.props.locations) {
      this.setState({ distance: 0, loading: false });
      return;
    }

    const originLocations = this.props.locations.map((location) => ({
      lat: location.geolocation.lat,
      lng: location.geolocation.lng,
    }));

    // Calculate total distance for all pairs of consecutive locations
    let totalDistance = 0;

    for (let i = 1; i < originLocations.length; i++) {
      const origin = originLocations[i - 1];
      const destination = originLocations[i];

      try {
        const distance = await this.calculateDistance(origin, destination);
        totalDistance += distance;

        const progress = (i / originLocations.length) * 100;

        // Update both distance and progress in state
        this.setState({ distance: totalDistance, progress });

        if (i === originLocations.length - 1) {
          // this.setState({ distance: totalDistance });
          this.sendCalculatedDistance(totalDistance);
          this.setLoading(false);
        }

        // Introduce a small delay (e.g., 100ms) between iterations
        // await new Promise((resolve) => setTimeout(resolve, 100));
      } catch (error) {
        console.error("Error calculating distance:", error);
        this.setLoading(false);
      }
    }
  };

  loadCalculateDistance = async () => {
    this.setLoading();

    if (!this.props.locations) {
      this.setState({ distance: 0, loading: false });
      return;
    }

    const originLocations = this.props.locations.map((location) => ({
      lat: location.geolocation.lat,
      lng: location.geolocation.lng,
    }));

    // Define a calibration factor (adjustment factor)
    const calibrationFactor = 1.01; // You can adjust this value

    let totalDistance = 0;

    for (let i = 1; i < originLocations.length; i++) {
      const origin = originLocations[i - 1];
      const destination = originLocations[i];

      try {
        let distance = await this.calculateDistance(origin, destination);
        distance *= calibrationFactor; // Apply the calibration factor
        totalDistance += distance;

        const progress = (i / originLocations.length) * 100;

        this.setState({ distance: totalDistance, progress });

        if (i === originLocations.length - 1) {
          this.sendCalculatedDistance(totalDistance);
          this.setLoading(false);
        }
      } catch (error) {
        console.error("Error calculating distance:", error);
        this.setLoading(false);
      }
    }
  };

  calculateDistance = (origin, destination) => {
    return new Promise((resolve, reject) => {
      if (haversineDistance(origin, destination) < 500) {
        var distance = haversineDistance(origin, destination) / 1000;
        console.log(`distance shorter: ${distance}`);
        resolve(distance);
      } else {
        const service = new window.google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
            unitSystem: window.google.maps.UnitSystem.METRIC,
          },
          (response, status) => {
            if (status === "OK" && response) {
              const distance = response.rows[0].elements[0].distance.value;
              resolve(distance);
            } else {
              reject(new Error("Distance matrix request failed."));
            }
          }
        );
      }
    });
  };

  sendCalculatedDistance = (distance) => {
    this.setState({ loading: true });

    var lastLocation = this.props.locations[this.props.locations.length - 1];

    fetch(constants.url + `update-distance/${lastLocation?.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({ distance }),
    })
      .then((response) => response.json())
      .then((res) => {
        this.setState({ loading: false });
        window.toast("Travel distance updated");
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  };

  calculateOffline = () => {
    const originLocations = this.props.locations.map((location) => ({
      lat: location.geolocation.lat,
      lng: location.geolocation.lng,
    }));

    // Calculate total distance for all pairs of consecutive locations
    let totalDistance = 0;

    for (let i = 1; i < originLocations.length; i++) {
      const origin = originLocations[i - 1];
      const destination = originLocations[i];

      const distance = haversineDistance(origin, destination);
      totalDistance += distance;
    }

    this.setState({ distance: totalDistance });
  };

  render() {
    if (this.state.closed) {
      return "";
    }
    var empType =
      this.props?.sessionCheck?.active_employment?.employment_type ?? null;
    empType = this.props?.empType ?? empType;

    if (!empType) {
      return <span>No Employment!</span>;
    }

    var fuel = empType.fuel_allowance;
    var half = fuel / 2;
    var percent = 0;
    if (empType.min_customers != 0) {
      percent = this.props.customerPercent;
    } else if (empType.min_sales_value != 0) {
      percent = this.props.salesValuePercent;
    } else if (empType.min_sales_volume != 0) {
      percent = this.props.salesVolumePercent;
    }
    var actualFuel = percent > 50 ? fuel : half + (percent / 100) * half;

    var distance = this.state.distance / 1000;

    var finalFuel = 0;

    if (typeof actualFuel === "number") {
      // The value is a number, proceed with using .toFixed()
      var finalFuel = distance * actualFuel;
    } else {
      actualFuel = 0;
      console.error("actualFuel is not a valid number:", actualFuel);
    }

    return (
      <div style={{ marginTop: "10px" }}>
        <Stack justifyContent="space-between">
          <Button>
            <ExploreIcon /> &nbsp;
            {this.state.loading ? (
              <div style={style}>
                <Progress.Circle
                  percent={parseInt(this.state.progress)}
                  strokeColor="#ffc107"
                />
              </div>
            ) : (
              distance?.toFixed(2)
            )}{" "}
            KM{" "}
          </Button>
          <Button>
            <FunnelIcon />{" "}
            {`Fuel ₹ ${finalFuel?.toFixed(2)} : ₹${actualFuel?.toFixed(2)}`}
          </Button>
          {this.props.sessionCheck.role == "Admin" && (
            <Button
              appearance="link"
              disabled={this.state.loading}
              onClick={this.loadCalculateDistance}
            >
              Calculate KM
            </Button>
          )}
          {this.props.canClose && (
            <Button onClick={() => this.setState({ closed: true })} size="xs">
              <CloseOutlineIcon />
            </Button>
          )}
        </Stack>
      </div>
    );
  }
}

export default TravelDistance;
