import Cookies from "js-cookie";
import React, { Component } from "react";
import { Loader } from "rsuite";
import constants from "../constants";
import { getDuplicateLeads } from "../Helpers/CoordinateHelper";

class LeadEvaluator extends Component {
  state = {
    duplicates: [],
    invalidLocations: [],
    clickedLead: null,
    selectedLead: null,
    selectedCustomer: null,
    loading: false,
  };

  setLoading = (loading = true) => this.setState({ loading });

  componentDidMount = async () => {
    //LOAD DUPLICATE AN INVALID LEADS..
    // var selectedRouteId = this.props.route.id;
    // this.setLoading();
    // this.setState({ leads: [] });
    // fetch(constants.url + "lead_spots/evaluate/" + selectedRouteId, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${Cookies.get("u-token")}`,
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     this.setLoading(false);
    //     this.setState({ data });
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     this.setLoading(false);
    //   });
  };
  render() {
    return (
      <div>
        {this.state.loading && <Loader size="sm" />}
        <h1>
          Lead Evaluator - {this.state.data?.duplicates?.length} /{" "}
          {this.props.leads?.length} Probable Duplicates
        </h1>
      </div>
    );
  }
}

export default LeadEvaluator;
