import React, { Component } from "react";

import {
  Stack,
  Panel,
  ButtonGroup,
  Button,
  Loader,
  DateRangePicker,
  Divider,
  Badge,
  InputNumber,
  InputGroup,
  List,
  Input,
  DatePicker,
  Modal,
  InputPicker,
} from "rsuite";
import ResponsiveNav from "@rsuite/responsive-nav";
import constants from "../../../../constants";
import moment from "moment";
import Cookies from "js-cookie";
import Vendors from "./vendors";
import Materials from "./materials";

class PurchaseIndex extends Component {
  state = { active: "dashboard" };

  setActive = (active) => {
    if (active != null) this.setState({ active });
  };
  render() {
    var { active } = this.state;
    return (
      <div>
        <ResponsiveNav
          appearance="subtle"
          activeKey={active}
          onSelect={this.setActive}
          style={{ marginBottom: 5 }}
        >
          <ResponsiveNav.Item eventKey="dashboard">
            Dashboard
          </ResponsiveNav.Item>
          <ResponsiveNav.Item eventKey="materials">
            Materials
          </ResponsiveNav.Item>
          <ResponsiveNav.Item eventKey="vendors">Vendors</ResponsiveNav.Item>
        </ResponsiveNav>
        <br />
        {active == "vendors" && <Vendors />}
        {active == "materials" && <Materials />}
      </div>
    );
  }
}

export default PurchaseIndex;
