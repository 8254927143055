import React, { Component } from "react";
import { List, Tag, Badge, Divider } from "rsuite";
import moment from "moment";
import SalesQtyList from "../../../../Modules/SalesQtyList";

class UserReturns extends Component {
  state = {};
  render() {
    var { data } = this.props;

    var returns = this.props.replaced
      ? data.sales_returns_replaced
      : data.sales_returns;
    return (
      <div>
        <SalesQtyList returns={returns} label="Calculate Total Quantity" />
        <Divider />
        <List size="lg" hover>
          {returns.map((returns, index) => {
            return (
              <List.Item
                key={index}
                index={index}
                onClick={() => this.props.selectCustomer(returns.customer)}
              >
                <strong>
                  Shop name: {returns.customer_name} ({returns.customer_id})
                </strong>{" "}
                <br />
                <Tag color="blue" size="xs">
                  &nbsp;
                  {returns.return.replace_product == 1
                    ? "Replace done"
                    : "Pending replace"}
                  &nbsp;
                </Tag>
                <br />{" "}
                <i>
                  {returns.product} | {returns.return.quantity} Pcs
                </i>
                <br />
                <Tag>
                  Created: {moment(returns.return.created_at).fromNow()}
                </Tag>
                <br />
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

export default UserReturns;
