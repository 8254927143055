import React, { Component } from "react";
import { Button, Modal } from "rsuite";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { GoogleMap, MarkerF, PolylineF } from "@react-google-maps/api";
import Cookies from "js-cookie";
import constants, { getCurrentDateTime } from "../constants";

class MyLocations extends Component {
  state = {
    modal: false,
    locations: [],
    loading: false,
  };

  modal = (modal = true) => this.setState({ modal });
  loading = (loading = true) => this.setState({ loading });

  componentDidMount = () => {
    this.loadLocations();
  };

  loadLocations = () => {
    this.loading();
    fetch(constants.url + "my-locations", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ locations: data }, () => this.loading(false));
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
      });
  };

  sendUserLocation = () => {
    if (this.props.currentLocation != null) {
      const url = constants.url + "updateUserLocation";

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${Cookies.get("u-token")}`);

      var formdata = new FormData();
      formdata.append(
        "geolocation",
        JSON.stringify(this.props.currentLocation)
      ); // Convert the object to a JSON string

      formdata.append("updateTime", getCurrentDateTime());

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      this.loading();
      fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.loadLocations();
        })
        .catch((error) => this.loading(false));
    }
  };

  render() {
    return (
      <>
        <Button onClick={this.modal}>
          <FaLocationCrosshairs />
        </Button>

        <Modal open={this.state.modal} onClose={() => this.modal(false)}>
          <Modal.Header>
            <Modal.Title>
              MyLocations ({this.state.locations.length})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "350px",
              }}
              center={
                this.state.locations[0]
                  ? JSON.parse(this.state.locations[0].geolocation)
                  : this.props.currentLocation
              }
              zoom={12}
            >
              <PolylineF
                path={this.state.locations.map((location) => ({
                  lat: JSON.parse(location.geolocation)?.lat,
                  lng: JSON.parse(location.geolocation)?.lng,
                }))}
                options={{
                  strokeColor: "#2ecc71",
                  strokeOpacity: 1,
                  strokeWeight: 3,
                }}
              />

              <MarkerF position={this.props.currentLocation} label="You" />
            </GoogleMap>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.sendUserLocation}
              appearance="primary"
              disabled={this.state.loading}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default MyLocations;
