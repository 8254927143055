import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  InputGroup,
  InputPicker,
  Loader,
  Rate,
} from "rsuite";
import TextField from "../Modules/TextField";
import FrownIcon from "@rsuite/icons/legacy/FrownO";
import MehIcon from "@rsuite/icons/legacy/MehO";
import SmileIcon from "@rsuite/icons/legacy/SmileO";
import ReloadIcon from "@rsuite/icons/Reload";
import constants from "../constants";
import Cookies from "js-cookie";

export const renderRatingCharacter = (value, index) => {
  // unselected character
  if (value < index + 1) {
    return <MehIcon />;
  }
  if (value < 3) {
    return <FrownIcon style={{ color: "#b13131" }} />;
  }
  if (value < 4) {
    return <MehIcon style={{ color: "#F4CA1D" }} />;
  }
  return <SmileIcon style={{ color: "#33b131" }} />;
};

export const rateTexts = {
  1: "Useless",
  2: "Poor",
  3: "Average",
  4: "Good",
  5: "Excellent",
};

export const rateColors = {
  1: "red",
  2: "orange",
  3: "cyan",
  4: "blue",
  5: "green",
};

const textStyle = {
  verticalAlign: "top",
  lineHeight: "35px",
  display: "inline-block",
  fontWeight: "bold",
};

class NewCustomerForm extends Component {
  state = {
    edit: false,
    shopTypes: [],
    data: {
      address: "",
      rating: 3,
      shop_type_id: 1,
    },
    loading: false,
  };

  componentDidMount = () => {
    //load shop types
    this.setState({
      shopTypes: constants.shopTypes,
    });
    this.props.locateMe(this.setAddress);
    if (this.props.data) {
      this.setState({ edit: true, data: this.props.data });
    }
  };

  setAddress = (address = this.props.currentAddress) => {
    this.dataChange("geo_location", JSON.stringify(this.props.currentLocation));
    this.dataChange("address", address);
  };

  setRating = (rating) => {
    this.dataChange("rating", rating);
  };

  dataChange = (name, value) => {
    var { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  saveCustomer = () => {
    this.dataChange("geo_location", JSON.stringify(this.props.currentLocation));
    var { data } = this.state;

    if (!this.state.edit) {
      this.setLoading();
      //create request..
      fetch(constants.url + "customers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((response_data) => {
          console.log(response_data);
          // this.viewCustomer();
          this.setState({ data: response_data.data });
          this.props.selectCustomer(response_data.data);
          this.setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          this.setLoading(false);
          alert("Data Error, check contents");
        });
    } else {
      //update reqeust..
      fetch(constants.url + "customers/" + data.id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((response_data) => {
          console.log(response_data);
          // this.viewCustomer();
          this.setState({ data: response_data.data });
          this.props.selectCustomer(response_data.data);
          this.setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          this.setLoading(false);
          alert("Check data, Customer might already exist!");
        });
    }
  };

  viewCustomer = () => {
    this.props.selectCustomer(this.state.data);
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  render() {
    var { data } = this.state;
    return (
      <div>
        <Form className="flex-column">
          <label>Shop Name</label>
          <Input
            value={data.shop_name}
            onChange={(value) => this.dataChange("shop_name", value)}
          />
          <br />
          <label>Contact Person</label>
          <Input
            value={data.person_name}
            onChange={(value) => this.dataChange("person_name", value)}
          />
          <br />
          <label>Contact Number (Phone)</label>
          <InputNumber
            value={data.phone}
            onChange={(value) => this.dataChange("phone", value)}
          />
          <br />
          <label>Email Address</label>
          <Input
            value={data.email}
            onChange={(value) => this.dataChange("email", value)}
          />
          <br />
          <label>GST No</label>
          <Input
            value={data.gst}
            onChange={(value) => this.dataChange("gst", value)}
          />
          <br />
          <Form.Group controlId="shop_type_id">
            <Form.ControlLabel>Shop Type </Form.ControlLabel>
            <InputPicker
              data={this.state.shopTypes}
              value={data.shop_type_id}
              onChange={(value) => this.dataChange("shop_type_id", value)}
            />
          </Form.Group>
          <label>Address / Location</label>
          <InputGroup>
            <Input value={data.address} onChange={this.setAddress} />
            <InputGroup.Button>
              {this.props.currentAddress == null ? (
                <Loader size="xs" content="" />
              ) : (
                <ReloadIcon
                  onClick={() => this.props.locateMe(this.setAddress)}
                />
              )}
            </InputGroup.Button>
          </InputGroup>
          <br />
          <div>
            <Rate
              defaultValue={1}
              renderCharacter={renderRatingCharacter}
              value={data.rating}
              onChange={this.setRating}
              onChangeActive={this.setRating}
              cleanable={false}
            />
            <span style={textStyle}>{rateTexts[data.rating]}</span>
          </div>
          <br />
          <label>Remarks</label>
          <Input
            as="textarea"
            rows={2}
            placeholder="Remarks or notes"
            value={data.remarks}
            onChange={(value) => this.dataChange("remarks", value)}
            disabled={this.state.loading}
          />
          <br />
          <Button
            onClick={this.saveCustomer}
            appearance="primary"
            className="center"
          >
            {this.state.loading ? (
              <Loader size="xs" content="" />
            ) : (
              "Save Customer"
            )}
          </Button>
        </Form>
      </div>
    );
  }
}

export default NewCustomerForm;
