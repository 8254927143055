import Login from "./Pages/login";

import "rsuite/dist/rsuite.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import MobileHome from "./Pages/mobile_home";
import Cookies from "js-cookie";
import PullToRefresh from "react-simple-pull-to-refresh";

function App() {
  const token = window.localStorage.getItem("u-token");
  if (token) {
    Cookies.set("u-token", token);
  }

  return (
    // <PullToRefresh
    //   isPullable={window.refreshFunction !== null}
    //   resistance={4}
    //   onRefresh={window.handleRefresh}
    // >
    <div className="App">
      {/* <Main /> */}
      {!token ? <Login /> : <MobileHome />}
    </div>
    // </PullToRefresh>
  );
}

export default App;
