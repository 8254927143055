import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  Button,
  ButtonGroup,
  DatePicker,
  InputNumber,
  InputPicker,
  Loader,
  Message,
  Modal,
  Drawer,
  Panel,
  Stack,
  Divider,
  Toggle,
  InputGroup,
  Input,
  List,
  Tag,
} from "rsuite";
import GearIcon from "@rsuite/icons/Gear";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";

import constants, {
  alertError,
  getCurrentDateTime,
  getProductImage,
} from "../../../constants";
import SalesExport from "./sales-export";
import { CheckRound } from "@rsuite/icons";
import SalesPayments from "./sales-payments";
import { priceFromList } from "../../../Helpers/Utilities";
import { loadUsers } from "../../../Loaders/GeneralLoder";
import SalesEditPrice from "./sales-edit-price";

class SalesEdit extends Component {
  state = {
    edit: false,
    data: {
      customer_id: this.props.customer.id,
      date: new Date(),
    },
    loading: false,
    products: [],
    users: [],
    itemModal: false,
    deliveryModal: false,
    selectedItem: null,
    tempSaleItem: {
      product_id: null,
      quantity: 1,
      unit_price: 0,
      commission: 0,
    },
    exportModal: false,
    changedAnything: false,
  };

  componentDidMount = () => {
    if (this.props.data) {
      this.setState({ data: this.props.data, edit: true });
    }

    this.loadProducts();
    if (this.props.sessionCheck.role == "Admin") {
      loadUsers((users) => this.setState({ users }));
    }
  };

  loadProducts = () => {
    fetch(constants.url + "products", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ products: data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  changeDate = (date) => {
    var { data } = this.state;
    data.date = date;
    this.setState({ data });
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  changeData = (where, what, autoSave = false) => {
    var { data } = this.state;
    data[where] = what;
    this.setState({ data, changedAnything: true });
    if (autoSave) {
      this.saveData();
    }
  };

  saveData = () => {
    fetch(constants.url + `sales/${this.state.data.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(this.state.data),
    })
      .then((response) => response.json())
      .then((saleItem) => {
        console.log(saleItem);
        this.setState({ changedAnything: false });
        this.refreshSale();
        this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  itemModal = (itemModal = true) => {
    var { selectedItem } = this.state;
    selectedItem = !itemModal ? null : selectedItem;
    this.setState({ itemModal, selectedItem });
    // console.log(`selected item change, by item modal: ${selectedItem}`);
    this.setTempSaleItem(null, 1, 0, null);
  };

  deliveryModal = (deliveryModal = true) => {
    this.setState({ deliveryModal });
  };

  exportModal = (exportModal = true) => {
    this.setState({ exportModal });
  };

  getSimpleDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
  };

  submitSale = () => {
    console.log(this.state.data);
    this.setLoading();
    var data = this.state.data;
    if (data.date) {
      if (data.date instanceof Date && !isNaN(data.date)) {
        var { date } = data;
        // data.date = data.date.toISOString().replace("T", " ").slice(0, -5);
        data.date = this.getSimpleDate(date);
        //create sales
        fetch(constants.url + "sales", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("u-token")}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({ data: data.data });
            setTimeout(() => {
              this.refreshSale();
            }, 200);
            this.setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            this.setLoading(false);
          });
      } else {
        this.setLoading(false);
      }
    } else {
      alert("Select sales date");
      this.setLoading(false);
    }
  };

  setTempSaleItem = (id, qty, unit_price = null, commission = null) => {
    var { tempSaleItem, products } = this.state;
    var product = products.filter((p) => p.id == id)[0];
    tempSaleItem.product_id = id;
    tempSaleItem.quantity = qty;
    if (commission) {
      tempSaleItem.commission = commission;
    }
    tempSaleItem.unit_price = unit_price ?? priceFromList(product, qty);
    this.setState({ tempSaleItem });
    console.log("set temp sale item");
    console.log(tempSaleItem);
  };

  saveItem = () => {
    var { selectedItem, data, tempSaleItem } = this.state;
    if (!tempSaleItem.product_id) {
      alert("You must select a prodcut!");
      return;
    }

    this.setLoading();

    if (selectedItem) {
      //edit item
      fetch(constants.url + `sales/${data.id}/items/${selectedItem.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(tempSaleItem),
      })
        .then((response) => response.json())
        .then((saleItem) => {
          console.log(saleItem);

          this.refreshSale();
          this.itemModal(false);
          this.setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          this.setLoading(false);
        });
    } else {
      //add item
      fetch(constants.url + `sales/${data.id}/items`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(tempSaleItem),
      })
        .then((response) => response.json())
        .then((saleItem) => {
          console.log(saleItem);

          this.refreshSale();
          this.itemModal(false);
          this.setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          this.setLoading(false);
        });
    }
  };

  startEditItem = (index) => {
    var selectedItem = this.state.data.items[index];
    this.setTempSaleItem(
      selectedItem.product_id,
      selectedItem.quantity,
      selectedItem.unit_price
    );
    this.setState({ selectedItem, itemModal: true });
    // this.itemModal();
  };

  deleteItem = () => {
    var { selectedItem } = this.state;
    let confCheck = window.confirm("Are you sure to delete this item?");
    if (confCheck && selectedItem) {
      this.setLoading();
      fetch(
        constants.url + `sales/${this.state.data.id}/items/${selectedItem.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("u-token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.itemModal(false);
          this.refreshSale();

          this.setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          this.setLoading(false);
        });
    }
  };

  changeDeliveryDate = (deliveryDate) => {
    var { data } = this.state;
    data.delivery_date = deliveryDate;
    this.setState({ data });
  };

  handleStatusChange = (status, conf = false) => {
    this.deliveryModal(false);
    if (conf) {
      const userConfirmed = window.confirm(
        `Are you sure you want to change the status to ${status}?`
      );
      if (userConfirmed) {
        this.handleStatusChange(status, false);
      }
    } else {
      //CHECK FOR ZERO PAYMENTS.. TO COMPLETE ORDER
      if (status == "complete" && this.state.data.payments.length == 0) {
        alertError("No Payments Received");
        return;
      }
      //CHECK FOR PENDING PAYMENTS.. TO COMPLETE ORDER
      if (status == "complete" && this.balanceAmount() > 0) {
        alertError("Pending Payments!");
        return;
      }
      this.setLoading();
      fetch(constants.url + `sales/${this.state.data.id}/status`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify({
          status,
          delivery_date:
            status == "delivery"
              ? this.getSimpleDate(this.state.data.delivery_date)
              : null,
        }),
      })
        .then((response) => response.json())
        .then((sale) => {
          this.refreshSale();
          this.setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          this.setLoading(false);
        });
    }
  };

  updateDeliveryBy = (delivery_by) => {
    this.setLoading();
    fetch(constants.url + `sales/${this.state.data.id}/delivery`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({
        delivery_by: delivery_by,
      }),
    })
      .then((response) => response.json())
      .then((sale) => {
        this.refreshSale();
        this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  refreshSale = () => {
    this.setLoading();
    fetch(constants.url + `sales/${this.state.data.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ data: data.data });

        this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  totalSaleAmount = () => {
    var { data } = this.state;

    return data?.items?.reduce(
      (accumulator, currentValue) =>
        accumulator +
        parseFloat(currentValue?.unit_price) *
          parseFloat(currentValue?.quantity),
      0
    );
  };

  totalPaid = () => {
    var { data } = this.state;

    return data?.payments?.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue?.amount),
      0
    );
  };

  balanceAmount = () => {
    var { data } = this.state;

    return (
      this.totalSaleAmount() +
      parseFloat(data.channel_rate) +
      parseFloat(data.cod_fee) -
      this.totalPaid()
    );
  };

  render() {
    var { customer } = this.props;
    var { data, selectedItem, products, tempSaleItem } = this.state;

    var eligibleDeliveryDays = JSON.parse(
      customer?.route?.cord2 ??
        this.props.sessionCheck.routes.find(
          (route) => route.id === customer.route_id
        )?.cord2 ??
        "[]"
    );

    return (
      <div>
        {!data.id && (
          <Panel>
            <DatePicker
              cleanable={false}
              onChange={(value) => this.changeDate(value)}
              value={
                typeof data.date === "string" && !isNaN(Date.parse(data.date))
                  ? new Date(data.date)
                  : data.date
              }
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="Sales Date"
              placement="auto"
            />

            <br />
            <br />
            <Button
              appearance="primary"
              onClick={this.submitSale}
              disabled={this.state.loading}
            >
              {this.state.loading ? (
                <Loader size="xs" content="Loading" />
              ) : (
                "Create Sale"
              )}
            </Button>
          </Panel>
        )}

        {data.id && (
          <Panel
            header={
              <div>
                {data.date} <br />
                <Stack justifyContent="space-between">
                  <span>{`INV #${data.id}`}</span>
                  <ButtonGroup>
                    {data.status == "pending" || data.status == "delivery" ? (
                      <Button onClick={this.itemModal}>Add Item</Button>
                    ) : (
                      ""
                    )}
                    {/* <Button>Payment Receive</Button> */}
                    <Button
                      appearance="ghost"
                      color="blue"
                      onClick={this.refreshSale}
                      disabled={this.state.loading}
                    >
                      {this.state.loading ? (
                        <Loader size="xs" content="Loading" />
                      ) : (
                        "Refresh"
                      )}{" "}
                    </Button>
                    <Button
                      onClick={() => this.setState({ exportModal: true })}
                    >
                      Export
                    </Button>
                    {/* <Button>Cancel</Button> */}
                  </ButtonGroup>
                </Stack>
              </div>
            }
          >
            <Divider>Order Items</Divider>
            {!data?.items && (
              <Message type="warning">
                <strong>Add items.</strong>.
              </Message>
            )}
            <List>
              {data?.items?.map((item, i) => {
                return (
                  <List.Item key={i}>
                    <Stack justifyContent="space-between">
                      <Stack>
                        <img
                          src={getProductImage(item?.product)}
                          style={{
                            width: "55px",
                            height: "55px",
                            marginRight: "10px",
                            objectFit: "cover",
                          }}
                        />
                        <span>
                          <strong>{item?.product?.name}</strong>
                          <br />
                          <i>
                            {item?.quantity} X ₹{item?.unit_price}
                          </i>
                          <strong>
                            &nbsp;= ₹
                            {parseFloat(item.quantity) *
                              parseFloat(item.unit_price)}
                          </strong>
                          <br />
                          {!this.state.minifyInfo && (
                            <Tag>
                              Stock:{" "}
                              {parseInt(item?.product?.current_stock ?? 0)}
                            </Tag>
                          )}
                          <Tag>
                            Comm:{"₹"}
                            {item.commission}
                          </Tag>
                        </span>
                      </Stack>
                      <Button
                        appearance="link"
                        onClick={() => this.startEditItem(i)}
                        disabled={
                          data.status.startsWith("complete") ||
                          data.status == "cancelled"
                        }
                      >
                        <EditIcon /> edit
                      </Button>
                    </Stack>
                  </List.Item>
                );
              })}
            </List>
            <table className="table-style">
              <tr>
                <th>Subtotal</th>
                <th>{this.totalSaleAmount()}</th>
              </tr>
              <tr>
                <th>Shipping Charge</th>
                <th>{data.channel_rate}</th>
              </tr>
              {data.cod != 0 ? (
                <tr>
                  <th>COD Fee</th>
                  <th>{data.cod_fee}</th>
                </tr>
              ) : (
                ""
              )}
              <tr>
                <th>Total</th>
                <th>
                  {this.totalSaleAmount() +
                    parseFloat(data.channel_rate) +
                    parseFloat(data.cod_fee)}
                </th>
              </tr>
              <tr>
                <th>Paid:</th>
                <th>{this.totalPaid()}</th>
              </tr>
              <tr>
                <th>Balance Amount</th>
                <th>{this.balanceAmount()}</th>
              </tr>
            </table>

            <br />
            {this.props.sessionCheck.role == "Admin" ? (
              !this.state.advanced ? (
                <Button
                  appearance="link"
                  onClick={() => this.setState({ advanced: true })}
                >
                  Advanced
                </Button>
              ) : (
                <>
                  <Divider>Assigned User</Divider>
                  <InputPicker
                    data={this.state.users.map((i) => ({
                      label: i.name,
                      value: i.id,
                    }))}
                    value={data.created_by}
                    onChange={(value) => this.changeData("created_by", value)}
                    disabled={this.state.loading}
                    placement="auto"
                  />
                  <Divider>Shipping Channel</Divider>
                  <Input
                    value={data.channel}
                    onChange={(value) => this.changeData("channel", value)}
                  />

                  <br />
                  <Stack justifyContent="space-between">
                    COD ({data.cod_fee})
                    <Toggle
                      checked={data.cod}
                      checkedChildren="Selected COD"
                      unCheckedChildren="Not selected"
                      onChange={(value) => this.changeData("cod", value, true)}
                    />
                  </Stack>
                  <br />
                  {data.cod && (
                    <InputNumber
                      value={data.cod_fee}
                      onChange={(value) => this.changeData("cod_fee", value)}
                    />
                  )}
                  <br />
                  <label>Channel Rate(per KG)</label>
                  <InputNumber
                    block
                    value={data.channel_rate}
                    onChange={(value) => this.changeData("channel_rate", value)}
                  />
                  <br />
                  <label>Total Weight(in KG)</label>
                  <InputNumber
                    block
                    value={data.total_weight}
                    onChange={(value) => this.changeData("total_weight", value)}
                  />
                  <br />
                  <label>Chargable Weight(in KG)</label>
                  <InputNumber
                    block
                    value={data.charged_weight}
                    onChange={(value) =>
                      this.changeData("charged_weight", value)
                    }
                  />
                </>
              )
            ) : (
              ""
            )}

            <br />
            <Divider>
              {this.state.loading ? (
                <Loader size="xs" content="Loading" />
              ) : (
                "Update status"
              )}
            </Divider>
            <br />
            {this.props.sessionCheck.role == "Admin" ? (
              <div>
                <InputPicker
                  data={constants.salesStatuses}
                  value={data.status}
                  onChange={(value) => {
                    if (value == "delivery") {
                      this.deliveryModal();
                    } else {
                      this.handleStatusChange(value);
                    }
                  }}
                  disabled={this.state.loading}
                  placement="auto"
                />
              </div>
            ) : data.status && data?.items?.length != 0 ? (
              <div>
                {!data.status.startsWith("complete") &&
                  data.status != "cancelled" && (
                    <div>
                      <Button
                        block
                        appearance="primary"
                        color="green"
                        onClick={() => {
                          if (this.balanceAmount() > 0) {
                            alertError("Payments pending..");
                          } else {
                            this.handleStatusChange("complete", true);
                          }
                        }}
                      >
                        Complete Order
                      </Button>
                    </div>
                  )}

                <br />
                {data.status != "cancelled" &&
                  !data.status.startsWith("paid") && (
                    <Button
                      block
                      appearance="primary"
                      color="yellow"
                      onClick={this.deliveryModal}
                    >
                      {" "}
                      {data.status != "delivery" ? "Make" : "Edit"} Delivery
                      Order{" "}
                    </Button>
                  )}
              </div>
            ) : (
              ""
            )}
            <br />
            <Message
              type={
                data.status == "cancelled"
                  ? "error"
                  : data?.status?.startsWith("paid")
                  ? "success"
                  : "warning"
              }
            >
              Status:{" "}
              {data?.status == "delivery"
                ? `Delivery ${data.delivery_date} `
                : data.status ?? "Pending"}
            </Message>

            <br />
            <strong>Notes</strong>
            <Input
              as="textarea"
              rows={3}
              value={data.notes}
              onChange={(value) => this.changeData("notes", value)}
            />
            <br />
            {this.state.changedAnything && (
              <Button onClick={this.saveData} appearance="primary" block>
                Save
              </Button>
            )}
            <br />
            <SalesPayments
              sale={data}
              balanceAmount={this.balanceAmount()}
              refresh={this.refreshSale}
            />
            <br />
            <br />
          </Panel>
        )}

        {data?.uid && (
          <a
            className="rs-btn rs-btn-primary rs-btn-sm"
            href={`sms://${customer.phone}/?body=${encodeURIComponent(
              `Your order with SalesMart #${data.id} is confirmed, for ₹${
                this.totalSaleAmount() +
                parseFloat(data.channel_rate) +
                parseFloat(data.cod_fee)
              } (Paid: ${this.totalPaid()}). To track visit: https://track.salesmart.in/?${
                data.uid
              }`
            )}`}
          >
            Send track link
          </a>
        )}

        {/* Sales item modal */}
        <Modal
          open={this.state.itemModal}
          onClose={() => this.itemModal(false)}
        >
          <Modal.Header>
            <Modal.Title>{selectedItem ? "Edit" : "Add"} item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {products ? (
              <InputPicker
                disabled={selectedItem != null}
                data={products?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                block
                value={tempSaleItem.product_id}
                onChange={(value) =>
                  this.setTempSaleItem(value, tempSaleItem.quantity)
                }
              />
            ) : (
              <Loader size="xs" content="Loading products" />
            )}
            <br />
            <InputNumber
              prefix="#"
              disabled={this.state.tempSaleItem.product_id == null}
              placeholder="Quantity"
              value={tempSaleItem.quantity}
              onChange={(value) =>
                this.setTempSaleItem(tempSaleItem.product_id, value)
              }
              min={1}
            />
            <br />
            <InputNumber
              prefix="RS "
              plaintext={this.props.sessionCheck.role != "Admin"}
              disabled={this.state.tempSaleItem.product_id == null}
              placeholder="Unit Price"
              value={tempSaleItem.unit_price}
              onChange={(value) =>
                this.setTempSaleItem(
                  tempSaleItem.product_id,
                  tempSaleItem.quantity,
                  value
                )
              }
              min={1}
            />
            <br />
            {this.props.sessionCheck?.active_employment?.employment_type
              ?.earnings != 0 && (
              <SalesEditPrice
                product={
                  products.filter((p) => p.id == tempSaleItem.product_id)[0]
                }
                qty={tempSaleItem.quantity}
                value={tempSaleItem.unit_price}
                onChange={(value, commission) =>
                  this.setTempSaleItem(
                    tempSaleItem.product_id,
                    tempSaleItem.quantity,
                    value,
                    commission
                  )
                }
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.saveItem}
              appearance="primary"
              disabled={this.state.loading}
            >
              {this.state.loading ? (
                <Loader size="xs" content="Saving" />
              ) : (
                "Save"
              )}
            </Button>
            <Button
              onClick={() => this.itemModal(false)}
              appearance="subtle"
              disabled={this.state.loading}
            >
              Cancel
            </Button>
            {selectedItem && (
              <Button
                appearance="primary"
                color="red"
                onClick={this.deleteItem}
              >
                {this.state.loading ? (
                  <Loader size="xs" content="" />
                ) : (
                  <span>
                    <TrashIcon /> Delete
                  </span>
                )}
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        {/* Delivery model */}
        <Modal
          open={this.state.deliveryModal}
          onClose={() => this.deliveryModal(false)}
        >
          <Modal.Header>
            <Modal.Title>Sales Delivery Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DatePicker
              cleanable={false}
              onChange={(value) => this.changeDeliveryDate(value)}
              value={
                typeof data.delivery_date === "string" &&
                !isNaN(Date.parse(data.delivery_date))
                  ? new Date(data.delivery_date)
                  : data.delivery_date
              }
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="Delivery Date and time"
              placement="auto"
              shouldDisableDate={(date) => {
                // Disable only if the user is not an admin
                if (this.props.sessionCheck.role === "Admin") return false;

                // Otherwise, disable dates not in eligibleDeliveryDays
                return !eligibleDeliveryDays.includes(
                  date.toLocaleDateString("en-US", { weekday: "long" })
                );
              }}
              renderCell={(date) => {
                const isEligible = eligibleDeliveryDays.includes(
                  date.toLocaleDateString("en-US", { weekday: "long" })
                );
                return (
                  <span
                    className={
                      isEligible ? "highlight-eligible-day" : "normal-day"
                    }
                  >
                    {date.getDate()}
                  </span>
                );
              }}
            />
            <br />
          </Modal.Body>
          <Modal.Footer>
            {data.delivery_date && (
              <Button
                onClick={() => this.handleStatusChange("delivery", true)}
                appearance="primary"
                disabled={this.state.loading}
              >
                {this.state.loading ? (
                  <Loader size="xs" content="Saving" />
                ) : (
                  "Save"
                )}
              </Button>
            )}
            <Button
              onClick={() => this.deliveryModal(false)}
              appearance="subtle"
              disabled={this.state.loading}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* EXPORT MODAL */}
        <Drawer
          size="full"
          placement="bottom"
          open={this.state.exportModal}
          onClose={() => this.exportModal(false)}
        >
          <Drawer.Body>
            <SalesExport
              customer={this.props.customer}
              sale={this.state.data}
              totalSaleAmount={this.totalSaleAmount()}
            />
          </Drawer.Body>
        </Drawer>
      </div>
    );
  }
}

export default SalesEdit;
