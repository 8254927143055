import Cookies from "js-cookie";
import React, { Component } from "react";
import { Button, ButtonGroup, InputPicker, Loader, Panel, Stack } from "rsuite";
import constants from "../../../constants";

class SalesHome extends Component {
  state = {
    sales: null,
    routes: [],
  };

  componentDidMount = () => {
    this.loadSales();
    this.loadRoutes();
  };

  loadSales = () => {
    this.setState({ sales: null });
    fetch(constants.url + "sales", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((sales) => {
        this.setState({ sales: sales.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  loadRoutes = () => {
    this.setState({ routes: null });
    // this.setLoading();
    fetch(constants.url + "routes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ routes: data.routes });
        // this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // this.setLoading(false);
      });
  };

  render() {
    var { sales, selectedRoute, dateRange, routes } = this.state;
    if (!sales) {
      return <Loader size="sm" content="Loading sales..." />;
    }
    return (
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Sales History</span>
            <ButtonGroup>
              <Button onClick={this.loadSales}>Refresh</Button>
            </ButtonGroup>
          </Stack>
        }
      >
        <div>
          <Stack justifyContent="space-between">
            <InputPicker
              placeholder="Select route"
              value={selectedRoute}
              data={routes.map((route) => ({
                label: route.route_name,
                value: route.id,
              }))}
            />
          </Stack>

          <table className="table-style">
            <tr>
              <th>Date</th>
              <th>Inv#</th>
              <th>Customer#</th>
              <th>Items</th>
              <th>Qty</th>
              <th>Amount</th>
            </tr>

            {sales?.map((item, index) => {
              return (
                <tr>
                  <td>{item.date}</td>
                  <td>#{item.id}</td>
                  <td>#{item.customer_id}</td>
                  <td>#{item.items?.length}</td>
                  <td>
                    {item?.items?.reduce(
                      (subTotal, item) => subTotal + item?.quantity,
                      0
                    )}
                  </td>
                  <td style={{ width: "70px" }}>
                    {item.items.reduce(
                      (subTotal, item) =>
                        subTotal + item.unit_price * item.quantity,
                      0
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </Panel>
    );
  }
}

export default SalesHome;
