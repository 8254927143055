import Cookies from "js-cookie";
import React, { Component } from "react";
import { Button, Divider, InputNumber, List, Modal } from "rsuite";
import constants, { alertError, alertInfo } from "../constants";

class ProductPrices extends Component {
  state = {
    modal: false,
    item: null,
    loading: false,
  };

  setLoading = (loading = true) => this.setState({ loading });

  modal = (modal = true, item = null) => {
    if (!item) {
      var { product } = this.props;
      item = {
        product_id: product.id,
        min: 0,
        max: 0,
        unit_price: 0,
      };
    }
    this.setState({ modal, item });
  };

  changeItem = (where, what) => {
    var { item } = this.state;
    item[where] = what;
    this.setState({ item });
  };

  save = () => {
    this.setLoading(false);
    var { item } = this.state;
    var url = constants.url + `prods/price` + (item.id ? `/${item.id}` : "");
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(item),
    })
      .then((response) => response.json())
      .then((data) => {
        alertInfo(`Product price saved`);
        if (!item.id) {
          var { product } = this.props;
          product.prices.push(data.price);
        }
        this.setLoading(false);
        this.modal(false);
      })
      .catch((error) => {
        console.error(error);
        // alert("Please check the entered data!");
        alertError("Failed to save price");
        this.setLoading(false);
      });
  };

  render() {
    var { item } = this.state;
    var { product } = this.props;
    return (
      <div>
        <Divider>
          Prices -{" "}
          <Button appearance="link" onClick={this.modal}>
            Create
          </Button>
        </Divider>

        <List hover>
          {product.prices.map((price) => (
            <List.Item key={price.id} onClick={() => this.modal(true, price)}>
              <i>
                Min: {price?.min} | Max: {price?.max}
              </i>
              <br /> <strong>{price.unit_price}</strong>
            </List.Item>
          ))}
        </List>

        {/* Modal */}
        <Modal open={this.state.modal} onClose={() => this.modal(false)}>
          <Modal.Header>
            <Modal.Title>
              {item?.id && "Edit - "}Product Price Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <i>Min Qty</i>
            <InputNumber
              block
              value={item?.min}
              onChange={(value) => this.changeItem("min", value)}
            />
            <br />
            <i>Max Qty</i>
            <InputNumber
              block
              value={item?.max}
              onChange={(value) => this.changeItem("max", value)}
            />
            <br />
            <i>Unit Price</i>
            <InputNumber
              block
              value={item?.unit_price}
              onChange={(value) => this.changeItem("unit_price", value)}
            />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.save}
              appearance="primary"
              disabled={this.state.loading}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ProductPrices;
