import React, { Component } from "react";
import { List, Tag, Badge } from "rsuite";
import moment from "moment";

class UserFollowups extends Component {
  state = {};
  render() {
    var { data } = this.props;
    return (
      <div>
        <List size="lg" hover>
          {data.customer_followups.map((followup, index) => {
            return (
              <List.Item
                key={index}
                index={index}
                onClick={() => this.props.selectCustomer(followup.customer)}
              >
                <strong>
                  Shop name: {followup.customer_name} ({followup.customer_id})
                </strong>{" "}
                <br />
                <strong>
                  Contact:{" "}
                  <a href={`tel:${followup.phone}`}>{followup.phone}</a>
                </strong>{" "}
                <br />
                <Tag size="xs">&nbsp;{followup.mode}&nbsp;</Tag>
                <br /> <i>{followup.remarks}</i>
                <br />
                <Tag>Created: {moment(followup.created_at).fromNow()}</Tag>
                <br />
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

export default UserFollowups;
