import Cookies from "js-cookie";
import React, { Component } from "react";
import { Button, Drawer, Input, InputNumber, InputPicker, Modal } from "rsuite";
import constants from "../../../constants";

const paymentTypes = ["Cash", "UPI"];

class SalesPayments extends Component {
  state = {
    drawer: false,
    modal: false,
    dummy: {},
    loading: false,
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  componentDidMount = () => {
    this.setDummy();
  };

  drawer = (drawer = true) => {
    this.setState({ drawer });
  };

  modal = (modal = true) => {
    if (modal && this.props.balanceAmount == 0) {
      window.alert("Paid Full");
      return;
    }
    this.setState({ modal });
    this.setDummy();
  };

  setDummy = () => {
    this.setState({
      dummy: {
        note: "",
        amount: this.props.balanceAmount,
        type: "Cash",
        sale_id: this.props.sale.id,
      },
    });
  };

  changeDummy = (where, what) => {
    var { dummy } = this.state;
    var { balanceAmount } = this.props;
    if (where == "amount" && what > balanceAmount) {
      return;
    }
    dummy[where] = what;
    this.setState({ dummy });
  };

  create = () => {
    this.setLoading();
    fetch(constants.url + `payments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(this.state.dummy),
    })
      .then((response) => response.json())
      .then((saleItem) => {
        console.log(saleItem);

        this.props.refresh();
        this.modal(false);
        this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  render() {
    var { drawer, modal, dummy } = this.state;
    var { sale } = this.props;

    return (
      <div>
        <Button block onClick={this.drawer} appearance="primary" color="green">
          PAYMENTS
        </Button>
        <Drawer
          open={drawer}
          onClose={() => this.drawer(false)}
          placement="bottom"
          size="lg"
        >
          <Drawer.Header>
            <Drawer.Title>Payments ({sale.id})</Drawer.Title>
            <Drawer.Actions>
              <Button
                onClick={this.modal}
                appearance="primary"
                color="yellow"
                disabled={this.state.loading}
              >
                Create
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            {sale.payments.length == 0 && <span>No Payments</span>}

            {sale.payments.map((payment) => {
              return (
                <div className="payment-item">
                  <div className="amount">{payment.amount}</div>
                  <div className="details">
                    <strong>Note: {payment.note}</strong>
                    <span>{payment.created_at}</span>
                    <span>Type: {payment.type}</span>
                    <span>Payment ID {payment.id}</span>
                  </div>
                </div>
              );
            })}
          </Drawer.Body>
        </Drawer>

        <Modal open={modal} onClose={() => this.modal(false)}>
          <Modal.Header>
            <Modal.Title>Create Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Payment Notes</label>
            <Input
              value={dummy.note}
              onChange={(value) => this.changeDummy("note", value)}
              block
            />
            <br />
            <label>Amount</label>
            <InputNumber
              value={dummy.amount}
              onChange={(value) => this.changeDummy("amount", value)}
              block
            />
            <br />
            <label>Payment Type</label>
            <InputPicker
              data={paymentTypes.map((t) => ({ label: t, value: t }))}
              value={dummy.type}
              onChange={(value) => this.changeDummy("type", value)}
              block
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.create}
              appearance="primary"
              disabled={this.state.loading}
            >
              Save
            </Button>
            <Button onClick={() => this.modal(false)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default SalesPayments;
