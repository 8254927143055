import React, { Component } from "react";
import { List } from "rsuite";

class UserRoutes extends Component {
  state = {};
  render() {
    return (
      <div>
        <List>
          {this.props.data?.routes?.map((route) => (
            <List.Item>{route.route_name}</List.Item>
          ))}
        </List>
      </div>
    );
  }
}

export default UserRoutes;
