import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  Badge,
  Button,
  Col,
  DateRangePicker,
  Divider,
  Drawer,
  Grid,
  Input,
  InputGroup,
  InputNumber,
  List,
  Modal,
  Row,
  Stack,
  Tag,
  Toggle,
} from "rsuite";
import constants, {
  alertError,
  alertInfo,
  getProductImage,
  isValid,
} from "../../constants";
import CustomerView from "./mobile/customer-view";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";
import SalesQtyList from "../../Modules/SalesQtyList";
import TempCustomerMaker from "../../Modules/TempCusomterMaker";

class StoreManager extends Component {
  state = {
    data: null,
    selectedSales: [],
    selected: null,
    selectedCustomerIndex: 0,
    selectedTemp: null,
    loading: false,
    dateRange: [new Date(), new Date()],
    drawer: false,
    tempDrawer: false,
    showRzPaysOnly: false,
    showTempWithPhone: false,
    editTempCustomer: false,
    minifyInfo: false,
  };

  drawer = (drawer = true, type = null) => {
    this.setState({ drawer, selectedSales: type ? this.getSales(type) : [] });
  };

  tempDrawer = (tempDrawer = true) => {
    this.setState({ tempDrawer, showRzPaysOnly: false });
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  componentDidMount = () => {
    this.loadData(this.state.dateRange);
    window.refreshFunction = () => this.loadData(this.state.dateRange);
  };

  loadData = (dateRange) => {
    this.setState({ dateRange });

    const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
    const endDate = moment(dateRange[1]).format("YYYY-MM-DD");

    const url =
      constants.url +
      "store-data" +
      `?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
        endDate
      )}`;

    this.setLoading();
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setLoading(false);
        this.setState({ data });
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  confirmOrder = (tempCustomer, runAfter) => {
    const url = constants.url + "confirm-order/" + tempCustomer.id;

    this.setLoading();
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setLoading(false);
        alertInfo("Customer & Sale Created, Click to view", 5000, () => {
          this.setState({ selected: data });
        });
        runAfter();
      })
      .catch((error) => {
        console.error(error);
        alertError("Unable to  Confirm Order");
        this.setLoading(false);
      });
  };

  getSales = (status) => {
    return this.state?.data?.sales?.filter((sale) => sale?.status === status);
  };

  selectNextCustomer = () => {
    var { selectedCustomerIndex, selectedSales } = this.state;
    if (selectedCustomerIndex < selectedSales.length - 1) {
      selectedCustomerIndex = selectedCustomerIndex + 1;
      this.setState({
        selectedCustomerIndex,
        selected: selectedSales[selectedCustomerIndex],
      });
    }
  };

  selectPreviousCustomer = () => {
    var { selectedCustomerIndex, selectedSales } = this.state;
    if (selectedCustomerIndex > 0) {
      selectedCustomerIndex = selectedCustomerIndex - 1;
      this.setState({
        selectedCustomerIndex,
        selected: selectedSales[selectedCustomerIndex],
      });
    }
  };

  verifyPayment = (tempCustomer) => {
    this.setLoading();
    fetch(constants.url + "pay/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_id: JSON.parse(tempCustomer?.rz_pay?.data)?.id,
        order_id: tempCustomer?.rz_pay?.order_id,
        uid: tempCustomer.uid,
      }),
    })
      .then((verificationResponse) => verificationResponse.json())
      .then((verificationData) => {
        // Handle payment verification on the server
        if (verificationData?.status == "success") {
          alertInfo("Payment verified, sale created");
          this.loadData(this.state.dateRange);
        } else {
          alertError("Payment verification failed");
          this.setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        alertError("Payment verification failed");
        this.setLoading(false);
      });
  };

  changeSelectedTemp = (where, what) => {
    var { selectedTemp } = this.state;
    selectedTemp[where] = what;
    this.setState({ selectedTemp });
  };

  saveTempCustomer = () => {
    var { selectedTemp } = this.state;

    this.setLoading();
    fetch(constants.url + `update-temp/${selectedTemp.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({
        name: selectedTemp?.name,
        phone: selectedTemp?.phone,
        address: selectedTemp?.address,
        postal_pin: selectedTemp?.postal_pin,
        remarks: selectedTemp?.remarks,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          selectedTemp: data.tempCustomer,
          editTempCustomer: false,
        });
        this.setLoading(false);
        alertInfo("TempCustomer Updated");
      })
      .catch((error) => {
        console.error(error);
        alertError("Unable to update temp customer!");
        this.setLoading(false);
      });
  };

  render() {
    var { selectedTemp } = this.state;
    if (this.state.selected) {
      return (
        <CustomerView
          data={this.state.selected.customer}
          back={() => this.setState({ selected: null })}
          currentLocation={this.props.currentLocation}
          sessionCheck={this.props.sessionCheck}
          selectedCustomerIndex={this.state.selectedCustomerIndex}
          selectNextCustomer={this.selectNextCustomer}
          selectPreviousCustomer={this.selectPreviousCustomer}
        />
      );
    }
    return (
      <div>
        <InputGroup>
          <DateRangePicker
            value={this.state.dateRange}
            onChange={this.loadData}
            cleanable={false}
            loading={this.state.loading}
            showOneCalendar
          />
          <InputGroup.Button
            onClick={() => this.loadData(this.state.dateRange)}
          >
            Refresh
          </InputGroup.Button>
        </InputGroup>

        <br />
        <Grid fluid>
          <Row className="show-grid">
            <Col xs={12}>
              <Button
                block
                onClick={() => this.drawer(true, "init")}
                appearance="ghost"
                color="orange"
                size="lg"
              >
                Init orders - {this.getSales("init")?.length}
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                onClick={() => this.drawer(true, "pending")}
                appearance="ghost"
                color="yellow"
                size="lg"
              >
                Pending orders - {this.getSales("pending")?.length}
              </Button>
            </Col>
          </Row>
          <br />
          <Row className="show-grid">
            <Col xs={12}>
              <Button
                block
                onClick={() => this.drawer(true, "confirmed")}
                appearance="ghost"
                color="blue"
                size="lg"
              >
                Confirmed orders - {this.getSales("confirmed")?.length}
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                onClick={() => this.drawer(true, "delivery")}
                appearance="ghost"
                color="yellow"
                size="lg"
              >
                Delivery orders - {this.getSales("delivery")?.length}
              </Button>
            </Col>
          </Row>
          <br />
          <Row className="show-grid">
            <Col xs={12}>
              <Button
                block
                onClick={() => this.drawer(true, "complete")}
                appearance="ghost"
                color="green"
                size="lg"
              >
                Completed orders - {this.getSales("complete")?.length}
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                onClick={() => this.drawer(true, "cancelled")}
                appearance="ghost"
                color="red"
                size="lg"
              >
                Cancelled orders - {this.getSales("cancelled")?.length}
              </Button>
            </Col>
          </Row>
          <br />
          <Row className="border big-text">
            <Col xs={16}>Temp Customers</Col>
            <Col xs={8}>
              <Button onClick={this.tempDrawer}>
                &nbsp;&nbsp;&nbsp;
                <strong>{this.state.data?.temp_customers?.length}</strong>
                &nbsp;&nbsp;&nbsp;
              </Button>
            </Col>
          </Row>
          <br />
          <Row className="border big-text">
            <Col xs={24}>
              <TempCustomerMaker
                onCreate={(tempCustomer) =>
                  this.setState({ selectedTemp: tempCustomer })
                }
              />
            </Col>
          </Row>
          <br />

          <Row className="border big-text">
            <Col xs={16}>Number of sales</Col>
            <Col xs={8}>
              <strong>{this.state.data?.sales?.length}</strong>
            </Col>
          </Row>
          <Row className="border big-text">
            <Col xs={16}>Total value</Col>
            <Col xs={8}>
              <strong>
                {this.state.data?.sales
                  ?.reduce(
                    (sum, sale) =>
                      sum +
                      (sale.status == "confirmed" ||
                      sale.status == "delivery" ||
                      sale.status == "complete"
                        ? parseFloat(sale?.total_amount || 0) +
                          parseFloat(sale?.channel_rate || 0) +
                          parseFloat(sale?.cod_fee || 0)
                        : 0),
                    0
                  )
                  .toFixed(2)}
              </strong>
            </Col>
          </Row>
          <Row className="border big-text">
            <Col xs={16}>Received payments</Col>
            <Col xs={8}>
              <strong>
                {this.state.data?.sales
                  ?.reduce((sum, sale) => {
                    const paymentsAmount = sale.payments.reduce(
                      (total, payment) => total + parseFloat(payment.amount),
                      0
                    );
                    return sum + paymentsAmount;
                  }, 0)
                  .toFixed(2)}{" "}
                {/* Assuming you want to round the result to two decimal places */}
              </strong>
            </Col>
          </Row>
        </Grid>

        <Drawer
          placement="bottom"
          open={this.state.drawer}
          onClose={() => this.drawer(false)}
          size="full"
        >
          <Drawer.Header>
            {this.state?.selectedSales[0]?.status} orders
            <SalesQtyList sales={this.state?.selectedSales} />
          </Drawer.Header>
          <Drawer.Body>
            <List hover>
              {this.state.selectedSales.map((sale, index) => {
                return (
                  <List.Item
                    key={index}
                    onClick={() =>
                      this.setState({
                        selected: sale,
                        selectedCustomerIndex: index,
                      })
                    }
                  >
                    <strong>
                      #{sale.customer.id} - {sale.customer.person_name}
                    </strong>
                    <br />
                    <span>
                      Order# :{sale.id} - {moment(sale.date).fromNow()}
                    </span>
                    <br />
                    <span>Amount :{sale.total_amount}</span>
                    <br />
                    {sale.cod ? (
                      <Tag color="yellow">Cash on delivery</Tag>
                    ) : (
                      <Tag color="blue">Prepaid</Tag>
                    )}
                  </List.Item>
                );
              })}
            </List>
          </Drawer.Body>
        </Drawer>

        {/* Temp customers drawer */}
        <Drawer
          placement="bottom"
          open={this.state.tempDrawer}
          onClose={() => this.tempDrawer(false)}
          size="full"
        >
          <Drawer.Header>
            <div>
              <Stack justifyContent="space-between">
                <span>
                  <strong>Temp - </strong>
                  <Toggle
                    checked={this.state.showRzPaysOnly}
                    checkedChildren="Paid"
                    unCheckedChildren="Unpaid/Paid"
                    onChange={(value) =>
                      this.setState({ showRzPaysOnly: value })
                    }
                  />
                  <Toggle
                    checked={this.state.showTempWithPhone}
                    checkedChildren="With Phone"
                    unCheckedChildren="W/O Phone"
                    onChange={(value) =>
                      this.setState({ showTempWithPhone: value })
                    }
                  />
                </span>
              </Stack>
            </div>
          </Drawer.Header>
          <Drawer.Body>
            <List hover>
              {this.state.data?.temp_customers
                ?.filter((item) => {
                  return (
                    (!this.state.showRzPaysOnly || item?.rz_pay !== null) &&
                    (!this.state.showTempWithPhone || item?.phone !== null)
                  );
                })
                .map((item, index) => {
                  return (
                    <List.Item key={index}>
                      <Stack justifyContent="space-between">
                        <div
                          onClick={() => this.setState({ selectedTemp: item })}
                        >
                          <strong>Name: {item.name ?? "-"}</strong>{" "}
                          <Tag>{moment(item.created_at).fromNow()}</Tag> <br />{" "}
                          {item.customer && (
                            <>
                              <Tag color="orange">Existing Customer</Tag>
                              <br />
                            </>
                          )}
                          Address: {item.address ?? "-"} |{" "}
                          {item.postal_pin ?? "-"}| {item.phone ?? "-"}
                          <br />
                          Amount: {item.total_amount} | Channel:{" "}
                          {item.channel_rate} | {item.cod ? "COD" : "PPD"}{" "}
                          <br />
                          {item.remarks && (
                            <Tag color="blue" size="sm">
                              {item.remarks} |{" "}
                              {moment(item.updated_at).fromNow()}
                            </Tag>
                          )}
                        </div>
                        {item?.rz_pay ? (
                          <Button
                            appearance="link"
                            color={
                              item.rz_pay?.captured == false
                                ? "orange"
                                : "green"
                            }
                            onClick={() => this.verifyPayment(item)}
                            disabled={this.state.loading}
                          >
                            Verify Payment
                          </Button>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </List.Item>
                  );
                })}
            </List>
          </Drawer.Body>
        </Drawer>

        {/* Temp customer view modal */}
        <Drawer
          closeButton={false}
          open={selectedTemp}
          onClose={() => this.setState({ selectedTemp: null })}
          size="full"
          placement="bottom"
        >
          <Drawer.Header>
            <Drawer.Title>
              <img
                src={constants.sales_mart}
                style={{ width: "auto", height: "40px" }}
              />{" "}
              &nbsp;&nbsp;<strong></strong>
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <Divider>Customer Information</Divider>
            {selectedTemp?.customer && (
              <p>
                <strong>Customer Exist:</strong>
                <Button
                  onClick={() => this.setState({ selected: selectedTemp })}
                  appearance="primary"
                  color="green"
                  size="xs"
                >
                  {selectedTemp?.customer?.person_name}
                </Button>
              </p>
            )}
            <p>
              <strong>Name: {selectedTemp?.name}</strong>
            </p>
            <p>
              <strong>Address:</strong> {selectedTemp?.address}
            </p>
            <p>
              <strong>PIN:</strong> {selectedTemp?.postal_pin}
            </p>
            <p>
              <strong>Phone:</strong>{" "}
              <a href={`tel:${selectedTemp?.phone}`}>{selectedTemp?.phone}</a>
            </p>
            {!this.state.minifyInfo && (
              <p>
                <strong>Remarks:</strong>
                <Input
                  disabled={this.state.loading}
                  as="textarea"
                  rows={3}
                  plaintext={!this.state.editTempCustomer}
                  value={selectedTemp?.remarks}
                  onChange={(value) =>
                    this.changeSelectedTemp("remarks", value)
                  }
                />
                <br />
                {this.state.editTempCustomer ? (
                  <Button
                    appearance="link"
                    onClick={this.saveTempCustomer}
                    disabled={this.state.loading}
                    size="xs"
                  >
                    Save Remarks
                  </Button>
                ) : (
                  <Button
                    appearance="link"
                    onClick={() => this.setState({ editTempCustomer: true })}
                    disabled={this.state.loading}
                    size="xs"
                  >
                    Edit Remarks
                  </Button>
                )}
              </p>
            )}
            <p>
              <strong>Date:</strong>
              {moment(selectedTemp?.created_at).format("MMMM DD, YYYY")}
            </p>
            {!this.state.minifyInfo && (
              <p>
                <strong>Updated:</strong>
                {moment(selectedTemp?.updated_at).fromNow()}
              </p>
            )}
            <Divider>Order Items</Divider>
            <List>
              {selectedTemp?.temp_items?.map((item, i) => (
                <List.Item key={i}>
                  <Stack>
                    <img
                      src={getProductImage(item?.product)}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    />
                    <span>
                      <strong>
                        {item?.product?.name} [QTY: {item?.quantity} X Rate ₹
                        {item?.unit_price}]
                      </strong>
                      <br />
                      {!this.state.minifyInfo && (
                        <Tag>Stock: {item?.product?.current_stock}</Tag>
                      )}
                    </span>
                  </Stack>
                </List.Item>
              ))}
            </List>

            <Divider>Order Details</Divider>
            <p>
              <strong>Type:</strong>
              {selectedTemp?.cod ? "Cash on Delivery" : "Pre Paid"}
            </p>
            <p>
              <strong>Amount:</strong>₹{selectedTemp?.total_amount}
            </p>
            <p>
              <strong>Channel Rate:</strong>
              {selectedTemp?.channel_rate == 0
                ? "FREE"
                : `₹${selectedTemp?.channel_rate}`}
            </p>
            <p>
              <strong>Cash on Delivery Charge:</strong>₹{selectedTemp?.cod_fee}
            </p>
            <p>
              <strong>Total:</strong>₹
              {parseFloat(selectedTemp?.total_amount) +
                parseFloat(selectedTemp?.channel_rate) +
                parseFloat(selectedTemp?.cod_fee)}
            </p>
            <br />
            {!this.state.minifyInfo && (
              <TempCustomerMaker
                tempCustomer={selectedTemp}
                onCreate={(tempCustomer) =>
                  this.setState({ selectedTemp: tempCustomer })
                }
              />
            )}

            {this.state.minifyInfo && (
              <strong>
                DELIVERY PARTNER{" "}
                <img
                  src={constants.channel_ecom}
                  style={{ width: "auto", height: "15px" }}
                />
              </strong>
            )}
            <Divider />
            <Stack>
              <Toggle
                checked={this.state.minifyInfo}
                checkedChildren="mini"
                unCheckedChildren="mini"
                onChange={(value) => this.setState({ minifyInfo: value })}
                size="xs"
              />
              {!this.state.editTempCustomer &&
                !this.state.minifyInfo &&
                selectedTemp?.temp_items?.length > 0 &&
                isValid(selectedTemp?.name, 2) &&
                isValid(selectedTemp?.address, 5) &&
                isValid(selectedTemp?.phone, 10) &&
                isValid(selectedTemp?.postal_pin, 2) && (
                  <Button
                    onClick={() =>
                      this.confirmOrder(selectedTemp, () => {
                        this.setState({ selectedTemp: null });
                        this.loadData(this.state.dateRange);
                      })
                    }
                    appearance="primary"
                    color={selectedTemp?.customer ? "red" : "green"}
                    size="sm"
                    disabled={this.state.loading}
                  >
                    {selectedTemp?.customer ? "Create Sale" : "Confirm Order"}
                  </Button>
                )}
              {!this.state.minifyInfo && (
                <Button
                  onClick={() => this.setState({ selectedTemp: null })}
                  appearance="subtle"
                  disabled={this.state.loading}
                >
                  Close
                </Button>
              )}
            </Stack>
          </Drawer.Body>
        </Drawer>
      </div>
    );
  }
}

export default StoreManager;
