import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  DatePicker,
  Grid,
  Input,
  Loader,
  Panel,
  Row,
  SelectPicker,
  Stack,
} from "rsuite";
import constants, { getListFromArray } from "../../constants";

class CandidateView extends Component {
  state = {
    data: this.props.data ?? null,
    edit: this.props.data == null,
    loading: false,
  };

  changeData = (name, value) => {
    var { data, edit } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  save = () => {
    var { data } = this.state;
    var newForm = this.props.data == null;
    var url = !newForm
      ? constants.url + "candidates/" + data.id
      : constants.url + "candidates";
    this.setLoading();
    fetch(url, {
      method: newForm ? "POST" : "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response_data) => {
        console.log(response_data);
        // this.viewCustomer();
        this.setState({
          data: response_data,
          edit: false,
        });
        this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
        this.setState({ edit: false });
        alert("Data Error, check contents");
      });
  };
  render() {
    var { data, loading, edit } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <div>
        <Panel
          bordered
          header={
            <Stack justifyContent="space-between">
              <h4>{data == null ? "Create" : "Edit"} Candidate</h4>
              <ButtonGroup>
                <Button onClick={this.props.back}>Back</Button>
                <Button
                  appearance="primary"
                  onClick={() => {
                    if (edit) {
                      this.save();
                    } else {
                      this.setState({ edit: true });
                    }
                  }}
                >
                  {edit ? "Save" : "Edit"}
                </Button>
              </ButtonGroup>
            </Stack>
          }
        >
          <Grid fluid>
            <Row>
              <Col xs={24} sm={24} md={8}>
                <Input
                  placeholder="Name"
                  value={data?.name}
                  onChange={(value) => this.changeData("name", value)}
                  plaintext={!this.state.edit}
                />
                <Input
                  placeholder="Phone"
                  value={data?.phone}
                  onChange={(value) => this.changeData("phone", value)}
                  plaintext={!this.state.edit}
                />
                <Input
                  placeholder="Email"
                  value={data?.email}
                  onChange={(value) => this.changeData("email", value)}
                  plaintext={!this.state.edit}
                />
              </Col>

              <Col xs={24} sm={24} md={8}>
                <DatePicker
                  placeholder="Date of birth"
                  value={
                    typeof data?.dob === "string" &&
                    !isNaN(Date.parse(data?.dob))
                      ? new Date(data?.dob)
                      : data?.dob
                  }
                  onChange={(value) => this.changeData("dob", value)}
                  plaintext={!this.state.edit}
                />

                <Input
                  placeholder="Education"
                  value={data?.education}
                  onChange={(value) => this.changeData("education", value)}
                  plaintext={!this.state.edit}
                />
                <Input
                  placeholder="Experience"
                  value={data?.experience}
                  onChange={(value) => this.changeData("experience", value)}
                  plaintext={!this.state.edit}
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <SelectPicker
                  placeholder="Marital status"
                  data={[
                    { label: "Married", value: "Married" },
                    { label: "Single", value: "Single" },
                    { label: "Other", value: "Other" },
                  ]}
                  value={data?.marital_status}
                  onChange={(value) => this.changeData("marital_status", value)}
                  plaintext={!this.state.edit}
                />
                <SelectPicker
                  placeholder="Gender"
                  data={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                    { label: "Other", value: "Other" },
                  ]}
                  value={data?.gender}
                  onChange={(value) => this.changeData("gender", value)}
                  plaintext={!this.state.edit}
                />
                <Input
                  placeholder="Location"
                  value={data?.location}
                  onChange={(value) => this.changeData("location", value)}
                  plaintext={!this.state.edit}
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Input
                  as="textarea"
                  rows={3}
                  placeholder="Address"
                  value={data?.address}
                  onChange={(value) => this.changeData("address", value)}
                  plaintext={!this.state.edit}
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Input
                  as="textarea"
                  rows={3}
                  placeholder="Remarks"
                  value={data?.remarks}
                  onChange={(value) => this.changeData("remarks", value)}
                  plaintext={!this.state.edit}
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <SelectPicker
                  placeholder="Status"
                  data={getListFromArray([
                    "Pending Review",
                    "Interview Scheduled",
                    "Shortlisted",
                    "Selected",
                    "Rejected",
                  ])}
                  value={data?.status}
                  onChange={(value) => this.changeData("status", value)}
                  plaintext={!this.state.edit}
                />
              </Col>
            </Row>
          </Grid>
        </Panel>
      </div>
    );
  }
}

export default CandidateView;
