import React, { Component } from "react";
import { List, Tag, Badge } from "rsuite";
import moment from "moment";

class UserLogs extends Component {
  state = {};
  render() {
    var { data } = this.props;
    return (
      <div>
        <List size="lg">
          {data.user_logs.map((log, index) => {
            return (
              <List.Item key={index} index={index}>
                <strong>Login:</strong> {log.login_at}{" "}
                {log.login_at == null && (
                  <Tag color="red">Incomplete Login</Tag>
                )}
                <br />
                <strong>Logout:</strong> {log.logout_at}{" "}
                {log.logout_at == null && (
                  <Tag color="red">Incomplete Logout</Tag>
                )}
                <br />
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

export default UserLogs;
