import React, { Component } from "react";
import { List, Tag, Badge, Drawer } from "rsuite";
import moment from "moment";
import HomeLeadView from "../home-lead-view";
import LeadListItem from "../../../../components/LeadListItem";

class UserLeads extends Component {
  state = {
    selectedLead: null,
  };
  render() {
    var { data } = this.props;
    return (
      <div>
        <List size="lg">
          {data.lead_spots.map((lead, index) => {
            return (
              <List.Item key={index} index={index}>
                <LeadListItem
                  lead={lead}
                  onClick={() => {
                    this.setState({ selectedLead: lead });
                  }}
                />
              </List.Item>
            );
          })}
        </List>
        {/* Lead & Custoemr View Drawer */}
        {this.state.selectedLead != null ? (
          <Drawer
            placement="bottom"
            size="full"
            open={this.state.selectedLead != null}
            onClose={() =>
              this.setState({
                selectedLead: null,
              })
            }
          >
            <Drawer.Header>
              <Drawer.Title>View Lead</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <HomeLeadView
                lead={this.state.selectedLead}
                back={() => this.setState({ selectedLead: null })}
                currentLocation={this.props.currentLocation}
                sessionCheck={this.props.sessionCheck}
                setView={(selectedLead) => this.setState({ selectedLead })}
                loadLeads={() => {}}
              />
            </Drawer.Body>
          </Drawer>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default UserLeads;
