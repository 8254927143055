import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  Avatar,
  CheckPicker,
  DateRangePicker,
  Divider,
  InputPicker,
  Stack,
} from "rsuite";
import constants, { getUserImage } from "../constants";
import { loadUsers } from "../Loaders/GeneralLoder";
import CustomLoader from "../Widgets/CustomLoader";

const filterTypes = [
  "customers",
  "sales",
  "sales_returns",
  "payments",
  "lead_spots",
  "lead_followups",
  "customer_followups",
  "lead_followups_confirmed",
];

const sortTypes = [
  "customers",
  "sales",
  "sales_returns",
  "lead_spots",
  "own_lead_followups",
  "customer_followups",
];

class TeamProgress extends Component {
  state = {
    date: [new Date(), new Date()],
    users: [],
    selectedUsers: [],
    data: [],
    filters: [],
    sortType: sortTypes[1],
    loading: false,
  };

  setLoading = (loading = true) => this.setState({ loading });

  componentDidMount = () => {
    this.setLoading();
    loadUsers((users) =>
      this.setState({ users }, () => this.setLoading(false))
    );
  };

  loadData = () => {
    var { date, selectedUsers } = this.state;
    if (selectedUsers.length > 0) {
      console.log("loading data");
      this.setLoading();
      const startDate = moment(date[0]).format("YYYY-MM-DD");
      const endDate = moment(date[1]).format("YYYY-MM-DD");

      // Construct the request body with the parameters
      const requestBody = {
        startDate: startDate,
        endDate: endDate,
        users: selectedUsers,
      };

      fetch(constants.url + "user-progress", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(requestBody), // Send the parameters in the request body
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.setState({ data });
          this.setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  render() {
    var { filters } = this.state;
    return (
      <div>
        <CustomLoader full text="Loading data.." loading={this.state.loading} />
        <Divider>Team Progress</Divider>
        <div style={{ maxWidth: "90vw !important", overflow: "hidden" }}>
          <DateRangePicker
            placeholder="Select Dates"
            onChange={(date) => this.setState({ date })}
            placement="auto"
            value={this.state.date}
            size="sm"
            showOneCalendar
            block
          />
          <CheckPicker
            size="sm"
            block
            label="Filters"
            data={filterTypes.map((filter) => ({
              label: filter,
              value: filter,
            }))}
            loading={this.state.loading}
            value={this.state.filters}
            onChange={(filters) => {
              this.setState({ filters });
            }}
          />

          <CheckPicker
            size="sm"
            block
            label="Users"
            data={this.state.users.map((user) => ({
              label: user.name,
              value: user.id,
            }))}
            loading={this.state.loading}
            value={this.state.selectedUsers}
            onChange={(selectedUsers) => {
              this.setState({ selectedUsers });
            }}
            onExited={this.loadData}
          />

          <InputPicker
            block
            value={this.state.sortType}
            onChange={(sortType) => this.setState({ sortType })}
            data={sortTypes.map((sort) => ({ label: sort, value: sort }))}
          />
        </div>

        <br />

        {this.state.data
          .sort(
            (a, b) =>
              (b[this.state.sortType]?.length ?? 0) -
              (a[this.state.sortType]?.length ?? 0)
          )
          .map((user, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: index == 0 ? "#FFF4A0" : "#f9f9f9",
                borderRadius: "8px",
                padding: "15px",
                marginBottom: "10px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  border: "3px solid #3498db",
                  padding: "3px",
                  objectFit: "cover",
                }}
                src={getUserImage(user)}
                alt={user.name}
              />

              <div style={{ marginLeft: "15px", flex: 1 }}>
                <strong style={{ fontSize: "18px", color: "#2c3e50" }}>
                  {user.name}
                </strong>
                <div
                  style={{
                    marginTop: "8px",
                    fontSize: "14px",
                    color: "#7f8c8d",
                  }}
                >
                  {filters.includes("customers") && (
                    <div>
                      Customers: <strong>{user?.customers?.length ?? 0}</strong>
                    </div>
                  )}
                  {filters.includes("sales") && (
                    <div>
                      Sales: <strong>{user?.sales?.length ?? 0}</strong>
                    </div>
                  )}
                  {filters.includes("sales_returns") && (
                    <div>
                      Sales Returns:{" "}
                      <strong>{user?.sales_returns?.length ?? 0}</strong>
                    </div>
                  )}
                  {filters.includes("payments") && (
                    <div>
                      Payments:{" "}
                      <strong>
                        {user?.payments?.reduce(
                          (a, b) => a + parseFloat(b.amount),
                          0
                        )}
                      </strong>
                    </div>
                  )}
                  {filters.includes("lead_spots") && (
                    <div>
                      Leads: <strong>{user?.lead_spots?.length ?? 0}</strong>
                    </div>
                  )}
                  {filters.includes("lead_followups") && (
                    <div>
                      Lead Followups:{" "}
                      <strong>{user?.own_lead_followups?.length ?? 0}</strong>
                    </div>
                  )}
                  {filters.includes("lead_followups") && (
                    <div>
                      Events / Visits:{" "}
                      <strong>
                        {user?.own_lead_followups?.filter(
                          (lf) => lf.event === 1
                        ).length ?? 0}
                      </strong>
                    </div>
                  )}
                  {filters.includes("lead_followups_confirmed") && (
                    <div>
                      Confirmed Visits:{" "}
                      <strong>
                        {user?.own_lead_followups?.filter(
                          (lf) => lf.event_type === "Direct Visit (Confirmed)"
                        ).length ?? 0}
                      </strong>
                    </div>
                  )}
                  {filters.includes("customer_followups") && (
                    <div>
                      Customer Followups:{" "}
                      <strong>{user?.customer_followups?.length ?? 0}</strong>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default TeamProgress;
