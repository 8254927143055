import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  Button,
  Drawer,
  Input,
  List,
  Loader,
  Modal,
  Stack,
  Toggle,
} from "rsuite";
import constants, { alertError, alertInfo } from "../../constants";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";

class ProductCategories extends Component {
  state = {
    createModal: false,
    categories: this.props.categories,
    loading: false,
    dummy: {},
    drawer: false,
  };

  drawer = (drawer = true) => {
    this.setState({ drawer });
  };

  componentDidMount = () => {
    this.setDummy();
  };

  setDummy = (selected = null) => {
    this.setState({
      dummy: selected ?? {
        name: "",
        description: "",
        public: 0,
      },
    });
  };

  changeDummy = (where, what) => {
    var { dummy } = this.state;
    dummy[where] = what;
    this.setState({ dummy });
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  createModal = (createModal = true) => {
    this.setState({ createModal });
    if (!createModal) {
      this.setDummy();
    }
  };

  save = () => {
    var { dummy } = this.state;
    //create or edit if dumy.id
    this.setLoading();
    fetch(constants.url + `categories` + (dummy.id ? `/${dummy.id}` : ``), {
      method: dummy.id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(dummy),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setLoading(false);
        this.props.loadCategories();
        this.createModal(false);
        alertInfo("Saved");
      })
      .catch((error) => {
        console.error(error);
        alertError("Can't create this category");
        this.setLoading(false);
      });
  };
  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    var { categories, selected, dummy } = this.state;
    return (
      <>
        <Button onClick={this.drawer}>Categories</Button>

        <Drawer
          placement="bottom"
          open={this.state.drawer}
          onClose={() => this.drawer(false)}
        >
          <Drawer.Header>
            <Drawer.Title>Product Categories</Drawer.Title>
            <Drawer.Actions>
              <Button onClick={this.createModal}>Create</Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <List>
              {categories.map((c, index) => {
                return (
                  <List.Item
                    onClick={() => {
                      this.createModal();
                      this.setDummy(c);
                    }}
                  >
                    <Stack justifyContent="space-between">
                      {c.name}
                      <Toggle
                        readonly
                        checked={c.public}
                        checkedChildren={<CheckIcon />}
                        unCheckedChildren={<CloseIcon />}
                      />
                    </Stack>
                  </List.Item>
                );
              })}
            </List>
          </Drawer.Body>
        </Drawer>

        <Modal
          open={this.state.createModal}
          onClose={() => this.createModal(false)}
        >
          <Modal.Header>
            <Modal.Title>
              {dummy.id ? "Edit " + dummy.name : "Create Category"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Name</label>
            <Input
              value={dummy.name}
              onChange={(value) => this.changeDummy("name", value)}
            />
            <br />
            <label>Description</label>
            <Input
              as="textarea"
              rows={4}
              value={dummy.description}
              onChange={(value) => this.changeDummy("description", value)}
            />
            <br />
            <Stack justifyContent="space-between">
              Public
              <Toggle
                checked={dummy.public}
                checkedChildren={<CheckIcon />}
                unCheckedChildren={<CloseIcon />}
                onChange={(value) => this.changeDummy("public", value)}
              />
            </Stack>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.save} appearance="primary">
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ProductCategories;
