import logo from "./images/logo.png";
import full_logo from "./images/old_logo.png";
import logoIcon from "./images/se_icon.png";
import channel_ecom from "./images/channel_ecom.png";
import sales_mart from "./images/sales_mart.png";
import channel_dtdc from "./images/channel_dtdc.png";
import razorpay from "./images/razorpay.png";
import google from "./images/google.png";
import map_marker_blue from "./images/map-marker-blue.png";
import map_marker_red from "./images/map-marker-red.png";
import map_marker_orange from "./images/map-marker-orange.png";

import marker_black from "./images/markers/marker_black.png";
import marker_white from "./images/markers/marker_white.png";
import marker_blue from "./images/markers/marker_blue.png";
import marker_green from "./images/markers/marker_green.png";
import marker_pink from "./images/markers/marker_pink.png";
import marker_red from "./images/markers/marker_red.png";
import marker_yellow from "./images/markers/marker_yellow.png";

import marker_black_time from "./images/markers/marker_black_time.png";
import marker_white_time from "./images/markers/marker_white_time.png";
import marker_blue_time from "./images/markers/marker_blue_time.png";
import marker_green_time from "./images/markers/marker_green_time.png";
import marker_pink_time from "./images/markers/marker_pink_time.png";
import marker_red_time from "./images/markers/marker_red_time.png";
import marker_yellow_time from "./images/markers/marker_yellow_time.png";

import map_arrow from "./images/map_arrow.png";

import imagePlaceholder from "./images/image-placeholder.webp";
import product_placeholder from "./images/product-placeholder.png";
import user_placeholder from "./images/user-image-placeholder.jpg";
import sound_1 from "./sounds/sound_1.mp3";
import sound_2 from "./sounds/sound_2.mp3";
import sound_3 from "./sounds/sound_3.mp3";
import sound_4_woosh from "./sounds/sound_4_woosh.mp3";
import { Message, toaster } from "rsuite";
import moment from "moment";

function getShopTypeName(id) {}

// const parent = "http://192.168.1.2:8000/";

var production = true;

//STAGING..
// production = false;

const parent_local = "http://localhost:8000/";
// const parent_local = "http://192.168.1.2:8000/";
const parent_live = "https://api.salesmart.in/";

const parent = production ? parent_live : parent_local;

const RAZOR_KEY_ID = "rzp_test_qdwepRw5gDPVto";
// const RAZOR_KEY_ID = "rzp_live_P271enwK6qSosV";

const constants = {
  // url: "http://localhost:5656/api/",
  // root_url: "http://localhost:5656/",
  // url: "https://sawari-api.stack.a2hosted.com/",
  // root_url: "https://sawari-api.stack.a2hosted.com/",
  // url: "https://api.stackenterprises.in/api/",
  url_parent: parent,
  url: parent + "api/",
  imagePath: parent + "storage/customer-images/",
  root_url: "https://sawari-api.herokuapp.com/",
  // map_api: "AIzaSyB8R5HSZM8XqkZnPSlFlDShG3xn3u3yaMw",
  // map_api: "AIzaSyD-DGfJYGW-ZYyssUoXlOj9BwzllSYBiVs",
  // map_api: "AIzaSyAL-5KFWqjKjjqLGVsqDAdcPax7RRTSwW8",
  map_api: "AIzaSyDIRIk_y6RFE0ZGZ0_DP91sQTgCPDO-6fc", //stackentgmail
  GOOGLE_CLIENT_ID:
    "91963764005-l8h2ou6gcqfet345va4n5s69ks0f87gn.apps.googleusercontent.com",
  RAZOR_KEY_ID,
  logo,
  full_logo,
  logoIcon,
  razorpay,
  google,
  map_marker_blue,
  map_marker_red,
  map_marker_orange,
  marker_black,
  marker_white,
  marker_blue,
  marker_green,
  marker_pink,
  marker_red,
  marker_yellow,
  map_arrow,
  channel_ecom,
  sales_mart,
  imagePlaceholder,
  LIST_MODE: 11,
  VIEW_MODE: 12,
  CREATE_MODE: 13,
  EDIT_MODE: 14,
  shopTypes: [
    { label: "Mini Mart", value: "1" },
    { label: "Super Market", value: "2" },
    { label: "Hyper Market", value: "3" },
    { label: "Electricals", value: "4" },
    { label: "Others", value: "5" },
    { label: "RBP", value: "6" },
    { label: "Online", value: "7" },
  ],
  salesStatuses: [
    { label: "Init", value: "init" },
    { label: "Pending", value: "pending" },
    { label: "Confirmed", value: "confirmed" },
    { label: "Delivery", value: "delivery" },
    { label: "Paid Cash", value: "paid-cash" },
    { label: "Paid UPI", value: "paid-upi" },
    { label: "Complete", value: "complete" },
    { label: "Cancelled", value: "cancelled" },
  ],
  event_types: [
    "Phone Call",
    "Direct Visit (Confirmed)",
    "Direct Visit (Not Confirmed)",
    "Random Visit",
    "Collect Information",
    "Other",
  ],
  followup_types: ["Phone call", "Direct Visit", "Message or Email", "Other"],
  followup_remarks: [
    "Has stock | Need to followup again",
    "More Interested | Frequent Followup",
    "Less interested | call disconnected during talk",
    "Less interested | dont want to talk",
    "Less interested | dont want to share contact number",
    "Dont have a phone",
    "Person not available",
    "Shared Details | Provided Catalogue",
    "Need Marketing Materials",
    "Not interested in our brand",
    "Pricing issue | Rate is high",
    "Shop Seems Closed",
    "Shop Fully Closed",
    "Shop Relocated",
    "Wrong Number",
    "Wrong Location",
    "Wrong Shop",
    "Wrong Shop Category | Not fit our products",
    "Busy Person | Hospital | Emergency | Driving",
    "Call not attending",
    "Call not connecting",
    "Invalid number",
    "Network issues",
    "Runs Electrical Shop",
    "Runs another shop",
    "Asking for credit | Bill to bill",
    "Finance issues",
    "Trust issues",
    "Bulk buyer",
    "Low quantity buyer",
    "Low sales customer",
    "No LED Sales",
    "Need to discuss with someone else",
  ],
  place_search_types: [
    "accounting",
    "airport",
    "amusement_park",
    "aquarium",
    "art_gallery",
    "atm",
    "bakery",
    "bank",
    "bar",
    "beauty_salon",
    "bicycle_store",
    "book_store",
    "bowling_alley",
    "bus_station",
    "cafe",
    "campground",
    "car_dealer",
    "car_rental",
    "car_repair",
    "car_wash",
    "casino",
    "cemetery",
    "church",
    "city_hall",
    "clothing_store",
    "convenience_store",
    "courthouse",
    "dentist",
    "department_store",
    "doctor",
    "drugstore",
    "electrician",
    "electronics_store",
    "embassy",
    "fire_station",
    "florist",
    "funeral_home",
    "furniture_store",
    "gas_station",
    "gym",
    "hair_care",
    "hardware_store",
    "hindu_temple",
    "home_goods_store",
    "hospital",
    "insurance_agency",
    "jewelry_store",
    "laundry",
    "lawyer",
    "library",
    "light_rail_station",
    "liquor_store",
    "local_government_office",
    "locksmith",
    "lodging",
    "meal_delivery",
    "meal_takeaway",
    "mosque",
    "movie_rental",
    "movie_theater",
    "moving_company",
    "museum",
    "night_club",
    "painter",
    "park",
    "parking",
    "pet_store",
    "pharmacy",
    "physiotherapist",
    "plumber",
    "police",
    "post_office",
    "primary_school",
    "real_estate_agency",
    "restaurant",
    "roofing_contractor",
    "rv_park",
    "school",
    "secondary_school",
    "shoe_store",
    "shopping_mall",
    "spa",
    "stadium",
    "storage",
    "store",
    "subway_station",
    "supermarket",
    "synagogue",
    "taxi_stand",
    "tourist_attraction",
    "train_station",
    "transit_station",
    "travel_agency",
    "university",
    "veterinary_care",
    "zoo",
  ],
};

export function getShopTypeLabel(value) {
  const shopType = constants.shopTypes.find((type) => type.value == value);
  return shopType ? shopType.label : "";
}

export function isInCurrentMonth(dateString) {
  var date = new Date(dateString);
  const now = new Date();
  return (
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
}

export function isToday(dateString) {
  var date = new Date(dateString);
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function shortenedText(text, maxLength) {
  if (!text) {
    return <span></span>;
  }

  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  return <span>{`${text.slice(0, maxLength) ?? ""}...`}</span>;
}

export function getCurrentDateTime() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateTime;
}

export function getListFromArray(arr) {
  return arr.map((item) => ({ label: item, value: item }));
}

export function haversineDistance(coord1, coord2) {
  const earthRadius = 6371000; // Radius of the Earth in meters

  const degToRad = (deg) => deg * (Math.PI / 180);

  const lat1 = degToRad(parseFloat(coord1?.lat));
  const lon1 = degToRad(parseFloat(coord1?.lng));
  const lat2 = degToRad(parseFloat(coord2?.lat));
  const lon2 = degToRad(parseFloat(coord2?.lng));

  if (!lat1 || !lat2 || !lon1 || !lon2) {
    // console.log("invalid lat or lon, from haversineDistance constants.js");
    return 0;
  }

  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c;
  window.debugToast("haversine distance " + distance);
  return distance;
}

export function getProductImage(product) {
  return product?.image
    ? parent + "storage/" + product?.image
    : product_placeholder;
}

export function getUserImage(user) {
  return user?.image ? parent + "storage/" + user?.image : user_placeholder;
}

export function alertError(msg, duration = 3000, runOnClick = () => {}) {
  play(2);
  toaster.push(
    <Message type="error" onClick={runOnClick}>
      {msg}
    </Message>,
    {
      duration,
      placement: "topCenter",
    }
  );
  navigator.vibrate(200);
}

export function alertInfo(msg, duration = 3000, runOnClick = () => {}) {
  play(3);
  toaster.push(
    <Message type="success" onClick={runOnClick}>
      {msg}
    </Message>,
    {
      duration,
      placement: "topCenter",
    }
  );
}

export function alertInfoSilent(msg, duration = 3000, runOnClick = () => {}) {
  toaster.push(
    <Message type="success" onClick={runOnClick}>
      {msg}
    </Message>,
    {
      duration,
      placement: "topCenter",
    }
  );
}

export function isItCartPage() {
  return new URLSearchParams(window.location.search).has("cart");
}
export function isItOrdersPage() {
  return new URLSearchParams(window.location.search).has("orders");
}

export function urlHas(param) {
  return new URLSearchParams(window.location.search).has(param);
}

export function pushUrl(param = null) {
  window.history.pushState(
    { path: "" },
    "",
    window.location.origin +
      window.location.pathname +
      (param ? "?" + param : "")
  );
}

export function play(which) {
  var sounds = [sound_1, sound_2, sound_3, sound_4_woosh];
  const audio = new Audio(sounds[which - 1]);
  audio.play();
}

export function isValid(obj, lngt) {
  return obj !== null && obj?.length >= lngt;
}

export function sqlDateTime(date) {
  if (date != null && date.length !== 0) {
    return moment(date)?.format("YYYY-MM-DD HH:mm:ss");
  }
  return date;
}

export function getMarkerImage(color, time = false) {
  switch (color.toUpperCase()) {
    case "RED":
      return time ? marker_red_time : marker_red;
    case "BLACK":
      return time ? marker_black_time : marker_black;
    case "WHITE":
      return time ? marker_white_time : marker_white;
    case "BLUE":
      return time ? marker_blue_time : marker_blue;
    case "GREEN":
      return time ? marker_green_time : marker_green;
    case "PINK":
      return time ? marker_pink_time : marker_pink;
    case "YELLOW":
      return time ? marker_yellow_time : marker_yellow;
    default:
      console.warn(`Unknown color: ${color}`);
      return null;
  }
}

export default constants;
