import React, { Component } from "react";
import OTPInput from "react-otp-input";
import {
  ButtonGroup,
  Drawer,
  List,
  Stack,
  Tag,
  Button,
  ButtonToolbar,
  Divider,
  InputNumber,
  Input,
  Toggle,
} from "rsuite";
import constants, {
  alertError,
  alertInfo,
  getProductImage,
} from "../constants";

class TempCustomerMaker extends Component {
  state = {
    products: [],
    loading: false,
    drawer: false,
    cart: [],
    data: {},
  };

  componentDidMount = () => {
    this.loadProducts();
  };

  drawer = (drawer = true) => {
    this.setState({
      drawer,
      data: this.props.tempCustomer ?? {
        name: "",
        phone: "",
        address: "",
        postal_pin: "",
        cod: false,
      },
      cart: this.getCartFromTemp(),
    });
  };

  getCartFromTemp = () =>
    (this.props.tempCustomer?.temp_items || []).map(
      ({ product_id, quantity }) => ({
        product_id,
        qty: quantity,
      })
    );

  changeData = (where, what) => {
    var { data } = this.state;
    data[where] = what;
    this.setState({ data });
    if (where == "postal_pin" && what.length == 6) {
      this.checkPin(what);
    }
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  loadProducts = () => {
    this.setLoading();
    fetch(constants.url + "store-products", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ products: data.products });
        this.setLoading(false);
        setTimeout(this.getCart, 200);
        setTimeout(this.checkUrlParam, 200);
      })
      .catch((error) => {
        this.setLoading(false);
        this.loadProducts();
        console.error(error);
      });
  };

  checkPin = (value) => {
    this.setLoading();
    fetch(constants.url + `check-pin/${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setLoading(false);
        if (data[0]?.PostOffice == null) {
          alertError("Invalid Postal Code");
          this.changeData("postal_pin", null);
        } else {
          var pinData = data[0]?.PostOffice[0];
          if (pinData.State == "Kerala") {
            alertInfo(`PIN ${value}: ${pinData?.Name}`);
          } else {
            alertError("Outside Kerala - " + pinData.State, 6000);
          }
        }
      })
      .catch((error) => {
        alertError("Failed to check PIN");
        this.setLoading(false);
      });
  };

  // Function to add a product to the cart
  addProduct = (product) => {
    const { cart } = this.state;

    // Check if the product is already in the cart
    const existingProduct = cart.find((item) => item.product_id === product.id);

    if (existingProduct) {
      // If the product is already in the cart, update the quantity with additional checks
      if (
        existingProduct.qty < Math.min(product.max_qty, product.current_stock)
      ) {
        existingProduct.qty += 1;
      } else {
        // Handle the case where the quantity is already at the maximum
        alertError(`Maximum quantity reached for product ${product.id}`);
        // You can add additional logic here if needed
      }
    } else {
      // If the product is not in the cart, add it with quantity based on min_qty
      const initialQty = Math.max(product.min_qty, 1); // Use min_qty or 1 if min_qty is not set
      cart.push({ product_id: product.id, qty: initialQty });
    }

    this.setState({ cart });
  };

  removeProduct = (product) => {
    const { cart } = this.state;

    // Find the product in the cart
    const existingProduct = cart.find((item) => item.product_id === product.id);

    if (existingProduct) {
      // If the product is in the cart, decrease the quantity
      existingProduct.qty -= 1;

      // Check if the quantity is less than or equal to min_qty
      if (existingProduct.qty < product.min_qty) {
        // If true, remove the product from the cart
        const updatedCart = cart.filter(
          (item) => item.product_id !== product.id
        );

        this.setState({ cart: updatedCart });
      } else {
        this.setState({ cart });
      }
    }
  };

  isInCart = (product) => {
    const { cart } = this.state;

    const foundProduct = cart.find((item) => item.product_id === product.id);

    // If the product is in the cart, return its quantity; otherwise, return null
    return foundProduct ? foundProduct.qty : null;
  };

  create = (retry = 0) => {
    var { data, cart } = this.state;
    var req = {
      name: data.name,
      address: data.address,
      postal_pin: data.postal_pin,
      phone: data.phone,
      cod: data.cod,
      cart,
      load_shipping: true,
      finish: false,
      return_full: true,
    };

    if (data.uid) {
      req.uid = data.uid;
    }

    this.setLoading();

    fetch(constants.url + `sync`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((d) => {
        var data = d.tempCustomer;

        if (!data) {
          if (retry < 3) {
            this.create(retry + 1);
          } else {
            alertError("Unable to create this temp customer..");
          }
        }

        if (data?.cod == 1 && data?.cod_fee == 0 && data.postal_pin) {
          console.log(
            "cod true and cod fee is zero, also has postal pin set.."
          );
          this.create();
        } else {
          alertInfo("Temp customer created");
          this.props.onCreate(data);
          this.drawer(false);
        }

        this.setLoading(false);
      })
      .catch((error) => {
        if (retry < 3) {
          this.create(retry + 1);
        } else {
          alertError("Unable to create this temp customer..");
        }
        this.setLoading(false);
      });
  };

  render() {
    var { loading, cart, data } = this.state;
    var { tempCustomer } = this.props;
    return (
      <div>
        <Button
          appearance={tempCustomer ? "link" : "primary"}
          onClick={this.drawer}
          block={tempCustomer == undefined}
        >
          {tempCustomer ? "Edit" : "Create Temp Customer"}
        </Button>
        <Drawer
          open={this.state.drawer}
          onClose={() => this.drawer(false)}
          size="full"
          placement="bottom"
        >
          <Drawer.Header>
            <Drawer.Title>Temp Customer Maker</Drawer.Title>
            <Drawer.Actions>
              <Button
                onClick={() => this.create()}
                appearance="primary"
                color="green"
                disabled={loading}
              >
                {tempCustomer ? "Update" : "Create"}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <Divider>Order Items</Divider>
            <List style={{ height: "300px" }}>
              {this.state.products.map((product, i) => (
                <List.Item key={i}>
                  <Stack justifyContent="space-between">
                    <Stack>
                      <img
                        src={getProductImage(product)}
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "10px",
                        }}
                      />
                      <div>
                        <strong>
                          {product?.name} |{" "}
                          <strong>Rs:{product.unit_price}</strong>
                        </strong>
                        <br />
                        <Tag>Stock: {product?.current_stock}</Tag>
                      </div>
                    </Stack>
                    <ButtonToolbar>
                      <ButtonGroup>
                        {this.isInCart(product) !== null && (
                          <Button
                            appearance="ghost"
                            onClick={() => this.removeProduct(product)}
                            disabled={loading}
                            size="xs"
                          >
                            -
                          </Button>
                        )}
                        {this.isInCart(product) !== null && (
                          <Button appearance="primary" size="xs" color="red">
                            {this.isInCart(product)}
                          </Button>
                        )}
                        <Button
                          appearance="ghost"
                          onClick={() => this.addProduct(product)}
                          disabled={loading}
                          size="xs"
                        >
                          +
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Stack>
                </List.Item>
              ))}
            </List>
            {cart.length != 0 ? (
              <div>
                <Divider>Name</Divider>
                <Input
                  block
                  value={data.name}
                  onChange={(value) => this.changeData("name", value)}
                />
                <br />
                <Divider>Phone</Divider>
                <InputNumber
                  scrollable={false}
                  prefix="+91"
                  placeholder="Enter Phone Number 10 Digit"
                  value={data.phone}
                  onChange={(value) =>
                    value.length <= 10 && this.changeData("phone", value)
                  }
                />
                <br />
                <Divider>Address</Divider>
                <Input
                  block
                  value={data.address}
                  onChange={(value) => this.changeData("address", value)}
                />
                <br />
                <Divider>PIN Code</Divider>

                <OTPInput
                  containerStyle={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  inputType="number"
                  value={data.postal_pin}
                  onChange={(value) => this.changeData("postal_pin", value)}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => (
                    <input {...props} className="otp-input" />
                  )}
                />
                <br />
                <Divider />
                <Stack justifyContent="space-between">
                  <span>Cash on delivery order?</span>
                  <Toggle
                    checked={data.cod}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(value) => this.changeData("cod", value)}
                  />
                </Stack>
                <br />
              </div>
            ) : (
              <strong>Add items..</strong>
            )}
          </Drawer.Body>
        </Drawer>
      </div>
    );
  }
}

export default TempCustomerMaker;
