import React, { useState } from "react";
import { Form, Button, Message } from "rsuite";
import Cookies from "js-cookie";
import constants from "../constants";
import "../App.css";
import "../index.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (a, event) => {
    event.preventDefault();
    // Handle login logic here
    try {
      setLoading(true);
      const response = await fetch(constants.url + "login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      setLoading(false);
      if (data.status == "failed") {
        setError("Failed to login");
      } else {
        setError("");
        window.localStorage.setItem("u-token", data.token);
        window.localStorage.setItem("username", data.username);
        window.localStorage.setItem("email", data.email);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      console.log(data);
      // do something with the response data, e.g. redirect the user to a dashboard
    } catch (error) {
      console.error(error);
      // handle the error, e.g. display an error message to the user
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black !important",
      }}
    >
      <div
        style={{
          width: "70%",
          height: "650px",
          backgroundColor: "white !important",
          border: "2px solid black",
          borderRadius: "10px",
          padding: "20px",
          // display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "20px",
        }}
      >
        <img
          src={constants.logoIcon}
          style={{ width: "130px", height: "auto" }}
        />
        <br />
        <Form fluid onSubmit={handleSubmit}>
          <h4 style={{ textAlign: "center", marginBottom: 40 }}>
            SalesMart Team Login
          </h4>
          {error && (
            <Message showIcon type="error" closable>
              {error}
            </Message>
          )}
          <Form.Group>
            <Form.ControlLabel>Email</Form.ControlLabel>
            <Form.Control
              name="email"
              type="email"
              value={email}
              onChange={(value) => setEmail(value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Password</Form.ControlLabel>
            <Form.Control
              name="password"
              type="password"
              value={password}
              onChange={(value) => setPassword(value)}
            />
          </Form.Group>
          <Button
            appearance="primary"
            block
            type="submit"
            style={{ marginTop: 16 }}
            disabled={loading}
          >
            Sign in
          </Button>
        </Form>
        <br />
        <br />
        &copy; Stack Enterprises
      </div>
    </div>
  );
};

export default Login;
